import React from 'react'
import classnames from 'classnames'

import styles from './TabbedSidePanelNavigationButton.module.css'

const TabbedSidePanelNavigationButton = ({ isCurrent, icon, onClick }) => {

    return (
        <li>
            <div className={`${classnames(styles.button, isCurrent && styles.isCurrent)}`} onClick={onClick}>
                {icon}
            </div>
        </li>
    )
}

export default TabbedSidePanelNavigationButton;