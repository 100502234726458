import React, { useState } from 'react'

import { Dates } from '../../utils/';

import styles from './DateSelectors.module.css';

export const DateSelector = ({
    // classname applied to the containing <div>
    className,
    // minimum allowed year
    yearMin = 2018,
    // maximum allowed year
    yearMax = 2999,
    // default (initial) date in milliseconds
    defaultValue = 0,
    // callback raised when the date has been changed by the user
    onChange,
}) => {

    const [year, setYear] = useState(Dates.fromMillis(defaultValue).year());
    const [month, setMonth] = useState(Dates.fromMillis(defaultValue).month());
    const [day, setDay] = useState(Dates.fromMillis(defaultValue).day());

    //console.log(`default ${defaultValue} year=${year} month=${month} day=${day}`)

    /**
     * Returns the number of Unix Epoch milliseconds that describe
     * the date represented by the supplied values.
     * @param {number} year
     * @param {number} month
     * @param {number} day
     */
    function createDate(year, month, day) {

        //console.log(`in createDate ${year} ${month} ${day}`);

        // convert the date values to a format string
        const value = `${year.toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} 00:00:00:000`;

        // convert to milliseconds including any added milliseconds
        return Dates.fromString(value).millis();
    }

    function onYearChange(event) {
        const year = +(event.target.value);
        setYear(year);
        onChange(createDate(year, month, day));
    }
    function onMonthChange(event) {
        const month = +(event.target.value);
        setMonth(month);
        onChange(createDate(year, month, day));
    }
    function onDayChange(event) {
        const day = +(event.target.value);
        setDay(day);
        onChange(createDate(year, month, day));
    }

    return (
        <div className={`${styles.root} ${className}`}>
            <div className={`${styles.yearGroup}`}>
                <div className={`${styles.label}`}>Year</div>
                <div><input className={`${styles.input}`} type="number" min={yearMin} max={yearMax} placeholder="YYYY" onChange={onYearChange} defaultValue={year} /></div>
            </div>

            <div className={`${styles.monthGroup}`}>
                <div className={`${styles.label}`}>Month</div>
                <div><input className={`${styles.input}`} type="number" min="1" max="12" step="1" placeholder="MM" onChange={onMonthChange} defaultValue={month} /></div>
            </div>

            <div className={`${styles.dayGroup} ${className}`}>
                <div className={`${styles.label}`}>Day</div>
                <div><input className={`${styles.input}`} type="number" min="1" max="31" step="1" placeholder="DD" onChange={onDayChange} defaultValue={day} /></div>
            </div>
        </div>
    )
}