import React from 'react'

const NavLogo = () => {
    // brand logo graphic can be overridden via site.css
    return (
        <div className="navLogoRoot">
            <img className="navLogo" src="./assets/AppLogo.png" alt="Brand logo"/>
        </div>
    )
}

export default NavLogo
