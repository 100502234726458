import React, {
    createRef,
    useEffect,
} from 'react';

import * as d3 from 'd3';

import styles from './Charts.module.css';

import { useSelector } from 'react-redux';

import {
    getCurrentDate,
} from '../../../redux/shipview/dates';

export const TimeLine = ({
    height,
    xScale,
}) => {
    const lineRef = createRef();

    const currentDate = useSelector(state => getCurrentDate(state));

    const x = xScale(new Date(currentDate)) || 0;

    //console.log("current date " + currentDate);

    useEffect(() => {
        if (lineRef.current) {
            d3.select(lineRef.current)
                .attr("x1", x)
                .attr("x2", x);
        }
    })

    return (
        <line ref={lineRef} x1="0" x2="0" y1="0" y2={height} stroke={"var(--color-base-mid-100)"} strokeWidth="1.5" />
    )
}

export const AxisLeft = (
    {
        scale,
        height,
        label,
        showTicks = true,
    }
) => {

    const labelX = 0 - (height / 2);

    const gRef = createRef();

    useEffect(() => {
        if (gRef.current) {
            d3.select(gRef.current).call(d3.axisLeft(scale).tickValues(showTicks ? null : []));
        }
    })

    return (
        <>
            <g ref={gRef} />
            <text transform={`rotate(-90)`} textAnchor="middle" y={-30} x={labelX}>{label}</text>
        </>
    )
}

export const AxisBottom = (
    {
        scale,
        height
    }
) => {

    const gRef = createRef();

    useEffect(() => {
        if (gRef.current) {
            d3.select(gRef.current).call(d3.axisBottom(scale));
        }
    })

    return (
        <>
            <g ref={gRef} transform={`translate(0,${height})`} />
        </>
    )
}

export const Line = (
    {
        data,
        lineFn,
        color,
        width = "1.5",
    }
) => {

    const pathRef = createRef();

    useEffect(() => {
        if (pathRef.current) {
            d3.select(pathRef.current)
                .attr("d", lineFn(data))
                .attr("stroke", color)
                .attr("stroke-width", width)
                .attr("fill", "none")
        }
    })

    return (
        <path ref={pathRef} />
    )
}

export const Bar = (
    {
        x,
        y,
        width,
        height,
        color,
    }
) => {

    const rectRef = createRef();

    useEffect(() => {
        d3.select(rectRef.current)
            .attr("x", x)
            .attr("y", y)
            .attr("width", width < 0 ? 0 : width)
            .attr("fill", color)
            .attr("height", height);
    }, [x, y, width, height, color, rectRef])


    return (
        <rect ref={rectRef} />
    )
}

export const Bars = (props) => {
    const { id, data, chartHeight, xScale, yScale, color } = props;

    const bars = data.length ? data.map(datum => {

        const utsDate = new Date(datum.uts);
        const ltsDate = new Date(datum.lts);

        const barProps = {
            x: xScale(ltsDate) || 0,
            y: yScale(datum.value) || 0,
            width: (xScale(utsDate) - xScale(ltsDate)) || 0,
            height: (chartHeight - yScale(datum.value)) || 0,
            color,
        };

        return <Bar key={id + datum.lts} {...barProps} />;
    }) : [];

    return (
        <g className="bars">{bars}</g>
    )
}

export const WanStatusBars = (props) => {
    const { id, data, chartHeight, xScale, yScale, color } = props;

    const bars = data.length ? data.map((datum) => {

        const utsDate = new Date(datum.tsu);
        const ltsDate = new Date(datum.tsl);

        const barProps = {
            x: xScale(ltsDate) || 0,
            y: yScale(datum.value + datum.index - 0.2),
            width: (xScale(utsDate) - xScale(ltsDate)) || 0,
            height: datum.show ? (chartHeight - yScale(datum.value - 0.4)) || 0 : 0,
            color,
        };

        return <Bar key={id + datum.tsl + datum.tsu} {...barProps} />;
    }) : [];

    return (
        <g className="bars">{bars}</g>
    )
}

export const KeyItem = (props) => {

    const { id, color, label, children } = props;

    return (
        <div key={id} className={styles.keyItem}>
            <div className={styles.keyItemIndicator} style={{ backgroundColor: color }}></div>
            <div className={styles.keyItemLabel}>{label}</div>
            {children}
        </div>
    )
}

export const Key = (props) => {

    const { children } = props;


    return (
        <div className={styles.key}>
            {children}
        </div>
    );
}

export const DebugInfo = (props) => {

    if (props.show) {
        return (
            <div className={styles.dataList}>
                <div>svg height {props.svgHeight}</div>
                <div>height {props.height}</div>
                <div>width {props.width}</div>
                <div>window width {props.windowWidth}</div>
                <div>window height {props.windowHeight}</div>
                <div>xScale Range {props.xScaleRange[0]}, {props.xScaleRange[1]}</div>
                <div>yScale Range {props.yScaleRange[0]}, {props.yScaleRange[1]}</div>
                <div>min tslower {props.minTsLower}</div>
                <div>max tsLower {props.maxTsLower}</div>
                <div>data length {props.dataLength}</div>
                {props.dataList}
            </div>
        )
    }

    return null;
}