import React from 'react';

import { useSelector } from 'react-redux';

import { getIsClosed as getContentIsClosed } from '../../../redux/shipview/contentPanel';

import TabbedSidePanel from '../../TabbedSidePanel/TabbedSidePanel.js';
import PanelNavigation from './PanelNavigation'
import PanelContent from './PanelContent'

const Panel = () => {

    const contentIsClosed = useSelector(state => getContentIsClosed(state));

    return (
        <TabbedSidePanel isClosed={contentIsClosed}>
            <PanelNavigation />
            <PanelContent />
        </TabbedSidePanel>
    )
}

export { Panel };