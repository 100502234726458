/* 
 * redux functions for : store.user.permissions
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */
import axios from 'axios';

// Actions
const REQUESTED = 'fleetwide/user/permissions/REQUESTED';
const RECEIVED = 'fleetwide/user/permissions/RECEIVED';
const FAILED = 'fleetwide/user/permissions/FAILED';

/*
 * Initial State
 */
const initialState = {
    isFetching: false,
    didInvalidate: true,
    isError: false,
    canViewAdminMenu: false,
    canViewBeams: false,
    canViewWatchAreas: false,
    canChangePackage: false,
    canViewPackagePrices: false,
    canViewInvoices: false,
};

/*
 * Reducer Functions
 */
const handlePermissionsRequested = (state) => {
    return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        isError: false
    }
}

const handlePermissionsReceived = (state, data) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: false,
        canViewAdminMenu: data.viewAdminMenu,
        canViewBeams: data.viewSatelliteBeamOverlay,
        canChangePackage: data.scheduleDataPackageChange,
        canViewPackagePrices: data.viewPackagePrices,
        canViewInvoices: data.viewInvoices,
    };
}
const handlePermissionsFailed = (state) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: true
    }
}

/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case REQUESTED:
            return handlePermissionsRequested(state);
        case RECEIVED:
            return handlePermissionsReceived(state, action.payload);
        case FAILED:
            return handlePermissionsFailed(state);
        default:
            return state;
    }
}


/*
 * Action Creators
 */
export function permissionsRequested() {
    return {
        type: REQUESTED
    }
}

export function permissionsReceived(data) {
    return {
        type: RECEIVED,
        payload: data
    }
}

export function permissionsFailed() {
    return {
        type: FAILED        
    }
}

/*
 * Selectors
 */
const permissionsState = (store) => {
    return store.user.permissions;
}

export const userCanViewAdminPage = (store) => {
    return permissionsState(store) ? permissionsState(store).canViewAdminMenu : false;
}

export const userCanViewBeams = (store) => {
    return permissionsState(store) ? permissionsState(store).canViewBeams : false;
}

export const userCanViewWatchAreas = (store) => {
    return permissionsState(store) ? permissionsState(store).canViewWatchAreas : false;
}

export const userCanChangePackage = (store) => {
    return permissionsState(store) ? permissionsState(store).canChangePackage : false;
}

export const userCanViewPackagePrices = (store) => {
    return permissionsState(store) ? permissionsState(store).canViewPackagePrices : false;
}

export const userCanViewInvoices = (store) => {
    return permissionsState(store) ? permissionsState(store).canViewInvoices : false;
}

/*
 * Side Effects
 */
const fetchPermissions = () => {
    // Thunk middleware knows how to handle functions.
    // It passes the dispatch method as an argument to the function,
    // thus making it able to dispatch methods to itself

    return function (dispatch) {
        // update the UI state to say the API call is starting
        dispatch(permissionsRequested());

        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.

        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.

        return axios.get('/api/user/permissions')
            .then(response => {
                // dispatch the data to the store
                dispatch(permissionsReceived(response.data));
            })
            .catch(function (error) {
                //console.log("error " + JSON.stringify(error));
                dispatch(permissionsFailed());
            });

        // TODO : other errors should be handled using React Error Boundaries
        // see https://reactjs.org/docs/error-boundaries.html

    }
}

export function shouldFetchPermissions(state) {
    const permissions = state.user.permissions;
    if (!permissions) {
        return true;
    } else if (permissions.isFetching) {
        return false;
    } else {
        return permissions.didInvalidate;
    }
}

// a thunk
export function loadPermissions() {
    // Note that the function also receives getState()
    // which lets you choose what to dispatch next.

    // This is useful for avoiding a network request if
    // a cached value is already available.
    return (dispatch, getState) => {
        if (shouldFetchPermissions(getState())) {
            // Dispatch a thunk from thunk!
            return dispatch(fetchPermissions())
        } else {
            // Let the calling code know there's nothing to wait for.
            return Promise.resolve()
        }
    }
}