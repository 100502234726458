import React, { useState } from 'react';

import styles from './PackageChanges.module.css';

/*
 * Imported Components
 */
import { ButtonOk, ButtonCancel, ButtonPrimary, ButtonSecondary } from '../../Forms/Buttons';

import { DateSelector } from '../../Forms';

import { Container, Row as GridRow, Col as GridCol } from 'react-grid-system';

import PackageList from "./PackageList";

/*
 * Imported Utilities
 */
import { formatNumber, Dates } from '../../../utils';

/*
 * Redux State
 */
import { useDispatch, useSelector } from 'react-redux';

import {
    getDialogIsOpen as getShowChangesList,
    getChanges,
    getVisibleChanges,
    getAvailablePackages,
    getBaseRatePlanName,
    getIsEditing,
    getIsFetching,
    getIsError,
    getIsSaving,
    getSaveFailed,
    getCanAddDefaultNew,
    getNewChangeDefaultStartDate,
    getEndOfToday,
    getDefaultMinDate,
    addItem,
    addItemAndEdit,
    removeItem,
    editItem,
    getEditItem,
    cancelEditItem,
    saveEditItem,
    cancelChanges,
    saveChanges,
} from '../../../redux/shipview/selectedInstallation/packages/packageChanges';

import { userCanViewPackagePrices as getUserCanViewPackagePrices } from '../../../redux/user/permissions';

function minAndMaxDates(defaultMinDate, endOfToday, items, itemId) {

    // items should be the array of visible items because that is ordered correctly
    // in respect of start dates

    let itemIndex = -1;

    // find the index of the array item which has the supplied itemId value
    for (let n = 0; n < items.length; n++) {
        if (items[n].id === itemId) {
            itemIndex = n;
            break;
        }
    }

    // defaults
    let result = {
        min: defaultMinDate,
        max: null,
        endOfToday: endOfToday,
    }

    if (itemIndex > -1) {
        // walk backwards to find the prior visible entry 
        for (let i = itemIndex - 1; i >= 0; i--) {
            result.min = Dates.fromMillis(items[i].endDate).nextDay().startOfDay().millis();
            break;
        }

        // walk forward to find the next visible entry
        for (let j = itemIndex + 1; j < items.length; j++) {
            result.max = Dates.fromMillis(items[j].startDate).previousDay().endOfDay().millis();
            break;
        }

    }

    //console.dir(result);

    return result;
}

function getRatePlanDetails(plans, id) {
    if (!plans) {
        console.log("plans is null");
        return null;
    }
    if (!plans.length) {
        console.log("no plans");
        return null;
    }

    function findIndex(plans, id) {
        const index = plans.findIndex(element => element.ratePlanId === id);
        return index;
    }

    const index = (id === null || typeof (id) === "undefined") ? 0 : findIndex(plans, id);

    return {
        ratePlanId: plans[index].ratePlanId,
        ratePlanName: plans[index].ratePlanName,
        dailyAdjustment: plans[index].dailyAdjustment
    }
}

/*
 * Components
 */

const EditChange = () => {

    // TODO: remove when things are all done
    const showDebug = false;

    const editItem = useSelector(state => getEditItem(state));

    const [startDate, setStartDate] = useState(editItem.startDate);
    const [endDate, setEndDate] = useState(editItem.endDate);
    const [ratePlanId, setRatePlanId] = useState(editItem.ratePlanId);
    const [startDateError, setStartDateError] = useState({ error: false, message: null });
    const [endDateError, setEndDateError] = useState({ error: false, message: null });

    const [dialogIsValid, setDialogIsValid] = useState(true);
    const availablePackages = useSelector(state => getAvailablePackages(state));
    // true if the user can view package prices
    const userCanViewPackagePrices = useSelector(state => getUserCanViewPackagePrices(state));

    const dispatch = useDispatch();

    function validateDates(start, end) {

        var isValid = true;

        function setStartDateState(message) {
            setStartDateError({ error: message !== null, message });
        }

        function setEndDateState(message) {
            setEndDateError({ error: message !== null, message });
        }

        // reset error states
        setStartDateState(null);
        setEndDateState(null);

        // not a real start date
        if (isNaN(start)) {
            setStartDateError("Start Date is not a valid date");
            isValid = false;
        }

        // not a real end date
        if (isNaN(end)) {
            setEndDateState("End Date is not a valid date");
            isValid = false;
        }

        // real start date and change NOT in progress and start date too early
        if (!isNaN(start) && !editItem.changeIsInProgress && (start < editItem.minDate)) {
            setStartDateState(`Start Date cannot be earlier than ${Dates.fromMillis(editItem.minDate).toString(Dates.DATE_FORMAT_SHORT)}`);
            isValid = false;
        }

        // real end date and real max date and and date too late
        if (!isNaN(end) && editItem.maxDate && (end > editItem.maxDate)) {
            setEndDateState(`End Date cannot be later than ${Dates.fromMillis(editItem.maxDate).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}`);
            isValid = false;
        }

        // real end date and change IN progress and end date earlier than end of today
        if (!isNaN(end) && editItem.changeIsInProgress && end < editItem.endOfToday) {
            setEndDateState(`End Date cannot be earlier than ${Dates.fromMillis(editItem.endOfToday).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}`);
            isValid = false;
        }

        // real end date and change IN progress and end date earlier than (start date + 24 hours)
        if (!isNaN(end) && editItem.changeIsInProgress && end < (startDate + Dates.MILLIS_FOR_HOURS_24)) {
            setEndDateState(`End Date cannot be earlier than ${Dates.fromMillis(startDate + Dates.MILLIS_FOR_HOURS_24).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}`);
            isValid = false;
        }

        if (!isNaN(start) && !isNaN(end) && end < start) {
            setEndDateState(`End Date cannot be earlier than Start Date`);
            isValid = false;
        }

        setDialogIsValid(isValid);
    }

    const handleStartDateOnChange = (millis) => {
        setStartDate(millis);
        validateDates(millis, endDate);
    }

    const handleEndDateOnChange = (millis) => {
        const adjusted = millis + Dates.endTime();
        setEndDate(adjusted);
        validateDates(startDate, adjusted);
    }

    const handleRatePlanOnChange = (ratePlanId) => {
        setRatePlanId(ratePlanId);
    }

    const handleOnCancel = () => {
        dispatch(cancelEditItem());
    }

    const handleOnSave = () => {

        const ratePlan = getRatePlanDetails(availablePackages, ratePlanId);

        dispatch(saveEditItem(editItem.id, startDate, endDate, ratePlan.ratePlanId, ratePlan.ratePlanName, ratePlan.dailyAdjustment));
    }

    return (
        <div className={styles.editOverlay}>
            <div className={styles.editDialog}>
                <div className={styles.editDialogHeader}>Edit Package Change</div>
                <div className={styles.editDialogBody}>

                    <div className={styles.editGroupContainer}>
                        <div className={styles.editGroupLabel}>Package {!editItem.changeIsInProgress && userCanViewPackagePrices && <span> (with Daily Adjustment from Base Plan)</span>}</div>

                        {editItem.changeIsInProgress &&
                            <div>
                                <div className={styles.editReadOnlyItem}>
                                    Package : {availablePackages.filter(value => value.ratePlanId === editItem.ratePlanId)[0].ratePlanName}
                                </div>
                                <div className={styles.editReadOnlyItem}>
                                    The Package cannot be edited because this Package Change is in progress.
                                </div>
                            </div>
                        }
                        {!editItem.changeIsInProgress &&
                            <div className={styles.editPackageListContainer}>
                                <PackageList
                                    packages={availablePackages}
                                    selectedRatePlanId={ratePlanId}
                                    onChange={handleRatePlanOnChange}
                                />
                            </div>
                        }
                    </div>

                    <div className={styles.editGroupContainer}>
                        <div className={styles.editGroupLabel}>Start Date</div>

                        {editItem.changeIsInProgress &&
                            <div>
                                <div className={styles.editReadOnlyItem}>
                                    Start Date : {Dates.fromMillis(startDate).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}
                                </div>
                                <div className={styles.editReadOnlyItem}>
                                    The Start Date cannot be edited because this Package Change is in progress.
                                </div>
                            </div>
                        }
                        {!editItem.changeIsInProgress &&
                            <div>
                                {!editItem.changeIsInProgress &&
                                    <div className={styles.editDateSelector}>
                                        <DateSelector defaultValue={startDate} onChange={handleStartDateOnChange} />
                                        <div className={styles.editDateDisplay}>{Dates.fromMillis(startDate).toString(Dates.DATE_FORMAT_TIME)}</div>
                                    </div>
                                }
                                <div className={styles.editGroupErrorBlock}>
                                    {startDateError.error ? <div>{startDateError.message}</div> : <div>&nbsp;</div>}
                                </div>
                            </div>
                        }

                    </div>

                    <div className={styles.editGroupContainer}>
                        <div className={styles.editGroupLabel}>End Date</div>

                        <div className={styles.editDateSelector}>
                            <DateSelector defaultValue={endDate} onChange={handleEndDateOnChange} />
                            <div className={styles.editDateDisplay}>{Dates.fromMillis(endDate).toString(Dates.DATE_FORMAT_TIME)}</div>
                        </div>

                        <div className={styles.editGroupErrorBlock}>
                            {endDateError.error ? <div>{endDateError.message}</div> : <div>&nbsp;</div>}
                        </div>
                    </div>

                    {showDebug &&
                        <div>
                            <div>start date : {startDate} : {startDate && Dates.fromMillis(startDate).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}</div>
                            <div>end date : {endDate} : {endDate && Dates.fromMillis(endDate).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}</div>
                            <div>rate plan id : {ratePlanId}</div>
                            <div>minDate : {editItem.minDate} : {editItem.minDate && Dates.fromMillis(editItem.minDate).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}</div>
                            <div>maxDate: {editItem.maxDate} : {editItem.maxDate && Dates.fromMillis(editItem.maxDate).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}</div>
                            <div>endOfToday: {editItem.endOfToday} : {editItem.endOfToday && Dates.fromMillis(editItem.endOfToday).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}</div>
                            <div>changeIsInProgress: {editItem.changeIsInProgress ? "yes" : "no"}</div>
                            <div>canBeRemoved : {editItem.canBeRemoved ? "yes" : "no"}</div>
                        </div>
                    }
                    <div className={styles.editDialogFooter}>
                        <div className={styles.editDialogFooterButtonsContainer}>
                            <ButtonOk onClick={handleOnSave} disabled={!dialogIsValid}>Ok</ButtonOk>
                            <ButtonCancel onClick={handleOnCancel}>Cancel</ButtonCancel>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

/**
 * 
 * @param {any} props
 */
const ChangesListItem = (props) => {

    const debugTables = false;

    const { item, minAndMaxDates, baseRatePlanName, onAddNew } = props;
    // true if the user can view package prices
    const userCanViewPackagePrices = useSelector(state => getUserCanViewPackagePrices(state));

    const dispatch = useDispatch();

    const handleOnEdit = (id) => {
        dispatch(editItem(id, minAndMaxDates.min, minAndMaxDates.max, minAndMaxDates.endOfToday));
    }

    const handleOnAdd = (id) => {
        onAddNew(id);
    }

    const handleOnRemove = (id) => {
        dispatch(removeItem(id));
    }

    return (
        <div>
            <div className={styles.changesRowContainer}>

                <GridRow>
                    <GridCol debug={debugTables} xs={12} sm={4} md={3} lg={2} className={styles.changesColHeader}>Start Date</GridCol>
                    <GridCol debug={debugTables} xs={12} sm={8} md={9} lg={4} className={styles.changesColValue}>{Dates.fromMillis(item.startDate).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}</GridCol>
                    <GridCol debug={debugTables} xs={12} sm={4} md={3} lg={2} className={styles.changesColHeader}>End Date</GridCol>
                    <GridCol debug={debugTables} xs={12} sm={8} md={9} lg={4} className={styles.changesColValue}>{item.endDate ? Dates.fromMillis(item.endDate).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS) : 'n/a'}</GridCol>
                    <GridCol debug={debugTables} xs={12} sm={4} md={3} lg={2} className={styles.changesColHeader}>Package</GridCol>
                    <GridCol debug={debugTables} xs={12} sm={8} md={9} lg={4} className={styles.changesColValue}>{item.ratePlanName}</GridCol>
                    {userCanViewPackagePrices &&
                        <GridCol debug={debugTables} xs={12} sm={4} md={3} lg={2} className={styles.changesColHeader}>Daily Adjustment</GridCol>
                    }
                    {userCanViewPackagePrices &&
                        <GridCol debug={debugTables} xs={12} sm={8} md={9} lg={4} className={styles.changesColValue}>${formatNumber(item.dailyAdjustment, 2)} / day</GridCol>
                    }
                    <GridCol debug={debugTables} xs={12}>
                        <div className={styles.changesButtonsContainer}>
                            <ButtonSecondary onClick={() => handleOnEdit(item.id)}>Edit...</ButtonSecondary>
                            {item.canBeRemoved && <ButtonPrimary onClick={() => handleOnRemove(item.id)}>Remove</ButtonPrimary>}
                        </div>
                    </GridCol>
                </GridRow>

            </div>

            {(!minAndMaxDates.max || (minAndMaxDates.max > item.endDate)) &&
                <div className={styles.changesRowContainer}>

                    <GridRow>
                        <GridCol debug={debugTables} xs={12} sm={4} md={3} lg={2} className={styles.changesColHeader}>Start Date</GridCol>
                        <GridCol debug={debugTables} xs={12} sm={8} md={9} lg={4} className={styles.changesColValue}>{Dates.fromMillis(item.endDate).nextDay().startOfDay().toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}</GridCol>
                        <GridCol debug={debugTables} xs={12} sm={4} md={3} lg={2} className={styles.changesColHeader}>End Date</GridCol>
                        <GridCol debug={debugTables} xs={12} sm={8} md={9} lg={4} className={styles.changesColValue}>{minAndMaxDates.max ? Dates.fromMillis(minAndMaxDates.max).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS) : 'n/a'}</GridCol>
                        <GridCol debug={debugTables} xs={12} sm={4} md={3} lg={2} className={styles.changesColHeader}>Package</GridCol>
                        <GridCol debug={debugTables} xs={12} sm={8} md={9} lg={4} className={styles.changesColValue}>Reverts to base package {baseRatePlanName}</GridCol>
                        {userCanViewPackagePrices &&
                            <GridCol debug={debugTables} xs={12} sm={4} md={3} lg={2} className={styles.changesColHeader}>Daily Adjustment</GridCol>
                        }
                        {userCanViewPackagePrices &&
                            <GridCol debug={debugTables} xs={12} sm={8} md={9} lg={4} className={styles.changesColValue}>${formatNumber(0, 2)} / day</GridCol>
                        }
                        <GridCol debug={debugTables} xs={12}>
                            <div className={styles.changesButtonsContainer}>
                                <ButtonSecondary onClick={() => handleOnAdd(item.id)}>Add Package Change</ButtonSecondary>
                            </div>
                        </GridCol>
                    </GridRow>

                </div>
            }
        </div>
    );
}

const ChangesList = () => {

    // TODO: remove when things are all done
    const showDebug = false;

    const isFetching = useSelector(state => getIsFetching(state));
    const isError = useSelector(state => getIsError(state));
    const changes = useSelector(state => getChanges(state));
    const displayableItems = useSelector(state => getVisibleChanges(state));
    const availablePackages = useSelector(state => getAvailablePackages(state));
    const baseRatePlanName = useSelector(state => getBaseRatePlanName(state));
    const canAddDefaultNew = useSelector(state => getCanAddDefaultNew(state));
    const newChangeDefaultStartDate = useSelector(state => getNewChangeDefaultStartDate(state));
    const defaultMinDate = useSelector(state => getDefaultMinDate(state));
    const endOfToday = useSelector(state => getEndOfToday(state));

    const dispatch = useDispatch();

    const handleAddDefaultNew = () => {

        const dates = minAndMaxDates(defaultMinDate, endOfToday, [], -1);

        //console.log("should be adding a new item where there are no existing items in the list which should then be immediately set to the edit item.  Needs minDates available as ", dates);

        const startDate = newChangeDefaultStartDate;
        const endDate = Dates.fromMillis(startDate).endOfDay().millis();

        const ratePlan = getRatePlanDetails(availablePackages);

        //if (!ratePlan) throw new Error();

        // TODO : we need to find a way of showing that there are no rate plans
        if (ratePlan) {
            dispatch(addItemAndEdit(startDate, endDate, ratePlan.ratePlanId, ratePlan.ratePlanName, ratePlan.dailyAdjustment, dates.min, dates.max, dates.endOfToday));
        } else {
            console.log("getRatePlanDetails returned null");
        }
    }

    /**
     * Add a new entry to the list of scheduled package changes.
     * Used to add an item to a non-empty list.
     * @param {any} precedingListItemId of the entry after which the new entry should be inserted.
     **/
    const handleAddNewForExisting = (precedingListItemId) => {
        // precedingListItemId is the index of the item after which the new item should be created
        // NICE and confusing because the precedingListItemId is an index value that relates to the 'whole' change list
        // not the visible changes list
        let startDate = Dates.fromMillis(changes[precedingListItemId].endDate).nextDay().startOfDay().millis();
        let endDate = Dates.fromMillis(startDate).endOfDay().millis();

        const ratePlan = getRatePlanDetails(availablePackages);

        // TODO : we need to find a way of showing that there are no rate plans
        //if (!ratePlan) throw new Error();

        // TODO: can we force the edit using a similar approach to handleAddDefaultName? 
        // We would need min and max dates which are calculate using an id value which we don't have yet
        // I'm not sure we can do this using our Redux approach because we would have to use the 'displayable items' list
        // to calculate the min and max dates, and that is not correctly set up until after we have created the new item.
        // We would have to do the business logic in the Redux action which is not really very good practice.

        // TODO: the user flow for cancelling an edit is a bit odd...
        // when we cancel a NEW change shouldn't it remove the change from the change list?
        // Cancelling an existing change (one that has already been accepted in some way), should just cancel the changes to the edit.
        if (ratePlan) {
            dispatch(addItem(startDate, endDate, ratePlan.ratePlanId, ratePlan.ratePlanName, ratePlan.dailyAdjustment));
        } else {
            console.log("getRatePlanDetails returned null");
        }

    }

    if (isFetching) {
        return <div>Loading...</div>
    }

    if (isError) {
        return <div>Ooops. Sorry, something went wrong!</div>
    }

    if (displayableItems && displayableItems.length === 0 && canAddDefaultNew) {
        // no current package changes and redux says it's ok to add a default new item
        // which will be shown in edit mode
        handleAddDefaultNew();
    }

    return (
        <div>
            {showDebug && <div>Total length of array : {changes.length}</div>}
            {showDebug && <div>{JSON.stringify(changes)}</div>}

            <Container>
                {!isFetching && !isError &&
                    displayableItems.map((item) => {

                        const dates = minAndMaxDates(defaultMinDate, endOfToday, displayableItems, item.id);

                        //console.log("showing ChangesListItem with dates ", dates);

                        return <ChangesListItem
                            key={`package-change${item.id}`}
                            item={item}
                            minAndMaxDates={dates}
                            baseRatePlanName={baseRatePlanName}
                            onAddNew={handleAddNewForExisting}
                        />
                    })
                }
            </Container>

            {displayableItems && displayableItems.length === 0 &&
                <div>
                    <p>There are no scheduled package changes.</p>
                    <ButtonSecondary onClick={handleAddDefaultNew}>Schedule a Package Change...</ButtonSecondary>
                </div>
            }
        </div>
    );
}

/**
 * This is a top (ShipView) level component that can't take props
 * associated with a specific piece of equipment. All state management
 * is done through redux, initiated from lower level components.
 * */
const PackageChanges = () => {

    const showChangesList = useSelector(state => getShowChangesList(state));
    const isEditing = useSelector(state => getIsEditing(state));
    const isSaving = useSelector(state => getIsSaving(state));
    const saveFailed = useSelector(state => getSaveFailed(state));

    const dispatch = useDispatch();

    const handleOnSave = () => {
        dispatch(saveChanges());
    }

    const handleOnCancel = () => {
        dispatch(cancelChanges());
    }

    if (!showChangesList) return null;

    return (
        <div className={styles.changesOverlay}>
            <div className={styles.changesDialog}>
                {isSaving &&
                    <div className={styles.savingOverlay}>Saving your changes. Please wait...</div>
                }
                <div className={styles.changesDialogHeader}>Package Changes</div>
                <div className={styles.changesDialogBody}>
                    <ChangesList />
                </div>
                <div className={styles.changesDialogFooter}>
                    <div className={styles.changesDialogFooterErrorMessage}>
                        {saveFailed && <span>Sorry, a problem occurred while saving your changes.</span>}
                        {!saveFailed && <span>&nbsp;</span>}
                    </div>
                    <div className={styles.changesDialogFooterButtonsContainer}>
                        <ButtonOk onClick={handleOnSave}>Close and Save</ButtonOk>
                        <ButtonCancel onClick={handleOnCancel}>Cancel</ButtonCancel>
                    </div>
                </div>
            </div>
            {isEditing && <EditChange />}
        </div>
    );
}

export default PackageChanges;