import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { getIsCharts } from '../../../redux/shipview/contentPanel';

import { getSelectedInstallation } from '../../../redux/shipview/installations';

import { DataVolumesChart } from './DataVolumesChart.js';
import { DownloadSpeedsChart } from './DownloadSpeedsChart.js';
import { RfChart } from './RfChart.js';
import { UploadSpeedsChart } from './UploadSpeedsChart.js';
import { WanStatusesChart } from './WanStatusesChart.js';
import TabbedSidePanelContentBody from '../../TabbedSidePanel/TabbedSidePanelContentBody'
import { CurrentBeam } from './BeamUsage.js';

import { DateRange } from '../DateRange/DateRange';

import styles from './Charts.module.css';

import {
    openDialog,
    getFromDate,
    getToDate,
    getInterval,
    getCurrentDate,
    currentDateChanged,
} from '../../../redux/shipview/dates';

import {
    DateDisplay
} from '../../Forms';

import CurrentLocation from '../CurrentLocation';

import ErrorBoundary from '../../../ErrorBoundary';

const Charts = () => {

    const show = useSelector(state => getIsCharts(state));
    const selectedInstallation = useSelector(state => getSelectedInstallation(state));

    const fromDate = useSelector(state => getFromDate(state));
    const toDate = useSelector(state => getToDate(state));
    const interval = useSelector(state => getInterval(state));
    const currentDate = useSelector(state => getCurrentDate(state));
    const dispatch = useDispatch();

    const chartProps = {
        chartHeight: 160,
        margin: { top: 10, right: 20, bottom: 0, left: 20 }
    }

    const handleOnCurrentDateChange = (value) => {
        dispatch(currentDateChanged(value));
    }

    const onChangeDates = () => {
        dispatch(openDialog());
    }

    if (show && selectedInstallation) {
        return (
            <>
                <div className={styles.informationContainer}>
                    <table >
                        <tbody>
                            <tr>
                                <td>Date Point</td>
                                <td className={styles.informationValue}>:&nbsp;<DateDisplay milliseconds={currentDate} showTime={true} /></td>
                            </tr>
                            <tr>
                                <td>Location</td>
                                <td className={styles.informationValue}>:&nbsp;<CurrentLocation /></td>
                            </tr>
                            <tr>
                                <td>Beam</td>
                                <td className={styles.informationValue}>:&nbsp;<CurrentBeam /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles.dateRangeContainer}>
                    <DateRange fromDate={fromDate} toDate={toDate} interval={interval} currentDate={currentDate} onCurrentDateChange={handleOnCurrentDateChange} onChangeDates={onChangeDates} />
                </div>
                <TabbedSidePanelContentBody noPadding={false}>
                    <div>
                        {selectedInstallation.hasMarpointInterface &&
                            <ErrorBoundary>
                                <WanStatusesChart {...chartProps} />
                            </ErrorBoundary>
                        }
                        <ErrorBoundary>
                            <DataVolumesChart {...chartProps} />
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <DownloadSpeedsChart {...chartProps} />
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <UploadSpeedsChart {...chartProps} />
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <RfChart {...chartProps} />
                        </ErrorBoundary>
                    </div>
                </TabbedSidePanelContentBody>
            </>
        )
    }

    return null;
}

export default Charts;