import React, { useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
    getIsBilling as getContentIsBilling,
    getIsCharts as getContentIsCharts,
    getIsDataUse as getContentIsDataUse,
    getIsNetwork as getContentIsNetwork,
    getIsPackages as getContentIsPackages,
    getIsSupportTickets as getContentIsSupportTickets,
    isBilling as showBilling,
    isCharts as showCharts,
    isDataUse as showDataUse,
    isNetwork as showNetwork,
    isPackages as showPackages,
    isSupportTickets as showSupportTickets,
} from '../../../redux/shipview/contentPanel';

import { userCanViewInvoices as getUserCanViewInvoices } from '../../../redux/user/permissions';

import TabbedSidePanelNavigationButton from '../../TabbedSidePanel/TabbedSidePanelNavigationButton'
import TabbedSidePanelNavigationPanel from '../../TabbedSidePanel/TabbedSidePanelNavigationPanel'

import {
    IconChart,
    IconTicket,
    IconDataUse,
    IconRouter,
    IconSimCard,
    IconBillingHistory,
} from '../../Icon/Icon';


const PanelNavigation = () => {

    const showingBilling = useSelector(state => getContentIsBilling(state));
    const showingCharts = useSelector(state => getContentIsCharts(state));
    const showingDataUse = useSelector(state => getContentIsDataUse(state));
    const showingNetwork = useSelector(state => getContentIsNetwork(state));
    const showingPackages = useSelector(state => getContentIsPackages(state));
    const showingSupportTickets = useSelector(state => getContentIsSupportTickets(state));
    const userCanViewInvoices = useSelector(state => getUserCanViewInvoices(state));

    const dispatch = useDispatch();

    // memoize the functions used to dispatch the change of current button
    // see https://react-redux.js.org/next/api/hooks#usedispatch
    const dispatchShowCharts = useCallback(
        () => dispatch(showCharts()),
        [dispatch]
    )

    const dispatchShowSupportTickets = useCallback(
        () => dispatch(showSupportTickets()),
        [dispatch]
    )

    const dispatchShowDataUse = useCallback(
        () => dispatch(showDataUse()),
        [dispatch]
    )

    const dispatchShowNetwork = useCallback(
        () => dispatch(showNetwork()),
        [dispatch]
    )

    const dispatchShowPackages = useCallback(
        () => dispatch(showPackages()),
        [dispatch]
    )

    const dispatchShowBilling = useCallback(
        () => dispatch(showBilling()),
        [dispatch]
    )

    return (
        <TabbedSidePanelNavigationPanel>
            <TabbedSidePanelNavigationButton isCurrent={showingCharts} icon={<IconChart title={"Vessel Connectivity"} />} onClick={dispatchShowCharts} />
            {false &&
                <TabbedSidePanelNavigationButton isCurrent={showingSupportTickets} icon={<IconTicket title={"Support Tickets"} />} onClick={dispatchShowSupportTickets} />
            }
            { false &&
                <TabbedSidePanelNavigationButton isCurrent={showingDataUse} icon={<IconDataUse title={"Data Use"} />} onClick={dispatchShowDataUse} />
            }
            {false &&
                <TabbedSidePanelNavigationButton isCurrent={showingNetwork} icon={<IconRouter title={"Network"} />} onClick={dispatchShowNetwork} />
            }
            <TabbedSidePanelNavigationButton isCurrent={showingPackages} icon={<IconSimCard title={"Data Packages"} />} onClick={dispatchShowPackages} />
            {userCanViewInvoices &&
                <TabbedSidePanelNavigationButton isCurrent={showingBilling} icon={<IconBillingHistory title={"Invoice History"} />} onClick={dispatchShowBilling} />
            }
        </TabbedSidePanelNavigationPanel>
    )
}

export default PanelNavigation;