import React from 'react';

import { coordinateDisplay } from '../../utils';

const LocationDisplay = (props) => {

    const { lat, lng } = props;

    const display = coordinateDisplay(lat, lng);

    return <span>{display.lat.position}{lat && <span>&deg;</span>}{display.lat.hemisphere}&nbsp;{display.lng.position}{lng && <span>&deg;</span>}{display.lng.hemisphere}</span>
}

export default LocationDisplay;