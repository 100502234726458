import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import { useDispatch } from 'react-redux';

// authorization imports
import AuthorizeRoute from '../authorization/AuthorizeRoute';
import { ApplicationPaths } from '../authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from '../authorization/ApiAuthorizationRoutes';
import authService from '../authorization/AuthorizeService';

// user actions
import { userLoggedIn, userLoggedOut } from '../redux/user/identity';

//import Layout from './Layout/Layout';

import { SHIP_VIEW_ROUTE, ADMIN_ROUTE } from './GlobalNavigation/GlobalNavigation';

// top level pages
import ShipViewPage from './ShipView/ShipViewPage';
import AdminViewPage from './AdminView/AdminViewPage';

import UserSettings from './UserSettings/UserSettings';

import GlobalNavigation from './GlobalNavigation/GlobalNavigation';

import styles from './App.module.css';

const App = () => {
    const dispatch = useDispatch();

    // effect to watch for changes in the user's login status
    useEffect(() => {
        // subscription to the authentication service
        var subscription = null;

        // callback passed to the authentication service which is called
        // when the authentication service notifies subscribers of a change
        // of logged in status
        async function getIsAuthenticated() {
            const [isAuth] = await Promise.all([authService.isAuthenticated()]);

            if (isAuth) {
                let profile = await authService.getUser();
                let token = await authService.getAccessToken();
                // update the store with logged in user details
                dispatch(userLoggedIn({ id: profile.sub, name: profile.name, token: token }));
            } else {
                // update the store to show no user is logged in
                dispatch(userLoggedOut());
            }
        }

        // subscribe to the authentication service
        subscription = authService.subscribe(() => getIsAuthenticated());

        // and make sure we know up front what state we're in
        getIsAuthenticated();

        // ensure we un-subscribe when the component un-mounts
        return function () {
            authService.unsubscribe(subscription);
        }
    })

    return (
        <Router>
            <div className={styles.root}>
                <div className={styles.navigation}>
                    <GlobalNavigation />
                </div>
                <div className={styles.content}>
                    <Switch>
                        {/* ordering of these routes is significant. see https://reactrouter.com/web/api/Switch*/}
                        <AuthorizeRoute exact path={"/"} component={ShipViewPage} />
                        <AuthorizeRoute path={SHIP_VIEW_ROUTE} component={ShipViewPage} />
                        <AuthorizeRoute path={ADMIN_ROUTE} component={AdminViewPage} />

                        {/* routes that are used to process the OIDC authorization flow */}
                        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    </Switch>
                </div>
            </div>
            <UserSettings />
        </Router>
    );
}

export default App;