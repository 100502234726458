import React from 'react'

import styles from './TabbedSidePanelNavigationPanel.module.css'

const TabbedSidePanelNavigationPanel = ({ children }) => {

    return (
        <div className={styles.root}>
            <ul className={styles.list}>
                {children}
            </ul>
        </div>
    )
}

export default TabbedSidePanelNavigationPanel;