import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './TableHeaderCell.module.css'

export const TableHeaderCell = ({ children, align }) => {

    let alignClass;

    if (align === "right") {
        alignClass = styles.alignRight;
    } else if (align === "centre") {
        alignClass = styles.alignCentre;
    } else {
        alignClass = styles.alignLeft;
    }

    return (
        <th className={classnames(styles.root, alignClass)}>{children}</th>
    )
}

TableHeaderCell.propTypes = {
    children: PropTypes.node.isRequired,
}