import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './Buttons.module.css';

export const ButtonPrimary = (props) => {
    const { children, className, onClick, disabled } = props;
    return (
        <Button colour="primary" className={className} onClick={onClick} disabled={disabled}>
            {children}
        </Button>
    )
}

export const ButtonSecondary = (props) => {
    const { children, className, onClick, disabled } = props;
    return (
        <Button colour="secondary" className={className} onClick={onClick} disabled={disabled}>
            {children}
        </Button>
    )
}

export const ButtonOk = (props) => {
    const { children, className, onClick, disabled } = props;
    return (
        <Button colour="ok" className={className} onClick={onClick} disabled={disabled}>
            {children}
        </Button>
    )
}

ButtonOk.defaultProps = {
    children: "Ok"
}

export const ButtonCancel = (props) => {
    const { children, className, onClick, disabled } = props;
    return (
        <Button colour="cancel" className={className} onClick={onClick} disabled={disabled}>
            {children}
        </Button>
    )
}

ButtonCancel.defaultProps = {
    children: "Cancel"
}


export const ButtonTransparent = (props) => {
    const { children, className, onClick, disabled, icon } = props;
    return (
        <Button colour="transparent" className={className} onClick={onClick} disabled={disabled} icon={icon}>
            {children}
        </Button>
    )
}

const Button = ({ children, colour, className, icon, onClick, disabled }) => {
    return (
        <button
            disabled={disabled}
            type="button"
            className={`${className} ${classnames(styles.button, colour && styles[colour], children || styles.iconOnly,)}`}
            onClick={onClick}
        >
            {icon && (
                <div className={styles.buttonIcon}>
                    <>{icon}</>
                </div>
            )}
            {children && <>{children}</>}
        </button>
    )
}

Button.propTypes = {
    children: PropTypes.node,
    colour: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.object,
    iconColour: PropTypes.string,
}

Button.defaultProps = {
    colour: 'blue',
    className: null,
    iconColour: null,
    icon: null,
    onClick: null,
}

