import React from 'react'

import styles from './DateButton.module.css'
import { IconDate } from '../Icon/Icon';

import classnames from 'classnames';

const DateButton = ({ children, onClick, title, disabled }) => {
    return (
        <button className={styles.button} onClick={onClick} disabled={disabled}>
            <div className={`${classnames(styles.icon, disabled && styles.disabled)}`}>
                <IconDate title={title} disabled={disabled }/>
            </div>
            {children}
        </button>
    )
}

export default DateButton
