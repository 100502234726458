/* 
 * User reducuer
 */
import { combineReducers } from 'redux';
import { reducer as identity } from './identity.js';
import { reducer as permissions } from './permissions.js';
import { reducer as settings } from './settings.js';
import { reducer as clients } from './clients.js';
import { reducer as notificationEmails } from './notificationEmails.js';

export const user = combineReducers({
    identity,
    permissions,
    settings,
    clients,
    notificationEmails,
})