/* 
 * Returns an object that contains display values for a coordinate
 */

import { formatNumber } from './formatters.js';

function formatValue(value) {

    const NUM_DP = 3;

    return formatNumber(Math.abs(value), NUM_DP);
}

function longitudeToString(value) {

    return {
        position: value ? `${formatValue(value)}` : null,
        hemisphere: value ? `${value >= 0 ? 'E' : 'W'}` : null
    }
}

function latitudeToString(value) {

    return {
        position: value ? `${formatValue(value)}` : null,
        hemisphere: value ? `${value >= 0 ? 'N' : 'S'}` : null
    }

}

/**
 * Returns an object that contains display values for a coordinate
 * passed as lat and lng decimal? values.
 * @param {any} lat
 * @param {any} lng
 */
export function coordinateDisplay(lat, lng) {

    return {
        lat: latitudeToString(lat),
        lng: longitudeToString(lng)
    }
}
