import React, {
    createRef,
    useEffect,
    useState
} from 'react';

import * as d3 from 'd3';

import styles from './Charts.module.css';

import { useSelector, useDispatch } from 'react-redux';

import {
    getSelectedInstallation
} from '../../../redux/shipview/installations';

import {
    getFromDate,
    getToDate,
    getInterval,
} from '../../../redux/shipview/dates';

import {
    loadData,
    getData,
    getCurrentValue,
    isError,
    isFetching,
} from '../../../redux/shipview/selectedInstallation/stats/uploadSpeeds';

import {
    useWindowSize,
    formatNumber,
} from '../../../utils';

import {
    Bars,
    Key,
    KeyItem,
    DebugInfo,
    TimeLine,
} from './ChartUtils.js';

import ErrorBoundary from '../../../ErrorBoundary';

import { ChartTab } from './ChartTab';

const KeyValue = () => {
    const current = useSelector(state => getCurrentValue(state));

    return (
        <div className={styles.keyValue}>
            <span>{formatNumber(current, 2)}kbps</span>
        </div>
    )
}

const Chart = (props) => {

    const { chartHeight, margin, data } = props;

    const color = d3.schemeCategory10[7];

    // reference to the containing div used to respond to resize events
    const divRef = createRef();
    const size = useWindowSize();

    // height of the svg that contains the chart
    const svgHeight = chartHeight;

    // height of the chart area inside the svg container
    const height = svgHeight - margin.top - margin.bottom;

    // width of the chart area inside the svg container
    const [width, setWidth] = useState(0);

    // d3 scale functions that determine how wide the size of the x and y axes and items on those axes
    const xScale = d3.scaleUtc().range([0, width]);
    const yScale = d3.scaleLinear().range([height, 0]);

    // the extents of the items on each axis
    const minTsLower = d3.min(data, d => new Date(d.tsl));
    const maxTsLower = d3.max(data, d => new Date(d.tsl));
    const maxTsUpper = d3.max(data, d => new Date(d.tsu));

    const maxValue = +d3.max(data, d => d.kbps);

    xScale.domain(data.length ? [minTsLower, maxTsUpper] : [0, 0]);
    yScale.domain(data.length ? [0, maxValue] : [0, 0]);

    // recalculate the chart width values when the window resizes
    useEffect(() => {
        if (divRef && divRef.current) {
            setWidth(divRef.current.offsetWidth - margin.left - margin.right);
        }
    }, [size, divRef, margin.left, margin.right])

    const barData = (data && data.length) ?
        data.map(datum => {
            return {
                lts: datum.tsl,
                uts: datum.tsu,
                value: datum.kbps,
            }
        })
        : [];

    const barsProps = {
        id: "upload",
        data: barData,
        chartHeight: height,
        xScale: xScale,
        yScale: yScale,
        color: color
    }

    function debugDataItems(data) {
        return (
            <div>
                {data && data.length &&
                    data.map((value, index) => {
                        return (<div key={`data-item-${index}`}>{value.tsl}, {new Date(value.tsl).toISOString()}, {value.tsu}, {value.kbps}</div>)
                    })
                }
            </div>
        )
    }

    const debugInfoProps = {
        show: false,
        svgHeight: svgHeight,
        height: height,
        width: width,
        windowWidth: size.width,
        windowHeight: size.height,
        xScaleRange: [xScale.range()[0], xScale.range()[1]],
        yScaleRange: [yScale.range()[0], yScale.range()[1]],
        minTslower: minTsLower ? minTsLower.toISOString() : "",
        maxTsLower: maxTsLower ? maxTsLower.toISOString() : "",
        dataLength: data.length,
        dataList: debugDataItems(data),
    }

    return (
        <div ref={divRef} className={styles.chartContainer}>
            <div className={styles.chartAndKey}>
                <svg className={styles.chartArea} height={svgHeight}>
                    <g transform={`translate(${margin.left},${margin.top})`}>
                        <Bars {...barsProps} />
                        <TimeLine height={height} xScale={xScale} />
                    </g>
                </svg>
                <Key>
                    <KeyItem id={"up"} color={color} label={"Upload Speed"}>
                        <KeyValue />
                    </KeyItem>
                </Key>
            </div>
            <DebugInfo {...debugInfoProps} />
        </div>
    )
}

export const UploadSpeedsChart = (props) => {

    const installation = useSelector(state => getSelectedInstallation(state));
    const fromDate = useSelector(state => getFromDate(state));
    const toDate = useSelector(state => getToDate(state));
    const interval = useSelector(state => getInterval(state));
    const data = useSelector(state => getData(state));
    const error = useSelector(state => isError(state));
    const fetching = useSelector(state => isFetching(state));

    const dispatch = useDispatch();

    useEffect(() => {
        if (installation) {
            dispatch(loadData(installation.id, fromDate, toDate, interval));
        }
    }, [dispatch, installation, fromDate, toDate, interval])

    const title = "Upload Speeds (Kilobits/second)";

    return (
        <div>
            <ChartTab>{fetching && <span>Fetching&nbsp;</span>}{error && <span className={styles.errorIndicator}>Failed to fetch&nbsp;</span>}{title}</ChartTab>
            <ErrorBoundary>
                <Chart {...props} data={data} />
            </ErrorBoundary>
        </div>
    );
}