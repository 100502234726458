import React from 'react'
import PropTypes from 'prop-types'

import styles from './ChartTab.module.css'

export const ChartTab = ({ children }) => {
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{children}</h2>
      <span className={styles.hr}></span>
    </div>
  )
}

ChartTab.propTypes = {
  children: PropTypes.node.isRequired,
}