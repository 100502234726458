import 
{
    useRef,
    useEffect,
} from 'react'

import { useInterval } from '../../../utils/';
import { useDispatch } from 'react-redux';
import { loadLatestStatuses } from '../../../redux/shipview/installations';

const enabled = true;

const update = (dispatch) => {
    if (enabled) {
        dispatch(loadLatestStatuses());
    }
}

const ShipCardStatusUpdater = () => {

    const isFirst = useRef(true);

    const dispatch = useDispatch();

    // update when the component is first loaded
    useEffect(() => {
        if (isFirst.current) {
            isFirst.current = false;
            if (enabled) {
                update(dispatch);
            }
        }
    })

    // update every minute
    useInterval(() => update(dispatch), 60000);

    return (null);
}

export default ShipCardStatusUpdater
