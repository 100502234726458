import React from "react";
import styles from "./Title.module.css";

const Title = () => {
    return (
        <div className={styles.root}>
            Immediate Package Upgrade
        </div>
    );
}

export default Title;