import { Dates } from './Dates';

/*
 * Returns the number of milliseconds that should be used to aggregate
 * data based on a date range supplied as unix milliseconds.
 */

export function dataAggregationTimeInterval(fromDate, toDate) {

    if (!fromDate) throw new Error("fromDate cannot be null");
    if (!toDate) throw new Error("toDate cannot be null");
    if (isNaN(fromDate)) throw new Error("fromDate must be a number");
    if (isNaN(toDate)) throw new Error("toDate must be a number");
    if (fromDate > toDate) throw new Error("fromDate cannot be later than toDate");

    let duration = toDate - fromDate;

    if (duration > Dates.MILLIS_FOR_DAYS_30) {
        return Dates.MILLIS_FOR_MINUTES_60;

    } else if (duration > Dates.MILLIS_FOR_DAYS_7) {
        return Dates.MILLIS_FOR_MINUTES_30;

    } else if (duration > Dates.MILLIS_FOR_DAYS_1) {
        return Dates.MILLIS_FOR_MINUTES_20;

    } else {
        return Dates.MILLIS_FOR_MINUTES_5;
    }
}

// get the relevant interval and adjust the end date to take account of the interval
// NOTE this assumes default end dates are always one second to mid-night
export function dateRangeCalculator(fromDate, toDate) {
    const interval = dataAggregationTimeInterval(fromDate, toDate + 1000);
    const adjustedTo = (toDate + 1000) - interval;
    return {
        from: fromDate,
        to: adjustedTo,
        interval: interval
    }
}

export function todayDateRange() {

    const start = Dates.today().startOfDay().millis();
    const end = Dates.today().endOfDay().millis();

    return dateRangeCalculator(start, end);
}

export function adjustedNow(interval) {

    const now = Dates.now().millis();

    // convert the now milliseconds to the nearest lowest valid interval
    // (now / interval) => quotient * interval
    return (~~(now / interval)) * interval;
}