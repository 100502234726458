import React, { useState } from "react";

import GroupContainer from "../../../Forms/GroupContainer";

import styles from "./ConfirmationChecks.module.css";

const ConfirmationChecks = (props) => {

    const { onChange } = props;

    const [checkOne, setCheckOne] = useState(false);
    const [checkTwo, setCheckTwo] = useState(false);

    function handleOnCheckOneChange() {
        const state = !checkOne;
        setCheckOne(state);
        onChange(state && checkTwo);
    }

    function handleOnCheckTwoChange() {
        const state = !checkTwo;
        setCheckTwo(state);
        onChange(checkOne && state);
    }

    return (
        <GroupContainer label="Important Information">
            <div className={styles.messages}>
                <div className={styles.messageContainer}>
                    <div>Please be aware that all scheduled future changes will be removed as part of the immediate upgrade process. Please reschedule any future changes once this immediate upgrade has been processed.</div>
                    <div>
                        <label className={styles.confirmationLabel}>
                            <input className={styles.confirmationCheckbox}
                                type="checkbox"
                                checked={checkOne}
                                onChange={handleOnCheckOneChange} />
                            I understand
                        </label>
                    </div>
                </div>
                <div className={styles.messageContainer}>
                    <div>Performing an immediate upgrade will incur a full 24 hour daily charge for today</div>
                    <div>
                        <label className={styles.confirmationLabel}>
                            <input className={styles.confirmationCheckbox}
                                type="checkbox"
                                checked={checkTwo}
                                onChange={handleOnCheckTwoChange} />
                            I accept
                        </label>
                    </div>
                </div>
            </div>
        </GroupContainer>
    );
}

export default ConfirmationChecks;