import React, { useMemo } from 'react';

import {
    Source,
    Layer,
} from 'react-map-gl';

import {
    useSelector,
} from 'react-redux';

import {
    getSelectedInstallation,
} from '../../../redux/shipview/installations';

import {
    getCurrentLocation,
    isFetching as getIsLoading,
} from '../../../redux/shipview/mapdata';

function makePoint(location) {
    const coords = [0, 0];
    if (location) {
        if (location.longitude) {
            coords[0] = location.longitude.value;
        }
        if (location.latitude) {
            coords[1] = location.latitude.value;
        }
    }
    return {
        type: 'Point',
        coordinates: coords
    };
}

export const ShipPoint = () => {

    //console.log("ship point here");

    const isLoading = useSelector(state => getIsLoading(state));
    const installation = useSelector(state => getSelectedInstallation(state));
    const pointData = useSelector(state => getCurrentLocation(state));
    const pointDataMemo = useMemo(() => makePoint(pointData), [pointData]);

    const pointLayer = {
        type: 'circle',
        paint: {
            'circle-radius': 5,
            'circle-color': '#FFFFFF',
            'circle-stroke-width': 2,
            'circle-stroke-color': '#0000FF'
        }
    };

    return (
        <Source type="geojson" data={pointDataMemo}>
            <Layer id={"ship-point-layer-id"} {...pointLayer} layout={{ 'visibility': !isLoading && pointData && installation ? 'visible' : 'none' }} />
        </Source>
    );
}