import React from "react";
import styles from "./Dialog.module.css";

const Dialog = (props) => {
    return (
        <div className={styles.overlay}>
            <div className={styles.dialog}>
                {props.children}
            </div>
        </div>
    );
}

export default Dialog;