import React from "react";
import styles from "./Buttons.module.css";

const Buttons = (props) => {
    const { children } = props;

    return (
        <div className={styles.root}>
            {children}
        </div>
    );
}

export default Buttons;
