import React from "react";

import { ButtonCancel } from '../../../Forms/Buttons';

const CloseButton = (props) => {

    const { visible, onClick } = props;

    if (!visible) return null;

    return (
        <ButtonCancel onClick={onClick}>Close</ButtonCancel>
    );
}

export default CloseButton;