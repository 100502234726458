import React from "react";
import styles from "./CannotUpgradeMessage.module.css";

import { Dates } from "../../../../utils/Dates";

const CannotUpgradeMessage = (props) => {

    const { visible, earliestUpgradeDate } = props;

    if (!visible) return null;

    return (
        <div className={styles.root}>
            <div>
                Sorry - you can't upgrade because there is an in-progress Package Change which started less than 24 hours ago.
            </div>
            <div>
                The earliest you can do an upgrade is {Dates.fromMillis(earliestUpgradeDate).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}
            </div>
        </div>
    );
}

export default CannotUpgradeMessage;