import React from 'react';

import {
    useSelector
} from 'react-redux';

import {
    DateDisplay
} from '../../Forms';

import LocationDisplay from '../../LocationDisplay/LocationDisplay';

import {
    getSelectedInstallation,
} from '../../../redux/shipview/installations';

import {
    getCurrentLocation
} from '../../../redux/shipview/mapdata';

import {
    getCurrentDate,
} from '../../../redux/shipview/dates';

import styles from './ShipInfo.module.css';

export const ShipInfo = () => {

    const installation = useSelector(state => getSelectedInstallation(state));
    const location = useSelector(state => getCurrentLocation(state));
    const date = useSelector(state => getCurrentDate(state));

    return (
        <div className={styles.root}>
            <div className={styles.item}>
                {installation ? <span>{installation.name}</span> : <span>nothing selected</span>}
            </div>
            { installation &&
                <div className={styles.item}>
                    <DateDisplay milliseconds={date} showTime={true} />
                </div>
            }
            { installation &&
                <div className={styles.item}>
                    {location && location.latitude && location.longitude &&
                        <LocationDisplay lat={location.latitude.display} lng={location.longitude.display} />
                    }
                    {(!location || !location.latitude || !location.longitude) && <span>Unknown Location</span>}
                </div>
            }
        </div>
    )
}