import React, { useState } from 'react';

import {
    ButtonOk,
    ButtonCancel,
    DateSelector,
    GroupContainer,
} from '../Forms';

import {
    Dialog,
    DialogContent,
    DialogFooter,
} from '../Dialog';

import styles from './DateRangeDialog.module.css';

import { dateRangeCalculator, Dates } from '../../utils';

const DateRangeDialog = (props) => {

    const { title, initialFromDate, initialToDate, onCancel, onSave } = props;

    const startOfToDate = Dates.fromMillis(initialToDate).startOfDay().millis();

    const [fromDate, setFromDate] = useState(initialFromDate);
    const [fromDateError, setFromDateError] = useState(null);
    const [toDate, setToDate] = useState(startOfToDate + Dates.endTime());
    const [toDateError, setToDateError] = useState(null);
    const [dateRangeError, setDateRangeError] = useState(null);

    const handleFromDateChanged = (milliseconds) => {
        setFromDate(milliseconds);
        validateDates(milliseconds, toDate);
    }

    const handleToDateChanged = (milliseconds) => {
        setToDate(milliseconds + Dates.endTime());
        validateDates(fromDate, milliseconds);
    }

    const validateDates = (from, to) => {
        setFromDateError(isNaN(from) ? "Not a valid date" : null);
        setToDateError(isNaN(to) ? "Not a valid date" : null);
        setDateRangeError(((!isNaN(from) && !isNaN(to)) && from >= to) ? "From date must be earlier than To date" : null);
    }

    const handleOnCancel = () => {
        onCancel();
    }

    const handleOnSave = () => {
        //console.log(`handleOnSave original: from=${fromDate} ${Dates.fromMillis(fromDate).toString(Dates.DATE_FORMAT_LONG_WITH_MILLISECONDS)} to=${toDate} ${Dates.fromMillis(toDate).toString(Dates.DATE_FORMAT_LONG_WITH_MILLISECONDS)}`);
        // calculate the interval to use and
        // adjust the actual saved date range to take account of the interval
        const dates = dateRangeCalculator(fromDate, toDate);

        //console.log(`handleOnSave dates: from:${dates.from} ${Dates.fromMillis(dates.from).toString(Dates.DATE_FORMAT_LONG_WITH_MILLISECONDS)} to:${dates.to} ${Dates.fromMillis(dates.to).toString(Dates.DATE_FORMAT_LONG_WITH_MILLISECONDS)} interval:${dates.interval}`)
        onSave(dates.from, dates.to, dates.interval);
    }

    return (
        <Dialog title={title}>
            <DialogContent>
                <div className={styles.content}>
                    <GroupContainer label="From Date">
                        <div className={styles.dateSelector}>
                            <DateSelector defaultValue={initialFromDate} onChange={handleFromDateChanged} />
                            <div className={styles.time}>
                                {Dates.fromMillis(fromDate).isValid()
                                    ? Dates.fromMillis(fromDate).toString(Dates.DATE_FORMAT_TIME)
                                    : Dates.now().startOfDay().toString(Dates.DATE_FORMAT_TIME)
                                }
                            </div>
                        </div>
                        <div className={styles.message}>
                            {!fromDateError &&
                                <div>&nbsp;</div>
                            }
                            {fromDateError &&
                                <div>{fromDateError}</div>
                            }
                        </div>
                    </GroupContainer>
                    <GroupContainer label="To Date">
                        <div className={styles.dateSelector}>
                            <DateSelector defaultValue={startOfToDate} onChange={handleToDateChanged} />
                            <div className={styles.time}>
                                {
                                    Dates.fromMillis(toDate).isValid()
                                        ? Dates.fromMillis(toDate).toString(Dates.DATE_FORMAT_TIME)
                                        : Dates.now().endOfDay().toString(Dates.DATE_FORMAT_TIME)
                                }
                            </div>
                        </div>
                        <div className={styles.message}>
                            {!toDateError &&
                                <div>&nbsp;</div>
                            }
                            {toDateError &&
                                <div>{toDateError}</div>
                            }
                        </div>
                    </GroupContainer>
                    <div className={styles.invalidDateRange}>
                        {dateRangeError &&
                            <div>{dateRangeError}</div>
                        }
                        {!dateRangeError &&
                            <div>&nbsp;</div>
                        }
                    </div>
                </div>
            </DialogContent>
            <DialogFooter>
                <div className={styles.footer}>
                    <ButtonOk onClick={handleOnSave} disabled={fromDateError || toDateError || dateRangeError} />
                    <ButtonCancel onClick={handleOnCancel} />
                </div>
            </DialogFooter>

        </Dialog>
    );
}

export default DateRangeDialog;