import React from 'react'

import SidePanelHeader from '../SidePanel/SidePanelHeader'

import SidePanelBody from '../SidePanel/SidePanelBody'

// TODO : fix the following:
// TODO : this is an example of Hedgehog not thinking about the specificity of their CSS.
// The sidePanel component expects a css class which is based on the 'layout' (ship view etc), 
// but the SidePanel is also used 'globally' e.g.in the User Settings which is accesible from the 'global navigation'
import ShipViewSidePanel from '../ShipView/SidePanel/ShipViewSidePanel'

import { SignOut } from './SignOut';

import { ChangePassword } from './ChangePassword';

import { NotificationEmails } from './NotificationEmails';

import { useSelector, useDispatch } from 'react-redux';

import { getIsOpen, close as closeUserSettings } from '../../redux/user/settings';

import { userCanChangePackage as getUserCanChangePackage } from '../../redux/user/permissions';

const UserSettings = () => {

    const isOpen = useSelector(state => getIsOpen(state));
    const userCanChangePackage = useSelector(state => getUserCanChangePackage(state));

    const dispatch = useDispatch();

    const handleOnClose = () => {
        dispatch(closeUserSettings());
    }

    if (isOpen) {
        return (
            <ShipViewSidePanel>
                <SidePanelHeader title="User Settings" onClose={handleOnClose}>
                    <SignOut />
                </SidePanelHeader>
                <SidePanelBody>
                    <ChangePassword />
                    {userCanChangePackage &&
                        <NotificationEmails />
                    }
                </SidePanelBody>
            </ShipViewSidePanel>
        )
    } else {
        return null;
    }
}

export default UserSettings
