import React, {
    createRef,
    useEffect,
    useState
} from 'react';

import * as d3 from 'd3';

import styles from './Charts.module.css';

import { useSelector, useDispatch } from 'react-redux';

import {
    getSelectedInstallation
} from '../../../redux/shipview/installations';

import {
    getFromDate,
    getToDate,
    getInterval,
} from '../../../redux/shipview/dates';

import {
    loadData,
    getData,
    isError,
    isFetching,
} from '../../../redux/shipview/selectedInstallation/stats/wanStatuses';

import {
    useWindowSize,
    formatNumber,
} from '../../../utils';

import {
    TimeLine,
    WanStatusBars,
    Line,
    Key,
    KeyItem,
} from './ChartUtils.js';

import { ChartTab } from './ChartTab';

import ErrorBoundary from '../../../ErrorBoundary';

const Status = (props) => {
    const { id, interfaceName, lineData, color, barData, chartHeight, xScale, yScale, lineFn } = props;

    return (
        <>
            <Line key={id + "line"} data={lineData} color={color} lineFn={lineFn} />
            <WanStatusBars key={id + "bar"} id={id + interfaceName} data={barData} chartHeight={chartHeight} xScale={xScale} yScale={yScale} color={color} />
        </>
    )
}

const Chart = (props) => {

    const { installationId, chartHeight, margin, data } = props;

    // strip out the null value that may have been appended to the data
    // set to allow us to know the full timespan that the data may cover
    // which in the case of the current day could include a future date
    const nonNullData = (data && data.length > 0) ? data.filter(value => value.interface !== null) : [];

    // create a list of unique interfaces from the data
    var interfaces = nonNullData.map((v) => v.interface).filter((v, i, a) => a.indexOf(v) === i).map((v) => {
        return {
            name: v,
            sort: null,
            color: null,
        }
    });

    //console.log("interfaces before sort " + JSON.stringify(interfaces));

    let auraColor = 0.7;
    let otherColor = 0.7;

    // assign useful properties to each interface
    interfaces.forEach((value) => {
        const lc = value.name.toLowerCase();
        if (lc === "offline") {
            value.sort = 0;
            value.color = d3.interpolateReds(0.8);
        } else if (lc.indexOf("aura") > -1 || lc.indexOf("vsat") > -1) {
            value.sort = 2;
            value.color = d3.interpolateGreens(auraColor);
            auraColor -= 0.1;
        } else {
            value.sort = 1;
            value.color = d3.interpolateBlues(otherColor);
            otherColor -= 0.1;
        }
    })

    //console.log("interface sort orders " + JSON.stringify(interfaceSortOrders));

    // sort the interface names
    interfaces.sort(function (a, b) {
        if (a.sort < b.sort) return -1;
        if (a.sort > b.sort) return 1;
        return 0;
    })

    //console.log("interfaces after sort " + JSON.stringify(interfaces));

    // associate data with each unique interface name
    let interfaceData = {};

    interfaces.forEach((value, index) => {
        interfaceData[value.name] = data.map((v) => {
            return {
                tsl: v.tsl,
                tsu: v.tsu,
                value: 1,
                index: index,
                show: v.interface === value.name ? true : false,
            }
        })
    })

    // determine the total time period covered by valid data
    const totalTime = nonNullData && nonNullData.length ? nonNullData[nonNullData.length - 1].tsu - nonNullData[0].tsl : 0;

    // create a reducer to accumlate the time for a given interface
    const timeReducer = (acc, curr) => curr.show ? acc + (curr.tsu - curr.tsl) : acc;

    //console.log("total time " + totalTime);

    // calculate the percentage of the total time each interface is up for
    // THIS DOES NOT Work correctly.  When the page is first shown with a date range of two days
    // covering today, we get crazy negative percentages showing.
    interfaces.forEach(value => {
        var interfaceTotal = interfaceData[value.name].reduce(timeReducer, 0);
        value.time = interfaceTotal;
        value.percentage = formatNumber((interfaceTotal / totalTime) * 100, 3);
    })

    //console.log("interfaceData " + JSON.stringify(interfaceData));

    //console.log("interfaces after time calc " + JSON.stringify(interfaces));

    // reference to the containing div used to respond to resize events
    const divRef = createRef();
    const size = useWindowSize();

    // height of the svg that contains the chart
    const svgHeight = chartHeight;

    // height of the chart area inside the svg container
    const height = svgHeight - margin.top - margin.bottom;

    // width of the chart area inside the svg container
    const [width, setWidth] = useState(0);

    // d3 scale functions that determine how wide the size of the x and y axes and items on those axes
    const xScale = d3.scaleUtc().range([0, width]);
    const yScale = d3.scaleLinear().range([height, 0]);

    // the extents of the items on each axis
    const minTsLower = d3.min(data, d => new Date(d.tsl));
    const maxTsUpper = d3.max(data, d => new Date(d.tsu));

    xScale.domain([minTsLower, maxTsUpper]);
    yScale.domain([0, interfaces.length]);

    // recalculate the chart width values when the window resizes
    useEffect(() => {
        if (divRef && divRef.current) {
            setWidth(divRef.current.offsetWidth - margin.left - margin.right);
        }
    }, [size, divRef, margin.left, margin.right])

    const lineFn = d3.line().curve(d3.curveBasis).x(d => xScale(d.x)).y(d => d.y);

    let lineData = [];

    if (interfaceData && interfaces && interfaces.length) {
        lineData = interfaces.map((value) => {

            const firstDataItem = interfaceData[value.name][0];
            const lastDataItem = interfaceData[value.name][interfaceData[value.name].length - 1];

            return [
                {
                    x: firstDataItem.tsl,
                    // this gets the line in the right place
                    y: yScale(firstDataItem.value + firstDataItem.index - 0.5)
                },
                {
                    x: lastDataItem.tsu,
                    y: yScale(firstDataItem.value + firstDataItem.index - 0.5)
                }
            ]
        })
    }

    return (
        <div ref={divRef} className={styles.chartContainer}>
            <div className={styles.chartAndKey}>
                <svg className={styles.chartArea} height={svgHeight}>
                    <g transform={`translate(${margin.left},${margin.top})`}>
                        {interfaces && interfaces.length &&
                            interfaces.map((value, index) => {
                                return (
                                    <Status
                                        key={installationId + "-" + index + "-status"}
                                        id={installationId}
                                        interfaceName={value.name}
                                        lineData={lineData[index]}
                                        color={value.color}
                                        barData={interfaceData[value.name]}
                                        chartHeight={height}
                                        xScale={xScale}
                                        yScale={yScale}
                                        lineFn={lineFn}
                                    />
                                )
                            })
                        }
                        <TimeLine height={height} xScale={xScale} />
                    </g>
                </svg>
                <Key>
                    {interfaces.map(value => {
                        return <KeyItem key={value.name} id={value.name} color={value.color} label={value.name}>
                            <div className={styles.keyValue}>
                                {/*<span>{value.percentage}%</span>*/}
                            </div>
                        </KeyItem>
                    })}
                </Key>
            </div>
        </div>
    )
}

export const WanStatusesChart = (props) => {

    const installation = useSelector(state => getSelectedInstallation(state));
    const fromDate = useSelector(state => getFromDate(state));
    const toDate = useSelector(state => getToDate(state));
    const interval = useSelector(state => getInterval(state));
    const data = useSelector(state => getData(state));
    const error = useSelector(state => isError(state));
    const fetching = useSelector(state => isFetching(state));

    const dispatch = useDispatch();

    useEffect(() => {
        if (installation) {
            dispatch(loadData(installation.id, fromDate, toDate, interval));
        }
    }, [dispatch, installation, fromDate, toDate, interval])

    const title = "WAN Statuses";

    return (
        <div>
            <ChartTab>{fetching && <span>Fetching&nbsp;</span>}{error && <span className={styles.errorIndicator}>Failed to fetch&nbsp;</span>}{title}</ChartTab>
            <ErrorBoundary>
                <Chart {...props} data={data} installationId={installation.id} />
            </ErrorBoundary>
        </div>
    );
}