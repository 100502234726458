import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
    ButtonOk,
    ButtonCancel,
    DateSelector,
    GroupContainer,
} from '../../Forms';

import {
    Dialog,
    DialogContent,
    DialogFooter,
} from '../../Dialog';

import { Dates } from '../../../utils';

import {
    getDialogIsOpen,
    closeDialog,
    getFromDate,
    getToDate,
    changeDateRange,
} from '../../../redux/shipview/selectedInstallation/invoices';

import styles from './InvoiceHistoryChangeDatesDialog.module.css';

const DialogImplementation = () => {

    const [fromDate, setFromDate] = useState(useSelector(state => getFromDate(state)));
    const [fromDateError, setFromDateError] = useState(null);
    const [toDate, setToDate] = useState(useSelector(state => getToDate(state)));
    const [toDateError, setToDateError] = useState(null);
    const [dateRangeError, setDateRangeError] = useState(null);

    const dispatch = useDispatch();

    const handleFromDateChanged = (milliseconds) => {
        setFromDate(milliseconds);
        validateDates(milliseconds, toDate);
    }

    const handleToDateChanged = (milliseconds) => {
        const value = milliseconds + Dates.endTime();
        setToDate(value);
        validateDates(fromDate, value);
    }

    const validateDates = (from, to) => {
        setFromDateError(isNaN(from) ? "Not a valid date" : null);
        setToDateError(isNaN(to) ? "Not a valid date" : null);
        setDateRangeError(((!isNaN(from) && !isNaN(to)) && from >= to) ? "From date must be earlier than To date" : null);
    }

    const onClose = () => {
        dispatch(closeDialog());
    }

    const onSave = () => {
        dispatch(changeDateRange(fromDate, toDate));
    }

    return (
        <Dialog title={"Change Invoice History Dates"}>
            <DialogContent>
                <div className={styles.content}>
                    <GroupContainer label="From Date">
                        <div className={styles.dateSelector}>
                            <DateSelector defaultValue={fromDate} onChange={handleFromDateChanged} />
                            <div className={styles.time}>
                                {Dates.fromMillis(fromDate).isValid()
                                    ? Dates.fromMillis(fromDate).toString(Dates.DATE_FORMAT_TIME)
                                    : Dates.now().startOfDay().toString(Dates.DATE_FORMAT_TIME)
                                }
                            </div>
                        </div>
                        <div className={styles.message}>
                            {!fromDateError &&
                                <div>&nbsp;</div>
                            }
                            {fromDateError &&
                                <div>{fromDateError}</div>
                            }
                        </div>
                    </GroupContainer>
                    <GroupContainer label="To Date">
                        <div className={styles.dateSelector}>
                            <DateSelector defaultValue={toDate} onChange={handleToDateChanged} />
                            <div className={styles.time}>
                                {
                                    Dates.fromMillis(toDate).isValid()
                                        ? Dates.fromMillis(toDate).toString(Dates.DATE_FORMAT_TIME)
                                        : Dates.now().endOfDay().toString(Dates.DATE_FORMAT_TIME)
                                }
                            </div>
                        </div>
                        <div className={styles.message}>
                            {!toDateError &&
                                <div>&nbsp;</div>
                            }
                            {toDateError &&
                                <div>{toDateError}</div>
                            }
                        </div>
                    </GroupContainer>
                    <div className={styles.invalidDateRange}>
                        {dateRangeError &&
                            <div>{dateRangeError}</div>
                        }
                        {!dateRangeError &&
                            <div>&nbsp;</div>
                        }
                    </div>
                </div>
            </DialogContent>
            <DialogFooter>
                <div className={styles.footer}>
                    <ButtonOk onClick={onSave} disabled={fromDateError || toDateError || dateRangeError} />
                    <ButtonCancel onClick={onClose} />
                </div>
            </DialogFooter>

        </Dialog>
    )
}

const InvoiceHistoryChangeDatesDialog = () => {
    const show = useSelector(state => getDialogIsOpen(state));

    return show ? <DialogImplementation /> : null;
}

export default InvoiceHistoryChangeDatesDialog;