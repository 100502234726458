import React, { useState } from 'react'

import { Divider } from '../Divider'

import {
    ButtonOk,
    PrimaryLabel,
    DangerLabel,
    SuccessLabel,
    Form,
    FormFooter,
    CurrentPasswordInput,
    NewPasswordInput,
} from '../Forms';

import axios from 'axios';

function UpdatePassword(oldPassword, newPassword) {

    const data = {
        oldPassword,
        newPassword
    }

    return axios.put('/api/user/password', data);
}

export const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [updatePasswordErrors, setUpdatePasswordErrors] = useState('');
    const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false);

    const updatePassword = () => {
        setCurrentPasswordError('');
        setNewPasswordError('');
        setConfirmPasswordError('');
        setUpdatePasswordErrors(null);
        setUpdatePasswordSuccess(false);

        if (!currentPassword) {
            setCurrentPasswordError("Old password is required");
            return;
        }

        if (!newPassword) {
            setNewPasswordError("New password is required");
            return;
        }

        if (!confirmPassword) {
            setConfirmPasswordError("Confirm password is required");
            return;
        }

        if (newPassword !== confirmPassword) {
            setNewPasswordError("New password does not match Confirm password");
            return;
        }

        UpdatePassword(currentPassword, newPassword)
            .then(() => {
                setUpdatePasswordSuccess(true);
            })
            .catch(error => {
                if (error.response) {
                    setUpdatePasswordErrors(["Unable to update password"])
                    if (error.response.data && error.response.data.length) {
                        setUpdatePasswordErrors(error.response.data);
                    }
                }
            })
    }

    const handleCurrentPasswordChange = event => {
        setCurrentPassword(event.target.value);
    }

    const handleNewPasswordChange = event => {
        setNewPassword(event.target.value);
    }

    const handleConfirmPasswordChange = event => {
        setConfirmPassword(event.target.value);
    }

    return (
        <Form>
            <Divider>Change Password</Divider>
            <PrimaryLabel>Old Password</PrimaryLabel>
            <CurrentPasswordInput id="oldPassword" placeholder={"old password"} onChange={handleCurrentPasswordChange} />
            {currentPasswordError !== '' &&
                <DangerLabel>{currentPasswordError}</DangerLabel>
            }
            <PrimaryLabel>New Password</PrimaryLabel>
            <NewPasswordInput id="newPassword" placeholder={"new password"} onChange={handleNewPasswordChange} />
            {newPasswordError !== '' &&
                <DangerLabel>{newPasswordError}</DangerLabel>
            }
            <PrimaryLabel>Confirm Password</PrimaryLabel>
            <NewPasswordInput id="confirmPassword" placeholder={"confirm password"} onChange={handleConfirmPasswordChange} />
            {confirmPasswordError !== '' &&
                <DangerLabel>{confirmPasswordError}</DangerLabel>
            }
            {updatePasswordSuccess &&
                <SuccessLabel>Your password has been changed</SuccessLabel>
            }
            {(updatePasswordErrors && updatePasswordErrors.length) &&
                <div>
                    <DangerLabel>Your password could not be updated</DangerLabel>
                    {updatePasswordErrors.map((error, index) => {
                        return (
                            <DangerLabel key={"error_label_" + index}>{error}</DangerLabel>
                        )
                    })}
                </div>
            }
            <FormFooter>
                <ButtonOk onClick={updatePassword}>Update password</ButtonOk>
            </FormFooter>
        </Form>
    );
}
