import React from 'react'

//import Styles from '../../styles/03-components/_sidePanel.module.scss'
import styles from './SidePanelBody.module.css';

const SidePanelBody = (props) => {
    const { children } = props;

    return (
        <main className={styles.root}>{children}</main>
    )
}

export default SidePanelBody
