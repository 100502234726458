import React from 'react'

import {
    IconSettings,
} from '../Icon/Icon';

import useAuthentication from '../../authorization/useAuthentication';
import { useSelector, useDispatch } from 'react-redux';
import { userCanViewAdminPage } from '../../redux/user/permissions';
import { open as openUserSettings } from '../../redux/user/settings';

import NavBar from './NavBar';
import NavLogo from './NavLogo';
import NavLink from './NavLink';
import NavButton from './NavButton';
import NavLinkList from './NavLinkList';

export const SHIP_VIEW_ROUTE = "/shipview";
export const ADMIN_ROUTE = "/admin";

function getCurrentPath(state) {
    if (state && state.router && state.router.location) {
        return state.router.location.pathname;
    }
    return null;
}

const GlobalNavigation = () => {
    const [auth] = useAuthentication();
    const showAdmin = useSelector(state => userCanViewAdminPage(state));
    const dispatch = useDispatch()

    const path = useSelector(state => getCurrentPath(state));

    // TODO: think we need to clean up the [auth] before exiting with a  useEffect ?

    if (!auth.isAuthenticated) {
        return null;
    }

    return (
        <NavBar>
            <NavLogo />
            {auth.isAuthenticated &&
                <NavLinkList>
                    <NavLink text={"Ship View"} to={SHIP_VIEW_ROUTE} isActive={path === SHIP_VIEW_ROUTE} />
                    {showAdmin &&
                        <NavLink text={"Admin View"} to={ADMIN_ROUTE} isActive={path === ADMIN_ROUTE} />
                    }
                </NavLinkList>
            }
            {/*{auth.isAuthenticated*/}
            {/*    &&*/}
            {/*    <NavButton icon={<IconNotification title={"Notifications"} />} />*/}
            {/*}*/}
            {auth.isAuthenticated
                &&
                <NavButton
                    icon={<IconSettings title={"Settings"} />}
                    onClick={() => { dispatch(openUserSettings()) }}
                >
                    {auth.userName}
                </NavButton>
            }
        </NavBar>
    )
}

export default GlobalNavigation
