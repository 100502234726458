import React from 'react'
import classnames from 'classnames'

import styles from './TabbedSidePanelContent.module.css'

const TabbedSidePanelContent = ({noPadding, children}) => {

    return (
        <div className={`${classnames(styles.root, noPadding && styles.noPadding)}`}>
            {children}
        </div>
    )
}

export default TabbedSidePanelContent;