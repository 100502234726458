import React, { useEffect } from 'react';

import TabbedSidePanelContentBody from '../../TabbedSidePanel/TabbedSidePanelContentBody'

import { useSelector, useDispatch } from 'react-redux';

import { getIsPackages } from '../../../redux/shipview/contentPanel';

import { getSelectedInstallation } from '../../../redux/shipview/installations';

import {loadData as loadCosts } from '../../../redux/shipview/selectedInstallation/packages/costs';

import {
    loadData as loadScheduledChanges,
    dataInvalidated as scheduledChangesInvalidated,
} from '../../../redux/shipview/selectedInstallation/packages/scheduledChanges';

import {
    loadData as loadChangeHistory,
    dataInvalidated as changeHistoryInvalidated,
} from '../../../redux/shipview/selectedInstallation/packages/changeHistory';

import Costs from './Costs';

const Packages = () => {

    const show = useSelector(state => getIsPackages(state));
    const installation = useSelector(state => getSelectedInstallation(state));
    const scheduledChangesIsInvalidated = useSelector(state => scheduledChangesInvalidated(state));
    const changeHistoryIsInvalidated = useSelector(state => changeHistoryInvalidated(state));

    const dispatch = useDispatch();

    useEffect(() => {
        if (installation) {
            dispatch(loadCosts(installation.id));
        }
    }, [dispatch, installation]);

    // update the set of scheduled changes if the package is changed by the user
    useEffect(() => {
        if (installation) {
            dispatch(loadScheduledChanges(installation.id));
        }
    }, [dispatch, installation, scheduledChangesIsInvalidated])

    useEffect(() => {
        if (installation) {
            dispatch(loadChangeHistory(installation.id));
        }
    }, [dispatch, installation, changeHistoryIsInvalidated])


    if (show) {
        return (
            <TabbedSidePanelContentBody noPadding={false}>
                {installation &&
                    <Costs />
                }
            </TabbedSidePanelContentBody>
        )
    }

    return null;
}

export default Packages;