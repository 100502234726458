import React from 'react'

const Message = ({ message, children }) => {
    return (
        <div className="authorizationLayoutMessage">
            <img className="authorizationLayoutMessageLogo" src="/assets/AppLogo.png" alt="Brand logo" />
            <h2 className="authorizationLayoutMessageSubTitle">
                {message}
            </h2>
            <div>
                {children}
            </div>
        </div>
    )
}

export default Message;