/*
 * Hook to provide Authentication details
 */
import { useEffect, useState } from 'react';
import authService from './AuthorizeService';

const useAuthentication = () => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userId, setUserId] = useState(null);
    const [userName, setUserName] = useState(null);

    useEffect(() => {
        var subscription = null;

        async function getIsAuthenticated() {
            const [isAuth, profile] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);

            setIsAuthenticated(isAuth);
            setUserId(profile ? profile.sub : null);
            setUserName(profile ? profile.name : null);

//            console.log(isAuth ? "user logged in" : "user logged out");
//            if (profile) {
//                console.log("user : " + JSON.stringify(profile));
//            }
        }

        subscription = authService.subscribe(() => getIsAuthenticated());

        getIsAuthenticated();

        return function () {
            authService.unsubscribe(subscription);
        }
    }, [isAuthenticated]);

    return [{ isAuthenticated, userId, userName }];
}

export default useAuthentication;