/* 
 * redux functions for : store.user.settings
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */

import { LOGGED_OUT } from './identity';

/*
 * Actions
 */
const CLOSE = 'fleetwide/user/settings/CLOSE';
const OPEN = 'fleetwide/user/settings/OPEN';

/*
 * Initial State
 */

const initialState = {
    isOpen: false,
};

/*
 * Reducer Functions
 */
const handleClose = (state) => {
    return {
        ...state,
        isOpen: false
    }
}

const handleOpen = (state) => {
    return {
        ...state,
        isOpen: true
    }
}
const handleUserLoggedOut = () => {
    return Object.assign({}, initialState);
}
/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLOSE:
            return handleClose();
        case OPEN:
            return handleOpen();
        case LOGGED_OUT:
            return handleUserLoggedOut();
        default:
            return state;
    }
}

/* 
 * Action Creators
 */
export function close() {
    return {
        type: CLOSE
    }
}

export function open() {
    return {
        type: OPEN
    }
}

/*
 * Selectors
 */
const sliceState = (store) => {
    return store.user.settings;
}

export const getIsOpen = (store) => {
    return sliceState(store) ? sliceState(store).isOpen : initialState.isOpen;
}

/*
 * Side Effects
 */
