import React from 'react'

import { useHistory } from 'react-router-dom';

import { ButtonPrimary } from '../Forms';

import { ApplicationPaths } from '../../authorization/ApiAuthorizationConstants';

import { useDispatch } from 'react-redux';

import { close as closeUserSettings } from '../../redux/user/settings';

export const SignOut = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    function logout() {
        dispatch(closeUserSettings());
        const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
        history.push(logoutPath);
    }

    return (
        <ButtonPrimary onClick={logout}>Sign Out</ButtonPrimary>
    )
}