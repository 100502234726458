import React from 'react'
import classnames from 'classnames';
import styles from './NavButton.module.css'

const NavButton = ({ icon, onClick, children }) => {
    return (
        <button
            type="button"
            className={`${classnames(styles.button, children || styles.iconOnly,)}`}
            onClick={onClick}
        >
            <div className={styles.buttonIcon}>
                {icon}
            </div>
            {children}
        </button>
    )
}

export default NavButton
