/**
 * Returns a string representing the supplied number value formatted using
 * the current browser language.  The number of decimal places to display
 * is controlled by the decimalPlaces parameter.
 * @param {number} value
 * @param {number} decimalPlaces
 */
export function formatNumber(value, decimalPlaces) {
    if (isNaN(value)) return '';
    const dp = +decimalPlaces;

    return new Intl.NumberFormat(
        navigator.language,
        {
            minimumFractionDigits: dp,
            maximumFractionDigits: dp
        }
    ).format(value);
}

