/*
 * Admin pages are currently being delivered as AspNet.Core Razor pages.
 * This component just redirects to the relevant index page.
 */
import { useEffect } from 'react';

const AdminViewPage = (props) => {
    const { location } = props;

    useEffect(() => {
        window.location.replace(location.pathname);
    })

    return (
        null
    );
}

export default AdminViewPage;