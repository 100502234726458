import React from "react";

import styles from "./GroupContainer.module.css";

const GroupContainer = (props) => {

    const { label, children } = props;

    return (
        <div className={styles.root}>
            <div className={styles.label}>{label}</div>
            {children}
        </div>
    );
}

export default GroupContainer;