import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
    getFromDate,
    getToDate,
    getData as getInvoiceHistory,
    isFetching as getInvoiceHistoryLoading,
    isError as getInvoiceHistoryError,
    loadData,
    openDialog as showChangeDatesDialog,
} from '../../../redux/shipview/selectedInstallation/invoices';

import { getSelectedInstallation } from '../../../redux/shipview/installations';

import { Divider } from '../../Divider';

import { Table, TableHeaderCell, TableBodyCell } from '../../Table';

import DateButton from '../../DateButton/DateButton';

import {
    formatNumber,
} from '../../../utils';

import { DateDisplay } from '../../Forms';

import styles from './InvoiceHistory.module.css';

const InvoiceHistory = () => {

    const installation = useSelector(state => getSelectedInstallation(state));
    const invoiceHistory = useSelector(state => getInvoiceHistory(state));
    const invoiceHistoryLoading = useSelector(state => getInvoiceHistoryLoading(state));
    const invoiceHistoryError = useSelector(state => getInvoiceHistoryError(state));

    const fromDate = useSelector(state => getFromDate(state));
    const toDate = useSelector(state => getToDate(state));

    const dispatch = useDispatch();

    let historyItems = [];

    useEffect(() => {
        if (installation) {
            dispatch(loadData(installation.id, fromDate, toDate));
        }
    }, [dispatch, installation, fromDate, toDate])

    if (invoiceHistoryLoading || invoiceHistoryError) {
        historyItems = [];
    } else if (invoiceHistory && invoiceHistory.length) {
        historyItems = invoiceHistory.map((item, index) => {
            const href = '/api/invoices/download?installationId=' + installation.id + '&invoiceId=' + item.invoiceId;
            return (
                <tr key={`invoice-history-id-${index}`}>
                    <TableBodyCell><a href={href}>{item.invoiceNumber}</a></TableBodyCell>
                    <TableBodyCell><DateDisplay milliseconds={item.date} /></TableBodyCell>
                    <TableBodyCell align={"right"}>{formatNumber(item.total, 2)}</TableBodyCell>
                    <TableBodyCell align={"right"}>{formatNumber(item.tax, 2)}</TableBodyCell>
                </tr>
            )
        })
    }

    const handleOnChangeDates = () => {
        dispatch(showChangeDatesDialog());
    }

    const changeDatesTitle = "Change Invoice History Dates";

    return (
        <>
            <Divider>Invoice History</Divider>
            <div className={styles.dateRangeContainer}>
                <div className={styles.dateRangeLabel}>Showing Invoices from</div>
                <div className={styles.changeDatesButton}>
                    <DateButton onClick={handleOnChangeDates} title={changeDatesTitle}>
                        <DateDisplay milliseconds={fromDate} showTime={false} title={changeDatesTitle} />
                    </DateButton>
                </div>
                <div className={styles.dateRangeLabel}>to</div>
                <div className={styles.changeDatesButton}>
                    <DateButton onClick={handleOnChangeDates} title={changeDatesTitle}>
                        <DateDisplay milliseconds={toDate} showTime={false} title={changeDatesTitle} />
                    </DateButton>
                </div>
            </div>
            <Table>
                <thead>
                    <tr>
                        <TableHeaderCell>Invoice Number</TableHeaderCell>
                        <TableHeaderCell>Invoice Date</TableHeaderCell>
                        <TableHeaderCell align={"right"}>Total $</TableHeaderCell>
                        <TableHeaderCell align={"right"}>Tax $</TableHeaderCell>
                    </tr>
                </thead>
                <tbody>
                    {invoiceHistoryLoading && <tr><td><p>Loading...</p></td></tr>}
                    {invoiceHistoryError && <tr><td><p>Sorry - something went wrong</p></td></tr>}
                    {historyItems}
                </tbody>
            </Table>
        </>
    )
}

export default InvoiceHistory;