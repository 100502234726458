import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import styles from "./WeatherLegends.module.css";

import classnames from "classnames";

import AerisWeather from '@aerisweather/javascript-sdk';
import '@aerisweather/javascript-sdk/dist/styles/styles.css';

const aeris = new AerisWeather('E8m6lbzLk0Siy4aZocdJq', 'fdOY36urjBYpEOs55n8zliYpZ1HdzFdDdZaC1vN0');

export const WeatherLegends = () => {

    const rainLegend = useRef(null);
    const rainLegendElement = useRef(null);

    const wavesLegend = useRef(null);
    const wavesLegendElement = useRef(null);

    const [visible, setVisible] = useState(false);

    const handleVisibleToggle = () => {
        setVisible(prev => !prev);
    }

    useEffect(() => {

        // I'm including the visible flag because if we allow the legends to be loaded when the panel
        // is hidden, the (flex) layout fails and the result is horrid
        if (!rainLegend.current && visible) {
            //console.log("got rain");
            aeris.views().then((views) => {
                rainLegend.current = new views.Legend(rainLegendElement.current, {
                    size: {
                        width: 600,
                        height: 40
                    },
                    autosize: false,
                });
                rainLegend.current.add("radar-global");
            });
        }
    });

    useEffect(() => {

        if (!wavesLegend.current && visible) {

            aeris.views().then((views) => {
                wavesLegend.current = new views.Legend(wavesLegendElement.current, {
                    size: {
                        width: 600,
                        height: 40
                    },
                    autosize: false,
                    metric: true,
                });
                wavesLegend.current.add("wave-heights");
            });
        }
    });

    return (
        <div className={styles.root}>
            <div className={styles.toggler}>
                <div></div>
                <div className={styles.togglerInner} onClick={handleVisibleToggle}>
                    <label className={styles.label}>Map Legends</label>
                    <FontAwesomeIcon icon={visible ? faAngleDown : faAngleUp} />
                </div>
                <div></div>
            </div>
            <div className={`${classnames(styles.legends, !visible && styles.hidden)}`}>
                <div className={styles.legend} ref={wavesLegendElement}></div>
                <div className={styles.legend} ref={rainLegendElement}></div>
            </div>
        </div>
    );
}


