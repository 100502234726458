/*
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */
import axios from 'axios';

import { LOGGED_OUT } from '../../../user/identity';

/*
 * Actions
 */
const OPEN_DIALOG = 'auranow/shipview/selectedInstallation/packages/packageChanges/OPEN_DIALOG';
const CLOSE_DIALOG = 'auranow/shipview/selectedInstallation/packages/packageChanges/CLOSE_DIALOG';
const DATA_RECEIVED = 'auranow/shipview/selectedInstallation/packages/packageChanges/DATA_RECEIVED';
const DATA_FAILED = 'auranow/shipview/selectedInstallation/packages/packageChanges/DATA_FAILED';
const REMOVE_ITEM = 'auranow/shipview/selectedInstallation/packages/packageChanges/REMOVE_ITEM';
const ADD_ITEM = 'auranow/shipview/selectedInstallation/packages/packageChanges/ADD_ITEM';
const ADD_ITEM_AND_EDIT = 'auranow/shipview/selectedInstallation/packages/packageChanges/ADD_ITEM_AND_EDIT';
const EDIT_ITEM = 'auranow/shipview/selectedInstallation/packages/packageChanges/EDIT_ITEM';
const CANCEL_EDIT_ITEM = 'auranow/shipview/selectedInstallation/packages/packageChanges/CANCEL_EDIT_ITEM';
const SAVE_EDIT_ITEM = 'auranow/shipview/selectedInstallation/packages/packageChanges/SAVE_EDIT_ITEM';
const SAVING = 'auranow/shipview/selectedInstallation/packages/packageChanges/SAVING';
const SAVE_FAILED = 'auranow/shipview/selectedInstallation/packages/packageChanges/SAVE_FAILED';
export const PACKAGES_CHANGED = 'auranow/shipview/selectedInstallation/packages/packageChanges/PACKAGES_CHANGED';

/*
 * Initial State
 */
const initialState = {
    isFetching: false,
    isError: false,
    dialogIsOpen: false,
    isSaving: false,
    saveFailed: false,
    wanInterfaceId: null,
    sabaEquipmentId: null,
    baseRatePlanName: null,
    editItem: null,
    availablePackages: [],
    currentServerDate: null,
    newChangeDefaultStartDate: null,
    endOfToday: null,
    changes: [],
    // flag that determines if a new package change can be 'auto created' by the UI when there are no existing package changes
    canAddDefaultNew: false, 
}

/*
 * Reducer Functions
 */
const handleOpenDialog = () => {
    // effectively this is data requested
    return {
        ...initialState,
        dialogIsOpen: true,
        isFetching: true,
    }
}

const handleDataReceived = (state, data) => {
    return {
        ...state,
        isFetching: false,
        isError: false,
        wanInterfaceId: data.wanInterfaceId,
        sabaEquipmentId: data.sabaEquipmentId,
        baseRatePlanName: data.baseRatePlanName,
        availablePackages: data.availablePackages,
        currentServerDate: data.currentServerDate,
        newChangeDefaultStartDate: data.newChangeDefaultStartDate,
        endOfToday: data.endOfToday,
        changes: data.scheduledChanges,
        // flag that determines if a new package change can be 'auto created' by the UI when there are no existing package changes
        canAddDefaultNew: data.scheduledChanges && data.scheduledChanges.length === 0,
    }
}

const handleDataFailed = (state) => {
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
}

const handleRemoveItem = (state, item) => {
    const ret = { ...state };
    ret.changes[item.id] = {
        ...ret.changes[item.id],
        deleted: true,
    }
    return ret;

}

const handleAddItem = (state, payload) => {
    const ret = {
        ...state,
        canAddDefaultNew: false,
        changes: [
            ...state.changes,
            {
                ...payload.item,
                id: state.changes.length,
                equipmentBillingSchemeId: null,
                canBeRemoved: true,
                changeIsInProgress: false,
                added: true,
                edited: false,
                deleted: false,
            }
        ],
    };

    return ret;
}

const handleAddItemAndEdit = (state, payload) => {
    const ret = {
        ...state,
        canAddDefaultNew: false,
        changes: [
            ...state.changes,
            {
                ...payload.item,
                id: state.changes.length,
                equipmentBillingSchemeId: null,
                canBeRemoved: true,
                changeIsInProgress: false,
                added: true,
                edited: false,
                deleted: false,
            }
        ],
        editItem: {
            id: state.changes.length,
            minDate: payload.minDate,
            maxDate: payload.maxDate
        }
    }

    return ret;
}

const handleEditItem = (state, item) => {
    return {
        ...state,
        isSaving: false,
        saveFailed: false, // reset in case user edits after a top level save
        editItem: item
    }
}

const handleCancelEditItem = (state) => {
    return {
        ...state,
        editItem: null
    }
}

const handleSaveEditItem = (state, item) => {
    const ret = {
        ...state,
        editItem: null,
        changes: [...state.changes],
    }
    ret.changes[item.id] = {
        ...ret.changes[item.id],
        ...item,
        edited: true
    }

    return ret;
}

const handleSaving = (state) => {
    return {
        ...state,
        isSaving: true,
        saveFailed: false,
    }
}

const handleSaveFailed = (state) => {
    return {
        ...state,
        isSaving: false,
        saveFailed: true
    }
}

const handleCloseDialog = () => {
    return { ...initialState };
}

const handleUserLoggedOut = () => {
    return { ...initialState };
}

/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case OPEN_DIALOG:
            return handleOpenDialog();
        case DATA_RECEIVED:
            return handleDataReceived(state, action.payload);
        case DATA_FAILED:
            return handleDataFailed(state);
        case ADD_ITEM:
            return handleAddItem(state, action.payload);
        case ADD_ITEM_AND_EDIT:
            return handleAddItemAndEdit(state, action.payload);
        case REMOVE_ITEM:
            return handleRemoveItem(state, action.payload);
        case EDIT_ITEM:
            return handleEditItem(state, action.payload);
        case CANCEL_EDIT_ITEM:
            return handleCancelEditItem(state);
        case SAVE_EDIT_ITEM:
            return handleSaveEditItem(state, action.payload);
        case CLOSE_DIALOG:
            return handleCloseDialog();
        case SAVING:
            return handleSaving(state);
        case SAVE_FAILED:
            return handleSaveFailed(state);
        case LOGGED_OUT:
            return handleUserLoggedOut();
        default:
            return state;
    }
}

/*
 * Action Creators
 */
function actionOpenDialog() {
    return {
        type: OPEN_DIALOG,
    }
}

function actionDataReceived(data) {
    return {
        type: DATA_RECEIVED,
        payload: data
    }
}

function actionDataFailed() {
    return {
        type: DATA_FAILED
    }
}

function actionRemoveItem(itemId) {
    return {
        type: REMOVE_ITEM,
        payload: { id: itemId }
    }
}

function actionAddItem(startDate, endDate, ratePlanId, ratePlanName, dailyAdjustment) {
    return {
        type: ADD_ITEM,
        payload: {
            item: {
                startDate,
                endDate,
                ratePlanId,
                ratePlanName,
                dailyAdjustment
            },
        }
    }
}

function actionAddItemAndEdit(startDate, endDate, ratePlanId, ratePlanName, dailyAdjustment, minDate, maxDate, endOfToday) {
    return {
        type: ADD_ITEM_AND_EDIT,
        payload: {
            item: {
                startDate,
                endDate,
                ratePlanId,
                ratePlanName,
                dailyAdjustment
            },
            minDate,
            maxDate,
            endOfToday,
        }
    }
}

function actionEditItem(itemId, minDate, maxDate, endOfToday) {
    return {
        type: EDIT_ITEM,
        payload: {
            id: itemId,
            minDate: minDate,
            maxDate: maxDate,
            endOfToday: endOfToday
        }
    }
}

function actionCancelEditItem() {
    return {
        type: CANCEL_EDIT_ITEM
    }
}

function actionSaveEditItem(id, startDate, endDate, ratePlanId, ratePlanName, dailyAdjustment) {
    return {
        type: SAVE_EDIT_ITEM,
        payload: {
            id,
            startDate,
            endDate,
            ratePlanId,
            ratePlanName,
            dailyAdjustment,
        }
    }
}

function actionSaving() {
    return {
        type: SAVING
    }
}

function actionSaveFailed() {
    return {
        type: SAVE_FAILED
    }
}

function actionPackagesChanged() {
    return {
        type: PACKAGES_CHANGED
    }
}

function actionCloseDialog() {
    return {
        type: CLOSE_DIALOG
    }
}

/*
 * Selectors
 */
const sliceState = (store) => {
    return store.shipview.selectedInstallation.packages.packageChanges;
}

export const getDialogIsOpen = (store) => {
    return sliceState(store) ? sliceState(store).dialogIsOpen : false;
}

const getWanInterfaceId = (store) => {
    return sliceState(store) ? sliceState(store).wanInterfaceId : null;
}

export const getChanges = (store) => {
    return sliceState(store) ? sliceState(store).changes : [];
}

export const getAvailablePackages = (store) => {
    return sliceState(store) ? sliceState(store).availablePackages : [];
}

export const getBaseRatePlanName = (store) => {
    return sliceState(store) ? sliceState(store).baseRatePlanName : null;
}

export const getVisibleChanges = (store) => {

    function filterItems(value) {
        return value.deleted === false;
    }

    function sortItems(a, b) {
        if (a.startDate < b.startDate) return -1;
        if (b.startDate < a.startDate) return 1;
        return 0;
    }

    /*important - filter first to create a new array, then sort */
    return getChanges(store).filter(filterItems).sort(sortItems);
}

export const getNewChangeDefaultStartDate = (store) => {
    return sliceState(store) ? sliceState(store).newChangeDefaultStartDate : null;
}

export const getEndOfToday = (store) => {
    return sliceState(store) ? sliceState(store).endOfToday : null;
}

export const getDefaultMinDate = (store) => {
    return sliceState(store) ? sliceState(store).newChangeDefaultStartDate : null;
}

export const getIsEditing = (store) => {
    const item = sliceState(store) ? sliceState(store).editItem : null;
    return (item !== null);
}

export const getEditItem = (store) => {
    const item = sliceState(store) ? sliceState(store).editItem : null;

    if (!item) return null;

    const change = getChanges(store)[item.id];

    if (!change) return null;

    return {
        ...item,
        startDate: change.startDate,
        endDate: change.endDate,
        ratePlanId: change.ratePlanId,
        changeIsInProgress: change.changeIsInProgress,
        canBeRemoved: change.canBeRemoved,
        endOfToday: getEndOfToday(store)
    }
}

export const getIsFetching = (store) => {
    return sliceState(store) ? sliceState(store).isFetching : false;
}

export const getIsError = (store) => {
    return sliceState(store) ? sliceState(store).isError : false;
}

export const getIsSaving = (store) => {
    return sliceState(store) ? sliceState(store).isSaving : false;
}

export const getSaveFailed = (store) => {
    return sliceState(store) ? sliceState(store).saveFailed : false;
}

export const getCanAddDefaultNew = (store) => {
    return sliceState(store) ? sliceState(store).canAddDefaultNew : false;
}

/*
 * Side Effects
 */
// axios cancellation is based on the deprecated cancellable Promise
let CancelToken = axios.CancelToken;
let canceller;

/**
 * 
 * @param {any} wanInterfaceId
 * @param {any} sabaEquipmentId
 * @param {any} baseRatePlanName - not nice!! passed to api so we can get it back for display.  
 * Doing this because it's not obvious how to get the base rate plan given the other identifiers.
 */
const fetchData = (wanInterfaceId, sabaEquipmentId, baseRatePlanName) => {

    return function (dispatch) {
        dispatch(actionOpenDialog());

        // cancel the previous request if we have a valid canceller
        canceller && canceller();

        const url = '/api/packages/packagechange?wanInterfaceId=' + wanInterfaceId + '&sabaEquipmentId=' + sabaEquipmentId + '&baseRatePlanName=' + baseRatePlanName;

        return axios.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // create a canceller for this request
                // an executor function receives a cancel function as a parameter
                canceller = c;
            })
        })
            .then(response => {
                //console.log("packages changes by wan interface " + JSON.stringify(response));
                // dispatch the data to the store
                var data = {
                    wanInterfaceId: response.data.wanInterfaceId,
                    sabaEquipmentId: response.data.sabaEquipmentId,
                    baseRatePlanName: response.data.baseRatePlanName,
                    currentServerDate: response.data.currentServerDate,
                    newChangeDefaultStartDate: response.data.newChangeDefaultStartDate,
                    endOfToday: response.data.endOfToday,
                    availablePackages: response.data.availablePackages,
                    scheduledChanges: response.data.scheduledChanges.map((value, index) => {

                        const availablePackage = response.data.availablePackages.find(p => p.ratePlanId === value.ratePlanId);

                        return {
                            ...value,
                            id: index,
                            ratePlanName: availablePackage ? availablePackage.ratePlanName : null,
                            dailyAdjustment: availablePackage ? availablePackage.dailyAdjustment : null,
                            canBeRemoved: value.canBeRemoved,
                            changeIsInProgress: value.changeIsInProgress,
                            added: false,
                            edited: false,
                            deleted: false,
                        }
                    })
                }

                dispatch(actionDataReceived(data));
            })
            .catch(function (error) {
                if (axios.isCancel(error)) {
                    // console.log("error");
                } else {
                    //console.log("error " + JSON.stringify(error));
                    dispatch(actionDataFailed());
                }
            });

        // TODO : other errors should be handled using React Error Boundaries
        // see https://reactjs.org/docs/error-boundaries.html
    }
}

function shouldFetchData(state) {
    const sliceData = sliceState(state);
    if (!sliceData) {
        return false;
    } else if (sliceData.isFetching) {
        return false;
    } else {
        return true;
    }
}

// Thunk to open the Change Package Dialog for the specific wanInterfaceId and sabaEquipmentId
// Nasty hack for baseRatePlanName -- see other comments elsewhere
export function openDialog(wanInterfaceId, sabaEquipmentId, baseRatePlanName) {
    return (dispatch, getState) => {
        if (shouldFetchData(getState())) {
            return dispatch(fetchData(wanInterfaceId, sabaEquipmentId, baseRatePlanName));
        } else {
            return Promise.resolve();
        }
    }
}

export function addItem(startDate, endDate, ratePlanId, ratePlanName, dailyAdjustment) {
    return (dispatch, getState) => {
        return dispatch(actionAddItem(startDate, endDate, ratePlanId, ratePlanName, dailyAdjustment));
    }
}

export function addItemAndEdit(startDate, endDate, ratePlanId, ratePlanName, dailyAdjustment, minDate, maxDate, endOfToday) {
    return (dispatch, getState) => {
        return dispatch(actionAddItemAndEdit(startDate, endDate, ratePlanId, ratePlanName, dailyAdjustment, minDate, maxDate, endOfToday));
    }
}

export function removeItem(itemId) {
    return (dispatch, getState) => {
        return dispatch(actionRemoveItem(itemId));
    }
}

/**
 * Starts the process of editing an item
 * @param {any} itemId      Id of the item to be edited
 * @param {any} minDate     Minimum date in unix milliseconds that the item can have as a start date.
 * @param {any} maxDate     Maximum date in unix milliseconds that the item can have as an end date.
 */
export function editItem(itemId, minDate, maxDate, endOfToday) {
    return (dispatch, getState) => {
        return dispatch(actionEditItem(itemId, minDate, maxDate, endOfToday));
    }
}

/**
 * Cancels the editing of the currently edited item.
 */
export function cancelEditItem() {
    return (dispatch, getState) => {
        return dispatch(actionCancelEditItem());
    }
}

/**
 * Saves the changes for the current edit item.
 * @param {any} id              Id of the item being edited
 * @param {any} startDate       Start date for the edited item
 * @param {any} endDate         End date for the edited item
 * @param {any} ratePlanId      Id of the Rate Plan for the edited item
 * @param {any} ratePlanName    Name of the Rate Plan for the edited item
 * @param {any} dailyAdjustment Daily Adjustment associated with the Rate Plan for the edited item.
 */
export function saveEditItem(id, startDate, endDate, ratePlanId, ratePlanName, dailyAdjustment) {
    return (dispatch, getState) => {
        return dispatch(actionSaveEditItem(id, startDate, endDate, ratePlanId, ratePlanName, dailyAdjustment));
    }
}

/**
 * Cancels all edits and changes for the set of Package Changes.
 */
export function cancelChanges() {
    return (dispatch, getState) => {
        return dispatch(actionCloseDialog());
    }
}

/**
 * Saves all current packages changes to the backend data store.
 */
export function saveChanges() {
    return (dispatch, getState) => {
        //console.log("should be saving all changes");

        // tell everyone were doing the save
        dispatch(actionSaving());

        const data = (() => {
            // changes can be 'unchanged', 'added', 'removed' (either as new or existing), 'edited' (either as new or existing)
            // we want to find the 'unchanged', 'added', existing 'removed', existing 'edited'
            const items = getChanges(getState()).map(value => {
                const action = ((item) => {
                    // existing unchanged
                    if ((item.equipmentBillingSchemeId !== null) && !item.deleted && !item.edited) {
                        return 'unchanged';
                    }
                    // existing edited
                    else if ((item.equipmentBillingSchemeId !== null) && !item.deleted && item.edited) {
                        return 'update';
                    }
                    // existing deleted
                    else if ((item.equipmentBillingSchemeId !== null) && item.deleted) {
                        return 'delete'
                    }
                    // new
                    else if ((item.equipmentBillingSchemeId === null) && !item.deleted) {
                        return 'new'
                    }

                    return null;
                })(value);

                return {
                    action: action,
                    startDate: value.startDate,
                    endDate: value.endDate,
                    ratePlanId: value.ratePlanId,
                    equipmentBillingSchemeId: value.equipmentBillingSchemeId
                }
            }).filter(value => value.action);  // filter out items with null action which are new items that have been removed during the edit session

            // console.log("items = " + JSON.stringify(items));

            return {
                wanInterfaceId: getWanInterfaceId(getState()),
                packageChanges: items
            };

        })();


        // console.log("saving changes " + JSON.stringify(data));

        const url = '/api/packages/packagechange';

        return axios
            .post(url, data)
            .then(() => {
                dispatch(actionPackagesChanged());
                dispatch(actionCloseDialog());
            })
            .catch(() => {
                // console.log("post failure");
                dispatch(actionSaveFailed());
            });
    }
}
