import React, { useState } from "react";
import styles from "./EndDate.module.css";

import { DateSelector, GroupContainer } from "../../../Forms";

import { Dates } from "../../../../utils/Dates";

const EndDate = (props) => {

    const { minimumDate, onChange } = props;

    const [isNotADate, setIsNotADate] = useState(false);
    const [isTooEarly, setIsTooEarly] = useState(false);
    const [isValid, setIsValid] = useState(true);

    /**
     * Validate the supplied end date
    * @param {any} millis end date in unix epoch milliseconds
    */
    function validateEndDate(millis) {
        const t1 = isNaN(millis);
        setIsNotADate(t1);

        const t2 = (!t1 && millis < minimumDate);
        setIsTooEarly(t2);

        return !t1 && !t2;
    }

    function handleOnChange(millis) {
        const adjusted = millis + Dates.endTime();
        const valid = validateEndDate(adjusted);
        setIsValid(valid);
        onChange(adjusted, valid);
    }

    const endOfDay = Dates.today().endOfDay().toString(Dates.DATE_FORMAT_TIME);

    return (
        <GroupContainer label="End Date">
            <div className={styles.dateSelector}>
                <DateSelector defaultValue={minimumDate} onChange={handleOnChange} />
                <div className={styles.time}>{endOfDay}</div>
            </div>
            <div className={styles.message}>
                {isValid &&
                    <div>&nbsp;</div>
                }
                {isNotADate &&
                    <div>End date is not a valid date</div>
                }
                {isTooEarly &&
                    <div>End date cannot be earlier { Dates.fromMillis(minimumDate).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS) }</div>
                }
            </div>
        </GroupContainer>
    );
}

export default EndDate;