import React from 'react';

import { useSelector } from 'react-redux';

import { getCurrentLocation } from '../../redux/shipview/mapdata';

import LocationDisplay from '../LocationDisplay/LocationDisplay';

const CurrentLocation = () => {

    const currentLocation = useSelector(state => getCurrentLocation(state));

    //console.log("currentLocation " + JSON.stringify(currentLocation));

    if (currentLocation && currentLocation.longitude && currentLocation.latitude) {
        return <LocationDisplay lat={currentLocation.latitude.display} lng={currentLocation.longitude.display} />
    } else {
        return <span>Unknown</span>
    }
}

export default CurrentLocation;