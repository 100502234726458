import React from 'react'

import styles from './Dialogs.module.css';

export const DialogContent = ({ children }) => {
    return (
        <div className={styles.content}>
            {children}
        </div>
    )
}
export const DialogFooter = ({ children }) => {
    return (
        <div className={styles.footer}>
            {children}
        </div>
    );
}

export const Dialog = ({ title, children }) => {

    return (
        <div className={styles.root}>
            <div className={styles.dialog}>
                <div className={styles.header}>{title}</div>
                {children}
            </div>
        </div>
    )
}