import React from 'react'
import PropTypes from 'prop-types'

import DateButton from '../../DateButton/DateButton';
import { DateDisplay, RangeSlider } from '../../Forms';

import styles from './DateRange.module.css'

export const DateRange = ({ fromDate, toDate, interval, currentDate, onCurrentDateChange, onChangeDates, disabled }) => {
    const title = "Map and Vessel Connectivity Dates";

    return (
        <div className={styles.root}>
            <DateButton onClick={onChangeDates} title={title} disabled={disabled}>
                <DateDisplay milliseconds={fromDate} showTime={false} title={title} />
            </DateButton>
            <div className={styles.sliderContainer}>
                <RangeSlider
                    min={fromDate}
                    max={toDate}
                    step={interval}
                    value={currentDate}
                    onChange={onCurrentDateChange}
                    disabled={disabled}
                />
            </div>
            <DateButton onClick={onChangeDates} title={title} disabled={disabled}>
                <DateDisplay milliseconds={toDate} showTime={false} title={title} />
            </DateButton>
        </div >
    )
}

DateRange.propTypes = {
    fromDate: PropTypes.number.isRequired,
    toDate: PropTypes.number.isRequired,
    interval: PropTypes.number.isRequired,
    currentDate: PropTypes.number.isRequired,
    onCurrentDateChange: PropTypes.func.isRequired,
    onChangeDates: PropTypes.func.isRequired,
}