import React from 'react';

import { getShowBeams, toggleBeams } from '../../../redux/shipview/overlays';

import { userCanViewBeams as getUserCanViewBeams } from '../../../redux/user/permissions';

import { useSelector, useDispatch } from 'react-redux';

import styles from './Beams.module.css';

export const Beams = () => {

    const TITLE = "Satellite Beams";

    const showBeams = useSelector(state => getShowBeams(state));
    const userCanViewBeams = useSelector(state => getUserCanViewBeams(state));

    const dispatch = useDispatch();

    const handleToggleBeams = () => {
        dispatch(toggleBeams());
    }

    if (!userCanViewBeams) return null;

    return (
        <div className={styles.container} title={TITLE}>
            <label>
                <input className={styles.button} type="checkbox" checked={showBeams} onChange={handleToggleBeams} />
                <div className={styles.svgContainer} title={TITLE}>
                    <svg className={styles.svg} viewBox="0 0 24 24">
                        <path d="M11.62,1L17.28,6.67L15.16,8.79L13.04,6.67L11.62,8.09L13.95,10.41L12.79,11.58L13.24,12.04C14.17,11.61 15.31,11.77 16.07,12.54L12.54,16.07C11.77,15.31 11.61,14.17 12.04,13.24L11.58,12.79L10.41,13.95L8.09,11.62L6.67,13.04L8.79,15.16L6.67,17.28L1,11.62L3.14,9.5L5.26,11.62L6.67,10.21L3.84,7.38C3.06,6.6 3.06,5.33 3.84,4.55L4.55,3.84C5.33,3.06 6.6,3.06 7.38,3.84L10.21,6.67L11.62,5.26L9.5,3.14L11.62,1M18,14A4,4 0 0,1 14,18V16A2,2 0 0,0 16,14H18M22,14A8,8 0 0,1 14,22V20A6,6 0 0,0 20,14H22Z" />
                    </svg>
                </div>
            </label>
        </div>
    );
}