import React, { useEffect, useState } from 'react';

/*
 * Redux State
 */
import { useDispatch, useSelector } from 'react-redux';

import {
    getIsFetching,
    getIsSaving,
    getSaveFailed,
    getAvailablePackages,
    getCanPerformImmediateUpgrade,
    getImmediateUpgradeEarliestStartDate,
    getImmediateUpgradeEarliestEndDate,
    cancelChanges,
    saveChanges,
} from "../../../../redux/shipview/selectedInstallation/packages/immediateUpgrade";


/*
 * Components
 */
import Dialog from "./Dialog";
import Title from "./Title";
import SavingMessage from "./SavingMessage";
import CannotUpgradeMessage from "./CannotUpgradeMessage";
import EndDate from "./EndDate";
import ConfirmationChecks from "./ConfirmationChecks";
import SaveErrorMessage from "./SaveErrorMessage";
import Buttons from "./Buttons";
import OkButton from "./OkButton";
import CancelButton from "./CancelButton";
import CloseButton from "./CloseButton";
import Package from "./Package";

/**
 * This is a top (ShipView) level component that can't take props
 * associated with a specific piece of equipment. All state management
 * is done through redux, initiated from lower level components.
 * */
const ImmediateUpgrade = () => {

    const isFetching = useSelector(state => getIsFetching(state));
    const isSaving = useSelector(state => getIsSaving(state));
    const saveFailed = useSelector(state => getSaveFailed(state));
    const availablePackages = useSelector(state => getAvailablePackages(state));
    const canPerformImmediateUpgrade = useSelector(state => getCanPerformImmediateUpgrade(state));
    const earliestUpgradeStartDate = useSelector(state => getImmediateUpgradeEarliestStartDate(state));
    const earliestUpgradeEndDate = useSelector(state => getImmediateUpgradeEarliestEndDate(state));

    const [ratePlanId, setRatePlanId] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [endDateIsValid, setEndDateIsValid] = useState(true);
    const [confirmationChecksAccepted, setConfirmationChecksAccepted] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        // default the end date to the earliest upgrade end date
        setEndDate(earliestUpgradeEndDate);
        //console.log("setting default end date to " + earliestUpgradeEndDate);
    }, [earliestUpgradeEndDate]);

    const handleOnSave = () => {

        //console.log(`saving for ratePlanId=${ratePlanId} endDate: ${endDate}`);

        dispatch(saveChanges(ratePlanId, endDate));
    }

    const handleOnCancel = () => {
        dispatch(cancelChanges());
    }

    function handleRatePlanChange(ratePlanId) {
        setRatePlanId(ratePlanId);
    }

    function handleEndDateChange(millis, isValid) {
        setEndDate(millis);
        setEndDateIsValid(isValid);
    }

    function handleConfirmationChecksChange(accepted) {
        setConfirmationChecksAccepted(accepted);
    }

    return (
        <Dialog>

            <Title />

            {isFetching && <div>Please wait...</div>}

            {!isFetching &&
                <div>
                    <CannotUpgradeMessage
                        visible={!canPerformImmediateUpgrade && earliestUpgradeStartDate}
                        earliestUpgradeDate={earliestUpgradeStartDate}
                    />

                    {canPerformImmediateUpgrade &&
                        <>

                            <Package
                                availablePackages={availablePackages}
                                selectedRatePlanId={ratePlanId}
                                onChange={handleRatePlanChange}
                            />

                            <EndDate
                                minimumDate={earliestUpgradeEndDate}
                                onChange={handleEndDateChange}
                            />

                            <ConfirmationChecks onChange={handleConfirmationChecksChange} />

                            <SaveErrorMessage visible={saveFailed} />
                        </>
                    }

                    <Buttons>
                        <OkButton
                            visible={canPerformImmediateUpgrade}
                            onClick={handleOnSave}
                            disabled={(ratePlanId === null) || !endDateIsValid || !confirmationChecksAccepted}
                        />

                        <CancelButton
                            visible={canPerformImmediateUpgrade}
                            onClick={handleOnCancel}
                        />

                        <CloseButton
                            visible={!canPerformImmediateUpgrade}
                            onClick={handleOnCancel}
                        />

                    </Buttons>

                    <SavingMessage visible={isSaving} />
                </div>
            }
        </Dialog>

    );
}
export default ImmediateUpgrade;