import React from 'react';

import ShipsPanel from '../../ShipsPanel/ShipsPanel.js';

import {
    useSelector,
    useDispatch
} from 'react-redux';

import {
    getFilteredInstallations,
    setFilter
} from '../../../redux/shipview/installations';

import ShipCard from './ShipCard';

const ShipViewShipsPanel = () => {

    const installations = useSelector(state => getFilteredInstallations(state));

    const dispatch = useDispatch();

    const handleOnFilter = (filterValue) => {
        dispatch(setFilter(filterValue));
    }

    return (
        <ShipsPanel
            defaultFilter={''}
            onFilter={handleOnFilter}
        >
            {installations && installations.length > 0 &&
                installations.map(item =>
                    <ShipCard
                        key={`installation-id${item.id}`}
                        id={item.id}
                        name={item.name}
                        isSelected={item.selected}
                    />
                )
            }
        </ShipsPanel>
    )
}

export default ShipViewShipsPanel;