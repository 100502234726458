import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import { ReactComponent as CloseSvg } from '../../assets/Icons/Icon_Close.svg'
import { ReactComponent as ArrowDownSvg } from '../../assets/Icons/Icon_ArrowDown.svg'
import { ReactComponent as NotificationSvg } from '../../assets/Icons/Icon_Notifications.svg'
import { ReactComponent as SettingsSvg } from '../../assets/Icons/Icon_Settings.svg'
import { ReactComponent as ZoomInSvg } from '../../assets/Icons/Icon_ZoomIn.svg'
import { ReactComponent as ZoomOutSvg } from '../../assets/Icons/Icon_ZoomOut.svg'
import { ReactComponent as SearchSvg } from '../../assets/Icons/Icon_Search.svg'
import { ReactComponent as DateSvg } from '../../assets/Icons/Icon_Date.svg'
import { ReactComponent as ChartSvg } from '../../assets/Icons/Icon_Chart.svg'
import { ReactComponent as TicketSvg } from '../../assets/Icons/Icon_Ticket.svg'
import { ReactComponent as DataUseSvg } from '../../assets/Icons/Icon_DataUse.svg'
import { ReactComponent as RouterSvg } from '../../assets/Icons/Icon_Router.svg'
import { ReactComponent as SimCardSvg } from '../../assets/Icons/Icon_SimCard.svg'
import { ReactComponent as BillingHistorySvg } from '../../assets/Icons/Icon_BillingHistory.svg'
import { ReactComponent as SaveSvg } from '../../assets/Icons/Icon_Save.svg';
import { ReactComponent as DeleteSvg } from '../../assets/Icons/Icon_Delete.svg';
import { ReactComponent as EditSvg } from '../../assets/Icons/Icon_Edit.svg';
import { ReactComponent as CheckSvg } from '../../assets/Icons/Icon_Check.svg';
import { ReactComponent as CancelSvg } from '../../assets/Icons/Icon_Cancel.svg';
import { ReactComponent as PlusSvg } from '../../assets/Icons/Icon_Plus.svg';

export const IconPlus = (props) => {
    const { className, title } = props;
    return (
        <PlusSvg className={className} title={title} />
    )
}

export const IconCancel = (props) => {
    const { className, title } = props;
    return (
        <CancelSvg className={className} title={title} />
    )
}

export const IconChart = (props) => {
    const { className, title } = props;
    return (
        <ChartSvg className={className} title={title} />
    )
}

export const IconCheck = (props) => {
    const { className, title } = props;
    return (
        <CheckSvg className={className} title={title} />
    )
}

export const IconClose = (props) => {
    const { className, title } = props;
    return (
        <CloseSvg style={{ fill: "var(--color-base-mid-100)" }} className={className} title={title} />
    )
}

export const IconDelete = (props) => {
    const { className, title } = props;
    return (
        <DeleteSvg className={className} title={title} />
    );
}

export const IconEdit = (props) => {
    const { className, title } = props;
    return (
        <EditSvg className={className} title={title} />
    );
}

export const IconTicket = (props) => {
    const { className, title } = props;
    return (
        <TicketSvg className={className} title={title} />
    )
}

export const IconDataUse = (props) => {
    const { className, title } = props;
    return (
        <DataUseSvg className={className} title={title} />
    )
}

export const IconRouter = (props) => {
    const { className, title } = props;
    return (
        <RouterSvg className={className} title={title} />
    )
}

export const IconSave = (props) => {
    const { className, title } = props;
    return (
        <SaveSvg className={className} title={title} />
    );
}

export const IconSimCard = (props) => {
    const { className, title } = props;
    return (
        <SimCardSvg className={className} title={title} />
    )
}

export const IconBillingHistory = (props) => {
    const { className, title } = props;
    return (
        <BillingHistorySvg className={className} title={title} />
    )
}

export const IconDate = (props) => {
    const { className, title, disabled } = props;
    return (
        <DateSvg style={disabled ? { fill: "var(--color-disabled)" } : { fill: "var(--color-base-mid-100)" }} className={className} title={title} />
    )
}

export const IconSearch = (props) => {
    const { className, title } = props;
    return (
        <SearchSvg style={{ fill: "var(--color-base-mid-100)" }} className={className} title={title} />
    )
}

export const IconZoomIn = (props) => {
    const { className, title } = props;
    return (
        <ZoomInSvg style={{ fill: "var(--color-base-mid-100)" }} className={className} title={title} />
    )
}

export const IconZoomOut = (props) => {
    const { className, title } = props;
    return (
        <ZoomOutSvg style={{ fill: "var(--color-base-mid-100)" }} className={className} title={title} />
    )
}

export const IconSettings = (props) => {
    const { className, title } = props;
    return (
        <SettingsSvg className={className} title={title} />
    )
}

export const IconNotification = (props) => {
    const { className, title } = props;
    return (
        <NotificationSvg className={className} title={title} />
    )
}

export const IconArrowDown = (props) => {
    const { className, title } = props;
    return (
        <ArrowDownSvg style={{ fill: "var(--color-base-mid-100)" }} className={className} title={title} />
    )
}

export const Icon = ({ svg: Svg, colour, className, title }) => {
    return (
        <Svg
            style={{ fill: colour }}
            className={classnames(className)}
            title={title}
        />
    )
}

Icon.defaultProps = {
    colour: null,
    className: null,
    title: '',
}

Icon.propTypes = {
    svg: PropTypes.object.isRequired,
    colour: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
}

