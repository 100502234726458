import React from 'react'

import PropTypes from 'prop-types'

import { Dates } from '../../utils';

export const DateDisplay = (props) => {

    const { className, milliseconds, invalidMsg = "invalid date", showTime = false, title = "" } = props;

    const m = Dates.fromMillis(milliseconds);

    let d = invalidMsg;

    if (m.isValid()) {
        if (showTime) {
            d = m.toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS);
        } else {
            d = m.toString(Dates.DATE_FORMAT_SHORT);
        }
    }

    return (
        <span className={className} title={title}>
            {d}
        </span>
    );
}

DateDisplay.propTypes = {
    className: PropTypes.string,
    milliseconds: PropTypes.number,
    invalidMsg: PropTypes.string,
    showTime: PropTypes.bool,
}

DateDisplay.defaultProps = {
    className: null,
    milliseconds: 0,
}
