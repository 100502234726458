/*
 * redux functions for : store.shipview.map
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */

import axios from 'axios';
import { LOGGED_OUT } from '../user/identity';
import { INSTALLATION_SELECTED } from './installations';
import { getFromDate, getCurrentDate, DATE_RANGE_CHANGED } from './dates';

/*
 * Actions
 */
const DATA_REQUESTED = 'fleetwide/shipview/mapdata/REQUESTED';
const DATA_RECEIVED = 'fleetwide/shipview/mapdata/RECEIVED';
const DATA_FAILED = 'fleetwide/shipview/mapdata/FAILED';

/*
 * Initial State
 */
const initialState = {
    isFetching: false,
    didInvalidate: false,
    isError: false,
    installationId: null,
    installationName: null,
    routes: null,
    locations: null,
}

/*
 * Reducer Functions
 */
const handleRequested = (state) => {
    return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        isError: false,
        routes: null,
        locations: null,
    }
}

const handleReceived = (state, installationId, installationName, routes, locations) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: false,
        installationId: installationId,
        installationName: installationName,
        routes: routes,
        locations: locations,
    }
}

const handleFailed = (state) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: true,
        installationId: null,
        installationName: null,
        routes: null,
        locations: null,
    }
}

const handleInstallationSelected = (state) => {
    return {
        ...state,
        didInvalidate: true,
    }
}

const handleDateRangeChanged = (state) => {
    return {
        ...state,
        didInvalidate: true,
    }
}

const handleUserLoggedOut = () => {
    return Object.assign({}, initialState);
}

/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case DATA_REQUESTED:
            return handleRequested(state);
        case DATA_RECEIVED:
            return handleReceived(state, action.payload.installationId, action.payload.installationName, action.payload.routes, action.payload.locations);
        case DATA_FAILED:
            return handleFailed(state);
        case INSTALLATION_SELECTED:
            return handleInstallationSelected(state);
        case DATE_RANGE_CHANGED:
            return handleDateRangeChanged(state);
        case LOGGED_OUT:
            return handleUserLoggedOut();
        default:
            return state;
    }
}

/*
 * Action Creators
 */
function dataRequested() {
    return {
        type: DATA_REQUESTED
    }
}

function dataReceived(data) {
    return {
        type: DATA_RECEIVED,
        payload: {
            installationId: data.installationId,
            installationName: data.installationName,
            routes: data.routes,
            locations: data.locations,
        }
    }
}

function dataFailed() {
    return {
        type: DATA_FAILED
    }
}

/*
 * Selectors
 */
const sliceState = (store) => {
    return store.shipview.mapdata;
}

export const getRoutes = (store) => {
    const sliceData = sliceState(store);

    if (sliceData && sliceData.routes && sliceData.routes.length) {
        return sliceData.routes;
    }

    return [];
}

export const getCurrentLocation = (store) => {

    const sliceData = sliceState(store);

    if (sliceData && sliceData.locations) {
        const date = getCurrentDate(store);
        return sliceData.locations[date] ? sliceData.locations[date] : [0, 0];
    }

    return null;
}

export const getFirstLocation = (store) => {
    const sliceData = sliceState(store);

    if (sliceData && sliceData.locations) {
        const date = getFromDate(store);
        return sliceData.locations[date] ? sliceData.locations[date] : [0,0];
    }
    return null;
}

export const isFetching = (store) => {
    return sliceState(store) ? sliceState(store).isFetching : false;
}

export const isError = (store) => {
    return sliceState(store) ? sliceState(store).isError : false;
}

export const isInvalidated = (store) => {
    return sliceState(store) ? sliceState(store).didInvalidate : false;
}

/*
 * Side Effects
 */
// axios cancellation is based on the deprecated cancellable Promise
let CancelToken = axios.CancelToken;
let canceller;

const fetchData = (installationId, fromDate, toDate, interval) => {
    // Thunk middleware knows how to handle functions.
    // It passes the dispatch method as an argument to the function,
    // thus making it able to dispatch methods to itself

    return function (dispatch) {
        // update the UI state to say the API call is starting
        dispatch(dataRequested());

        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.

        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.

        // cancel the previous request if we have a valid canceller
        canceller && canceller();

        // api expects time values in seconds not milliseconds
        const url = '/api/installations/' + installationId + '/locations?startTime=' + (fromDate / 1000) + '&endTime=' + (toDate / 1000) + '&interval=' + (interval / 1000);

        return axios.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // create a canceller for this request
                // an executor function receives a cancel function as a parameter
                canceller = c;
            })
        })
            .then(response => {
                //console.log("response " + JSON.stringify(response));
                // dispatch the data to the store
                const data = {
                    installationId: response.data.installationId,
                    installationName: response.data.installationName,
                    locations: {},
                    routes: response.data.routes,
                }

                // convert the array of locations into an associative
                // array using the unix time as the key,
                // making sure to set a default if there is no lat lng value
                let lastLatLng = [0, 0];
                var first = true;
                var subtract360 = false;

                response.data.locations.forEach((item) => {

                    if (item.lat && item.lng) {
                        // case 9387 : convert lngs to make the mapbox map works correctly 
                        // across the antimeridian by effectivly offsetting the map center 
                        // by 180 degrees east or west depending on where the route starts
                        if (first) {
                            first = false;
                            subtract360 = item.lng < 0;
                            //console.log(" subtract360 " + subtract360);
                        }

                        if (subtract360) {
                            // works for routes crossing the anti-meridian east to west
                            lastLatLng = [item.lat, item.lng > 0 ? item.lng - 360 : item.lng];
                        } else {
                            // works for routes crossing the anti-meridian west to east
                            lastLatLng = [item.lat, item.lng < 0 ? item.lng + 360 : item.lng];
                        }

                        // TEST - unadulterated
                        //lastLatLng = [item.lat, item.lng];
                    }

                    // api returns time values in epoch seconds,
                    // so convert the unix seconds to milliseconds
                    //item.unixTime = item.tsL * 1000;
                    data.locations[item.tsL * 1000] = {
                        longitude: {
                            value: lastLatLng[1],
                            display: item.lng
                        },
                        latitude: {
                            value: lastLatLng[0],
                            display: item.lat
                        }
                    };
                });

                dispatch(dataReceived(data));
            })
            .catch(function (error) {
                if (axios.isCancel(error)) {
                    console.log("locations request cancelled ", error.message);
                } else {
                    //console.log("error " + JSON.stringify(error));
                    dispatch(dataFailed());
                }
            });


        // TODO : other errors should be handled using React Error Boundaries
        // see https://reactjs.org/docs/error-boundaries.html

    }
}

function shouldFetchData(state) {
    const sliceData = sliceState(state);
    if (!sliceData) {
        //console.log("should fetch invoices returning true - slice is null");
        return true;
    } else if (sliceData.isFetching) {
        //console.log("should fetch invoices returning false - data is fetching");
        return false;
    } else {
        //console.log("should fetch invoices returning " + sliceData.didInvalidate);
        return sliceData.didInvalidate;
    }
}

// a thunk
export function loadData(installationId, fromDate, toDate, interval) {
    // Note that the function also receives getState()
    // which lets you choose what to dispatch next.

    // This is useful for avoiding a network request if
    // a cached value is already available.
    return (dispatch, getState) => {
        if (shouldFetchData(getState())) {
            return dispatch(fetchData(installationId, fromDate, toDate, interval));
        } else {
            return Promise.resolve();
        }
    }
}