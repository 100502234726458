/* 
 * Ship View Selected Installation Stats reducuer
 */

import { combineReducers } from 'redux';
import { reducer as dataVolumes } from './dataVolumes';
import { reducer as downloadSpeeds } from './downloadSpeeds';
import { reducer as uploadSpeeds } from './uploadSpeeds';
import { reducer as rf } from './rf';
import { reducer as wanStatuses } from './wanStatuses';
import { reducer as beamUsage } from './beamUsage';

export const reducer = combineReducers({
    beamUsage,
    dataVolumes,
    downloadSpeeds,
    uploadSpeeds,
    rf,
    wanStatuses,
})