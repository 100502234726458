import React, { useState } from 'react'

import { Divider } from '../Divider'

import { useSelector, useDispatch } from 'react-redux';

import { getClients } from '../../redux/user/clients';

import { getNotificationEmails, loadNotificationEmails } from '../../redux/user/notificationEmails';

import axios from 'axios';

import classNames from 'classnames'

import styles from './NotificationEmails.module.css';

import { ButtonTransparent } from '../Forms';

import { IconCancel, IconDelete, IconEdit, IconCheck } from '../Icon/Icon';

//
// Persistence
//
function saveNewEmail(email, clientId) {
    const data = {
        email: email,
        clientId: clientId
    }

    const url = '/api/user/notificationemails';

    return axios.post(url, data);
}

function saveExistingEmail(id, email) {

    const data = {
        id: id,
        email: email
    }

    const url = '/api/user/notificationemails';

    return axios.put(url, data);
}

function deleteExistingEmail(id) {

    const url = '/api/user/notificationemails/' + id;

    return axios.delete(url);
}

//
// Components
//
const EditEmail = (props) => {
    const { defaultValue, errorMessage, onChange, onSave, onCancel } = props;

    return (
        <div>
            <div className={styles.emailAndButtonContainer}>
                <input className={styles.editInput} defaultValue={defaultValue} onChange={onChange} placeholder="email address" />
                <div className={styles.buttonGroup}>
                    <ButtonTransparent className={styles.iconButton} onClick={onSave} icon={<IconCheck title={"Save"} />} />
                    <ButtonTransparent className={styles.iconButton} onClick={onCancel} icon={<IconCancel title={"Cancel"} />} />
                </div>
            </div>
            <div>{errorMessage}</div>
        </div>
    );
}

const AddAnEmail = (props) => {

    const { clientId } = props;

    const [inputIsVisible, setInputIsVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const dispatch = useDispatch();

    const handleShowInput = () => {
        setEmail('');
        setErrorMessage(null);
        setInputIsVisible(true);
    }

    const handleOnChange = (event) => {
        const value = event.target.value;
        setEmail(value);
        setErrorMessage(null);
    }

    const handleOnCancel = () => {
        setInputIsVisible(false);
    }

    const handleOnSave = () => {
        saveNewEmail(email, clientId)
            .then(() => {
                dispatch(loadNotificationEmails(true));
                setInputIsVisible(false);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        setErrorMessage(error.response.data);
                    } else {
                        setErrorMessage('Sorry - an error occurred');
                    }
                    console.log('error saving email : ' + JSON.stringify(error.response));
                }
            });
    }

    return (
        <div>
            {!inputIsVisible &&
                <span className={styles.addButton} onClick={handleShowInput}>Add an email</span>
            }
            {inputIsVisible &&
                <EditEmail defaultValue={email} errorMessage={errorMessage} onChange={handleOnChange} onSave={handleOnSave} onCancel={handleOnCancel} />
            }
        </div>
    );
}

const ExistingEmail = (props) => {

    const { id, email } = props;
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(email);
    const [isDeleting, setIsDeleting] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const dispatch = useDispatch();

    const handleOnEditStart = () => {
        setEditValue(email);
        setErrorMessage(null);
        setIsEditing(true);
    }

    const handleEmailOnChange = (event) => {
        const value = event.target.value;
        setEditValue(value);
        setErrorMessage(null);
    }

    const handleOnEditCancel = () => {
        setIsEditing(false);
    }

    const handleOnEditSave = () => {
        saveExistingEmail(id, editValue)
            .then(() => {
                dispatch(loadNotificationEmails(true));
                setIsEditing(false);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        setErrorMessage(error.response.data);
                    } else {
                        setErrorMessage('Sorry - an error occurred');
                    }
                    console.log('error saving email : ' + JSON.stringify(error.response));
                }
            });
    }

    const handleOnDeleteStart = () => {
        setIsDeleting(true);
    }

    const handleOnDeleteCancel = () => {
        setIsDeleting(false);
    }

    const handleOnDeleteConfirm = () => {
        deleteExistingEmail(id)
            .then(() => {
                dispatch(loadNotificationEmails(true));
                setIsDeleting(false);
            })
            .catch((error) => {
                if (error.response) {
                    console.log('error deleting email : ' + JSON.stringify(error.response));
                }
            });
    }

    return (
        <div>
            {!isEditing &&
                <div className={styles.emailAndButtonContainer}>
                    <div className={`${classNames(styles.emailValue, isDeleting && styles.deleting)}`}>{email}</div>
                    {!isDeleting &&
                        <div className={styles.buttonGroup}>
                            <ButtonTransparent className={styles.iconButton} onClick={handleOnEditStart} icon={<IconEdit title={"Edit"} />} />
                            <ButtonTransparent className={styles.iconButton} onClick={handleOnDeleteStart} icon={<IconDelete title={"Delete"} />} />
                        </div>
                    }
                    {isDeleting &&
                        <div className={styles.buttonGroup}>
                            <ButtonTransparent className={styles.iconButton} onClick={handleOnDeleteConfirm} icon={<IconCheck title={"Delete"} />} />
                            <ButtonTransparent className={styles.iconButton} onClick={handleOnDeleteCancel} icon={<IconCancel title={"Cancel"} />} />
                        </div>
                    }
                </div>
            }
            {isEditing && !isDeleting &&
                <EditEmail defaultValue={editValue} errorMessage={errorMessage} onChange={handleEmailOnChange} onSave={handleOnEditSave} onCancel={handleOnEditCancel} />
            }
        </div>
    );
}

const Client = (props) => {

    const { id, name } = props;

    const notificationEmails = useSelector(state => getNotificationEmails(state));

    return (
        <div className={styles.clientContainer}>
            <div>
                <div className={styles.clientHeader}>{name}</div>
            </div>
            <hr />
            {
                notificationEmails.filter(value => value.clientId === id).map(item => {
                    return <ExistingEmail key={'notificationEmail-' + item.id} id={item.id} email={item.email} />
                })
            }
            <AddAnEmail clientId={id} />
        </div>
    );
}

const Clients = () => {

    const clients = useSelector(state => getClients(state));

    return (
        <div>
            {clients.map(item => {
                return <Client key={'client-' + item.id} id={item.id} name={item.name} />
            })}
        </div>
    );
}

export const NotificationEmails = () => {

    const clients = useSelector(state => getClients(state));

    if (clients && clients.length > 0) {
        return (
            <div className={styles.root}>
                <Divider>Package Change Notification Emails</Divider>
                <div className={styles.content}>
                    <div>The following email addresses will be notified when a Package Change event occurs</div>
                    <Clients />
                </div>
            </div>
        );
    }

    return null;
}
