import React from "react";

import PackageList from "../PackageList";
import { GroupContainer } from "../../../Forms";
import { userCanViewPackagePrices as getUserCanViewPackagePrices } from '../../../../redux/user/permissions';
import { useSelector } from "react-redux";

import styles from "./Package.module.css";

const Package = (props) => {

    const { availablePackages, selectedRatePlanId, onChange } = props;
    // true if the user can view package prices
    const userCanViewPackagePrices = useSelector(state => getUserCanViewPackagePrices(state));

    // had trouble setting a default selected package!

    const containerLabel = `Package${userCanViewPackagePrices ? ' (with Daily Adjustment from Base Plan)' : ''}`;

    return (
        <GroupContainer label={containerLabel}>

            <div className={styles.root}>
                <PackageList
                    packages={availablePackages}
                    selectedRatePlanId={selectedRatePlanId}
                    onChange={onChange}
                />

            </div>

        </GroupContainer>
    );
}

export default Package;