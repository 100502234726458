import React from 'react'
import { ButtonPrimary } from '../../Forms'
import styles from './TicketsHeader.module.css'

const TicketsHeader = () => {
    // for the moment - don't show the support ticket header - it may not live here anyway
    const show = false;
    if (show) {
        return (
            <div>
                <ul className={styles.list}>
                    <li>
                        <button
                            className={`${styles.button} ${styles.current}`}
                        >
                            Open
                  </button>
                    </li>
                    <li>
                        <button className={styles.button}>Closed</button>
                    </li>
                    <li>
                        <button className={styles.button}>All</button>
                    </li>
                </ul>
                <ButtonPrimary>Raise a new ticket</ButtonPrimary>
            </div>
        )
    } else {
        return null
    }
}

export default TicketsHeader