import React from 'react';

import { Container, Row, Col } from 'react-grid-system';

import { useSelector } from 'react-redux';

import {
    getData,
    isFetching,
    isError
} from '../../../redux/shipview/selectedInstallation/packages/costs';

import { userCanViewPackagePrices as getUserCanViewPackagePrices } from '../../../redux/user/permissions';

import { Divider } from '../../Divider';

import styles from './Costs.module.css';

import { formatNumber } from '../../../utils';

import ScheduledChanges from './ScheduledChanges';
import PackageChangeHistory from './PackageChangeHistory';

const Costs = () => {

    // get equipment costs
    const data = useSelector(state => getData(state));
    const dataIsLoading = useSelector(state => isFetching(state));
    const dataError = useSelector(state => isError(state));

    // true if the user can view package prices
    const userCanViewPackagePrices = useSelector(state => getUserCanViewPackagePrices(state));

    let items = [];

    if (dataIsLoading || dataError) {
        items = [];
    }
    else if (data && data.length) {
        // sort the equipment costs by ascending description
        items = data
            .sort((a, b) => {
                if (a.description < b.description) {
                    return -1;
                }
                if (a.description > b.description) {
                    return 1;
                }
                return 0;
            }).map((item, index) => {
                // display equipment cost lines
                return (
                    <Container key={`costsId${index}`} className={styles.body}>
                        <Row>
                            <Col>
                                <div className={styles.itemDescription}>{item.description}</div>
                            </Col>
                            {userCanViewPackagePrices &&
                                <Col className={styles.itemCost} md={3} lg={3} align={"right"}>${formatNumber((item.total ? (+item.total) : 0), 2)}</Col>
                            }
                        </Row>
                        {item.package &&
                            // if the equipment has a package, display the associated scheduled changes and package change history
                            <div className={styles.currentPackageContainer}>
                                <div>
                                    Package: <span className={styles.currentPackageName}>{item.package}</span>
                                </div>
                                {item.wanInterfaceId && item.sabaEquipmentId &&
                                    <ScheduledChanges
                                        wanInterfaceId={item.wanInterfaceId}
                                        sabaEquipmentId={item.sabaEquipmentId}
                                        packageCanBeChanged={item.canChangePackage}
                                        baseRatePlanName={item.package}
                                    />
                                }
                                {item.wanInterfaceId && item.sabaEquipmentId &&
                                    <PackageChangeHistory
                                        wanInterfaceId={item.wanInterfaceId}
                                        sabaEquipmentId={item.sabaEquipmentId}
                                        packageCanBeChanged={item.canChangePackage}
                                    />
                                }
                            </div>
                        }
                    </Container>
                )
            });

    }

    return (
        <>
            <Divider>Base Package</Divider>
            <div>
                {dataIsLoading &&
                    <p>Loading...</p>
                }
                {dataError &&
                    <p>Sorry - something went wrong</p>
                }
                {!dataIsLoading && !dataError && items.length === 0 &&
                    <p>No information available</p>
                }
                {items}
            </div>
        </>
    )

}

export default Costs;