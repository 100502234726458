import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import styles from './RangeSliders.module.css';

export const RangeSlider = ({ min, max, value, step, onChange, disabled }) => {

    const handleOnChange = (e) => {
        const value = +e.target.value;
        onChange(value);
    }

    return (
        <div className={`${classnames(styles.rangeslider, disabled && styles.disabled)}`}>
            <input
                type="range"
                min={min}
                max={max}
                value={value}
                step={step}
                onChange={(e) => handleOnChange(e)}
                disabled={disabled}
            ></input>
        </div>
    )
}

RangeSlider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
}