import React from 'react'

import styles from './Labels.module.css';

const Label = (props) => {
    const { className, style, children } = props;

    return (
        <div className={`${styles.root} ${style} ${className}`}>
            {children}
        </div>
    );
}

export const PrimaryLabel = (props) => {
    const { className, children } = props;

    return <Label style={styles.primary} className={className}>{children}</Label>
}

export const SuccessLabel = (props) => {
    const { className, children } = props;

    return <Label style={styles.success} className={className}>{children}</Label>
}

export const DangerLabel = (props) => {
    const { className, children } = props;

    return <Label style={styles.danger} className={className}>{children}</Label>
}