import React from 'react';

import { useSelector } from 'react-redux';

import {
    getDataForEquipment,
    isFetching,
    isError,
} from '../../../redux/shipview/selectedInstallation/packages/changeHistory';

import { userCanViewPackagePrices as getUserCanViewPackagePrices } from '../../../redux/user/permissions';

import { DateDisplay } from '../../Forms';

import { Container, Row, Col } from 'react-grid-system';

import styles from './PackageChangeHistory.module.css';

import {
    formatNumber,
} from '../../../utils';

const PackageChangeHistory = (props) => {

    const { wanInterfaceId, sabaEquipmentId, packageCanBeChanged } = props;

    const data = useSelector(state => getDataForEquipment(state, wanInterfaceId, sabaEquipmentId));
    const dataIsLoading = useSelector(state => isFetching(state));
    const loadError = useSelector(state => isError(state));
    // true if the user can view package prices
    const userCanViewPackagePrices = useSelector(state => getUserCanViewPackagePrices(state));

    if (dataIsLoading || loadError || !packageCanBeChanged) return null;

    return (
        <div className={styles.root}>
            <div className={styles.title}>Completed Package Changes</div>
            {!data.length &&
                <Container><Row><Col>No information</Col></Row></Container>
            }
            {data.length > 0 &&
                <Container>
                    <Row className={styles.tableHeader}>
                        <Col >Change Started</Col>
                        <Col>Change Ended</Col>
                        <Col>Package</Col>
                        {userCanViewPackagePrices &&
                            <Col className={styles.headerColRight}>$ Daily Adjustment</Col>
                        }
                        <Col>Created By</Col>
                    </Row>
                </Container>
            }
            {data.map((v, index) => {
                    return (
                        <Container key={`change-history-item-${index}`} className={styles.container}>
                            <Row className={styles.row}>
                                <Col><DateDisplay milliseconds={v.startDate} showTime={true} /></Col>
                                <Col><DateDisplay milliseconds={v.endDate} showTime={true} /></Col>
                                <Col className={styles.packageName} title={v.package }>{v.package}</Col>
                                {userCanViewPackagePrices &&
                                    <Col className={styles.dailyAdjustment} >{formatNumber((v.dailyAdjustment ? +v.dailyAdjustment : 0), 2)}</Col>
                                }
                                <Col className={styles.email} title={v.loginEmail}>{v.loginEmail}</Col>
                            </Row>
                        </Container>
                    )
                })}
        </div>
    )

}

export default PackageChangeHistory;