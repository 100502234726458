import React from 'react'
import classnames from 'classnames'

import { useDispatch } from 'react-redux';
import { isClosed } from '../../redux/shipview/contentPanel';

import { ButtonPrimary } from '../Forms'

import styles from './TabbedSidePanelContentHeader.module.css'

const TabbedSidePanelContentHeader = ({ title, subTitle, noPadding, children }) => {
    const dispatch = useDispatch();

    // TODO : should this be memoized using useCallback?

    const onCloseClicked = (e) => {
        dispatch(isClosed());
        e.preventDefault();
    }
    return (
        <div className={`${classnames(styles.root, noPadding && styles.noPadding)}`}>
            <div className={styles.titleContainer}>
                <h1>{title}</h1>
                {subTitle &&
                    <p className={styles.subTitle}>{subTitle}</p>
                }
            </div>
            {children}
            <ButtonPrimary onClick={(e) => onCloseClicked(e)}>Close</ButtonPrimary>
        </div>
    )
}

export default TabbedSidePanelContentHeader;