import React from 'react';

import TabbedSidePanelContentBody from '../../TabbedSidePanel/TabbedSidePanelContentBody'

import { useSelector } from 'react-redux';

import { getIsSupportTickets } from '../../../redux/shipview/contentPanel';

import { getSelectedInstallation } from '../../../redux/shipview/installations';

const SupportTickets = () => {

    const show = useSelector(state => getIsSupportTickets(state));

    const selectedInstallation = useSelector(state => getSelectedInstallation(state));

    if (show && selectedInstallation) {
        return (
            <TabbedSidePanelContentBody noPadding={false}>
                <div>
                    <p>Coming in a later release...</p>
                    <p>A searchable list of Support Tickets related to the selected Ship.</p>
                    <p>The ability to create a new Support Ticket for the selected Ship.</p>
                </div>
            </TabbedSidePanelContentBody>
        )
    }

    return null;
}

export default SupportTickets;