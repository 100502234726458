/*
 * redux functions for : store.shipview.mapzoom
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */

import { Dates } from '../../utils';

/*
 * Actions
 */
const ZOOM_IN = 'fleetwide/shipview/mapzoom/ZOOM_IN';
const ZOOM_OUT = 'fleetwide/shipview/mapzoom/ZOOM_OUT';


/*
 * Initial State
 */
const initialState = {
    zoomIn: null,
    zoomOut: null,
}

/*
 * Reducer Functions
 */

const handleZoomIn = (state, value) => {
    return {
        ...state,
        zoomIn: value,
    }
}

const handleZoomOut = (state, value) => {
    return {
        ...state,
        zoomOut: value
    }
}

/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ZOOM_IN:
            return handleZoomIn(state, action.payload.value);
        case ZOOM_OUT:
            return handleZoomOut(state, action.payload.value);
        default:
            return state;
    }
}

/*
 * Action Creators
 */
function zoomIn(value) {
    return {
        type: ZOOM_IN,
        payload: {
            value: value,
        }
    }
}

function zoomOut(value) {
    return {
        type: ZOOM_OUT,
        payload: {
            value: value,
        }
    }
}

/*
 * Selectors
 */
const sliceState = (store) => {
    return store.shipview.mapzoom;
}

export const getZoomIn = (store) => {
    const sliceData = sliceState(store);

    return sliceData ? sliceData.zoomIn : null;
}

export const getZoomOut = (store) => {
    const sliceData = sliceState(store);

    return sliceData ? sliceData.zoomOut : null;    
}

// a thunk
export function setZoomIn() {
    return (dispatch) => {
        return dispatch(zoomIn(Dates.now().millis()));
    }
}

// a thunk
export function setZoomOut() {
    return (dispatch) => {
        return dispatch(zoomOut(Dates.now().millis()));
    }
}