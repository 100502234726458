import React, {
    useState,
    useCallback
} from 'react'

import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './ShipCard.module.css'

import { IconArrowDown } from '../../Icon/Icon';

import { useDispatch, useSelector } from 'react-redux';
import {
    installationSelected,
    getLatestLocation,
    getLatestStatusDate,
    getLatestWanConnection,
} from '../../../redux/shipview/installations';

import LocationDisplay from '../../LocationDisplay/LocationDisplay';

const LatestLocation = (props) => {

    const { location } = props;

    //console.log("shipcard location " + JSON.stringify(location));

    return <LocationDisplay lat={location ? location.lat : null} lng={location ? location.lng : null}/>
}

const ShipCard = ({ id, name, isSelected }) => {

    const [isOpen, setOpen] = useState(false)

    const latestLocation = useSelector(state => getLatestLocation(state, id));
    const latestWanConnection = useSelector(state => getLatestWanConnection(state, id));
    const latestStatusDate = useSelector(state => getLatestStatusDate(state, id));

    const dispatch = useDispatch();

    /* memoize the call back for good measure - may not be required */
    const handleOnSelected = useCallback(
        () => {
            dispatch(installationSelected(id));
        },
        [dispatch, id]
    )

    const toggleDrawerOpen = () => {
        setOpen(!isOpen)
    }

    return (
        <div className={styles.shipcard}>
            <div
                className={`${classnames(styles.top, isSelected && styles.checked)}`}
                role="button"
                tabIndex="0"
            >
                <label className={styles.radiobutton} onClick={handleOnSelected}>
                    <span className={`${classnames(styles.checkmark, isSelected && styles.checked)}`}></span>
                    <span className={styles.labeltext}>
                        {name}
                    </span>
                </label>

                <span
                    className={`${classnames(styles.button, !isOpen && styles.rotate)}`}
                    onClick={toggleDrawerOpen}
                >
                    <IconArrowDown title={"Details"} />
                </span>
                <span className={`${classnames(styles.message, isOpen && styles.hidden)}`}>
                    {latestWanConnection && latestWanConnection}
                    {!latestWanConnection && "Unknown"}
                </span>
            </div>
            <div className={`${classnames(styles.bottom, isOpen ? styles.open : styles.closed)}`}>
                <table className={styles.shipcardtable}>
                    <tbody>
                        <tr>
                            <th>Status Date</th>
                            <td>{latestStatusDate }</td>
                        </tr>
                        <tr>
                            <th>WAN Connection</th>
                            <td>
                                {latestWanConnection && latestWanConnection}
                                {!latestWanConnection && "Unknown"}
                            </td>
                        </tr>
                        <tr>
                            <th>Location</th>
                            <td>
                                {latestLocation && <LatestLocation location={latestLocation} />}
                                {!latestLocation && "Unknown"}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

ShipCard.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
}


export default ShipCard
