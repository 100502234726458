import React from 'react';
import SidePanel from '../../SidePanel/SidePanel';

import styles from './ShipViewSidePanel.module.css';

const ShipViewSidePanel = ({ children }) => {
    return (
        <SidePanel className={styles.root}>
            {children}
        </SidePanel>
    )
}

export default ShipViewSidePanel;