/*
 * redux functions for : store.shipview.selectedInstallation.stats.beamUsage
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */

import axios from 'axios';
import { LOGGED_OUT } from '../../../user/identity';
import { INSTALLATION_SELECTED } from '../../installations';
import { getCurrentDate, DATE_RANGE_CHANGED } from '../../dates';

/*
 * Actions
 */
const DATA_REQUESTED = 'fleetwide/shipview/selectedInstallation/stats/beamusage/REQUESTED';
const DATA_RECEIVED = 'fleetwide/shipview/selectedInstallation/stats/beamusage/RECEIVED';
const DATA_FAILED = 'fleetwide/shipview/selectedInstallation/stats/beamusage/FAILED';

/*
 * Initial State
 */
const initialState = {
    isFetching: false,
    didInvalidate: false,
    isError: false,
    data: [],
    keyed: {},
}

/*
 * Reducer Functions
 */
const handleRequested = (state) => {
    return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        isError: false,
        data: [],
        keyed: {},
    }
}

const handleReceived = (state, data, keyed) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: false,
        data: data ? data : [],
        keyed: keyed,
    }
}

const handleFailed = (state) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: true,
        data: [],
        keyed: {},
    }
}

const handleInstallationSelected = (state) => {
    return {
        ...state,
        didInvalidate: true,
    }
}

const handleDateRangeChanged = (state) => {
    return {
        ...state,
        didInvalidate: true,
    }
}
const handleUserLoggedOut = () => {
    return Object.assign({}, initialState);
}

/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case DATA_REQUESTED:
            return handleRequested(state);
        case DATA_RECEIVED:
            return handleReceived(state, action.payload.data, action.payload.keyed);
        case DATA_FAILED:
            return handleFailed(state);
        case INSTALLATION_SELECTED:
            return handleInstallationSelected(state);
        case DATE_RANGE_CHANGED:
            return handleDateRangeChanged(state);
        case LOGGED_OUT:
            return handleUserLoggedOut();
        default:
            return state;
    }
}

/*
 * Action Creators
 */
function dataRequested() {
    return {
        type: DATA_REQUESTED
    }
}

function dataReceived(data, keyed) {
    return {
        type: DATA_RECEIVED,
        payload: {
            data: data,
            keyed: keyed,
        }
    }
}

function dataFailed() {
    return {
        type: DATA_FAILED
    }
}

/*
 * Selectors
 */
const sliceState = (store) => {
    return store.shipview.selectedInstallation.stats.beamUsage;
}

export const getData = (store) => {
    return sliceState(store) ? sliceState(store).data : [];
}

export const getCurrentValue = (store) => {
    const sliceData = sliceState(store);

    if (sliceData && sliceData.keyed) {
        const date = getCurrentDate(store);

        return sliceData.keyed[date] ? sliceData.keyed[date].beam : null;
    }
}

export const isFetching = (store) => {
    return sliceState(store) ? sliceState(store).isFetching : false;
}

export const isError = (store) => {
    return sliceState(store) ? sliceState(store).isError : false;
}

export const isInvalidated = (store) => {
    return sliceState(store) ? sliceState(store).didInvalidate : false;
}


/*
 * Side Effects
 */
// axios cancellation is based on the deprecated cancellable Promise
let CancelToken = axios.CancelToken;
let canceller;

/**
 * Retrieves data from the data store.
 * @param {number} installationId - ID of the installation for which data is to be retrieved
 * @param {number} fromDate - Lower date range value (inclusive) as a UNIX epoch timestamp in seconds
 * @param {number} toDate - Upper date range value (inclusive) as a UNIX epoch timestamp in seconds
 * @param {number} interval - Date Range interval in seconds
 */
const fetchData = (installationId, fromDate, toDate, interval) => {
    // Thunk middleware knows how to handle functions.
    // It passes the dispatch method as an argument to the function,
    // thus making it able to dispatch methods to itself

    return function (dispatch) {
        // update the UI state to say the API call is starting
        dispatch(dataRequested());

        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.

        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.

        // cancel the previous request if we have a valid canceller
        canceller && canceller();

        // api expects time values in seconds not milliseconds
        const url = '/api/installations/' + installationId + '/beamusage?startTimeUnix=' + (fromDate / 1000) + '&endTimeUnix=' + (toDate / 1000)+ "&interval=" + (interval / 1000);

        return axios.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // create a canceller for this request
                // an executor function receives a cancel function as a parameter
                canceller = c;
            })
        })
            .then(response => {
                //console.log("response " + JSON.stringify(response));
                // dispatch the data to the store
                const data = response.data.map(datum => {
                    return {
                        // need to convert the timestamps from seconds to milliseconds
                        tsl: datum.tsL * 1000,
                        tsu: datum.tsU * 1000,
                        beam: datum.beamName,
                    }
                })

                // create an associative array keyed by lower timestamp for each data value
                let keyed = {};

                data.forEach((value) => {
                    keyed[value.tsl] = {
                        beam: value.beam,
                    };
                });

                dispatch(dataReceived(data, keyed));
            })
            .catch(function (error) {
                if (axios.isCancel(error)) {
                    //console.log("beam usage request cancelled ", error.message);
                } else {
                    //console.log("error " + JSON.stringify(error));
                    dispatch(dataFailed());
                }
            });


        // TODO : other errors should be handled using React Error Boundaries
        // see https://reactjs.org/docs/error-boundaries.html

    }
}

function shouldFetchData(state) {
    const sliceData = sliceState(state);
    if (!sliceData) {
        return true;
    } else if (sliceData.isFetching) {
        return false;
    } else {
        return sliceData.didInvalidate;
    }
}

/**
 * A react thunk which initiates the loading of Beam Usage data from the data store.
 * @param {number} installationId
 * @param {number} fromDate
 * @param {number} toDate
 * @param {number} interval
 */
export function loadData(installationId, fromDate, toDate, interval) {
    // Note that the function also receives getState()
    // which lets you choose what to dispatch next.

    // This is useful for avoiding a network request if
    // a cached value is already available.
    return (dispatch, getState) => {
        if (shouldFetchData(getState())) {
            return dispatch(fetchData(installationId, fromDate, toDate, interval));
        } else {
            return Promise.resolve();
        }
    }
}