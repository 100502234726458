import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash';

import styles from './ShipsPanel.module.css';

import { IconSearch} from '../Icon/Icon';

// for debounce and lodash, see
// see https://www.freecodecamp.org/news/debounce-and-throttle-in-react-with-hooks/
// with the addition of changing useCallback to useMemo as per:
// https://github.com/facebook/react/issues/19240

const ShipsPanel = ({ children, defaultFilter = '', onFilter }) => {

    const [filter, setFilter] = useState(defaultFilter);

    const applySearch = () => {
        onFilter(filter);
    }

    const debounceFilterValue = useMemo(
        () => _.debounce(nextValue => onFilter(nextValue), 300),
        [onFilter]
    )

    const handleChange = event => {
        const { value: nextValue } = event.target;
        setFilter(nextValue);
        debounceFilterValue(nextValue)
    }

    return (
        <div className={styles.shipsPanel}>
            <header className={styles.shipsPanel__header}>
                <div className={styles.shipSearch}>
                    <input
                        className={styles.shipSearch__field}
                        type="search"
                        placeholder="Search your fleet"
                        value={filter}
                        onChange={handleChange}
                    ></input>
                    <button
                        className={styles.shipSearch__button}
                        type="button"
                        onClick={applySearch}>
                        <IconSearch title={"Search"}/>
                    </button>
                </div>
            </header>
            <main className={styles.shipsPanel__body}>
                {children}
            </main>
        </div>
    )
}

ShipsPanel.propTypes = {
    children: PropTypes.node,
    onFilter: PropTypes.func.isRequired,
}

export default ShipsPanel
