/*
 * redux functions for : store.shipview.overlays
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */

/*
 * Actions
 */
const TOGGLE_BEAMS = 'fleetwide/shipview/overlays/TOGGLE_BEAMS';

/*
 * Initial State
 */
const initialState = {
    showBeams: false,
}

/*
 * Reducer Functions
 */
const handleToggleBeams = (state) => {
    return {
        ...state,
        showBeams: !state.showBeams
    }
}

/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case TOGGLE_BEAMS:
            return handleToggleBeams(state);
        default:
            return state;
    }
}

/*
 * Action Creators
 */
export function toggleBeams() {
    return {
        type: TOGGLE_BEAMS
    }
}

/*
 * Selectors
 */
const sliceState = (store) => {
    return store.shipview.overlays;
}

export const getShowBeams = (store) => {
    const sliceData = sliceState(store);

    return sliceData ? sliceData.showBeams : false;
}

