import React from 'react';

import styles from './MapType.module.css';

export const SELECTED_TODAY = "today";
export const SELECTED_DATED = "dated";

export const MapType = (props) => {

    const { selected, onChange } = props;

    const TITLE_TODAY = "Today";
    const TITLE_DATED = "Custom Date Range";

    const handleOnChange = (value) => {
        //console.log("map type on change " + value);
        onChange(value);
    }

    return (
        <div className={styles.container}>
            <label title={TITLE_DATED}>
                <input className={styles.button} type="radio" name="mapType" id="mapTypeDated" value={SELECTED_DATED} checked={selected === SELECTED_DATED} onChange={(event) => handleOnChange(event.target.value) }/>
                <div className={styles.svgContainer}>
                    <svg className={styles.svg} viewBox="0 0 24 24" title={TITLE_DATED}>
                        <path d="M12,1C8.14,1 5,4.14 5,8A7,7 0 0,0 12,15C15.86,15 19,11.87 19,8C19,4.14 15.86,1 12,1M12,3.15C14.67,3.15 16.85,5.32 16.85,8C16.85,10.68 14.67,12.85 12,12.85A4.85,4.85 0 0,1 7.15,8A4.85,4.85 0 0,1 12,3.15M11,5V8.69L14.19,10.53L14.94,9.23L12.5,7.82V5M4,16V24H6V21H18V24L22,20L18,16V19H6V16" />
                    </svg>
                </div>
            </label>
            <label title={TITLE_TODAY}>
                <input className={styles.button} type="radio" name="mapType" id="mapTypeToday" value={SELECTED_TODAY} checked={selected === SELECTED_TODAY} onChange={(event) => handleOnChange(event.target.value) }/>
                <div className={styles.svgContainer}>
                    <svg className={styles.svg} viewBox="0 0 24 24" title={TITLE_TODAY}>
                        <path d="M2.21,0.79L0.79,2.21L4.8,6.21L3,8H8V3L6.21,4.8M12,8C8.14,8 5,11.13 5,15A7,7 0 0,0 12,22C15.86,22 19,18.87 19,15A7,7 0 0,0 12,8M12,10.15C14.67,10.15 16.85,12.32 16.85,15A4.85,4.85 0 0,1 12,19.85C9.32,19.85 7.15,17.68 7.15,15A4.85,4.85 0 0,1 12,10.15M11,12V15.69L14.19,17.53L14.94,16.23L12.5,14.82V12" />
                    </svg>
                </div>
            </label>
        </div>
    );
}