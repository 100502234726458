/* 
 * redux functions for : store.shipview.dates
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */

import axios from 'axios';
import { dateRangeCalculator } from '../../utils';
import { LOGGED_OUT } from '../user/identity';

/*
 * Actions
 */
const DEFAULTS_REQUESTED = 'fleetwide/shipview/dates/DEFAULTS_REQUESTED';
const DEFAULTS_RECEIVED = 'fleetwide/shipview/dates/DEFAULTS_RECEIVED';
const DEFAULTS_FAILED = 'fleetwide/shipview/dates/DEFAULTS_FAILED';
const DIALOG_CLOSED = 'fleetwide/shipview/dates/DIALOG_CLOSED';
const DIALOG_OPENED = 'fleetwide/shipview/dates/DIALOG_OPENED';
export const DATE_RANGE_CHANGED = 'fleetwide/shipview/dates/DATE_RANGE_CHANGED';
export const CURRENT_DATE_CHANGED = 'fleetwide/shipview/dates/CURRENT_DATE_CHANGED';

/*
 * Initial State
 */

const initialState = {
    dialogIsOpen: false,
    isFetching: false,
    didInvalidate: true,
    isError: false,
    fromDate: 0,
    toDate: 0,
    interval: 0,
    earliestDate: 0,
    currentDate: 0
};

/*
 * Reducer Functions
 */
const handleDefaultsRequested = (state) => {
    return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        isError: false
    }
}

const handleDefaultsReceived = (state, data) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: false,
        fromDate: data.fromDate,
        toDate: data.toDate,
        interval: data.interval,
        earliestDate: data.earliestDate,
        currentDate: data.fromDate
    }
}

const handleDefaultsFailed = (state) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: true
    }
}

const handleCurrentDateChanged = (state, value) => {
    return {
        ...state,
        currentDate: value
    }
}

const handleDateRangeChanged = (state, fromDate, toDate, interval, currentDate) => {
    return {
        ...state,
        fromDate: fromDate,
        toDate: toDate,
        interval: interval,
        currentDate: currentDate
    }
}

const handleDialogClosed = (state) => {
    return {
        ...state,
        dialogIsOpen: false,
    }
}

const handleDialogOpened = (state) => {
    return {
        ...state,
        dialogIsOpen: true,
    }
}

const handleUserLoggedOut = () => {
    return initialState;
}

/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case DEFAULTS_REQUESTED:
            return handleDefaultsRequested(state);
        case DEFAULTS_RECEIVED:
            return handleDefaultsReceived(state, action.payload);
        case DEFAULTS_FAILED:
            return handleDefaultsFailed(state);
        case CURRENT_DATE_CHANGED:
            return handleCurrentDateChanged(state, action.payload);
        case DATE_RANGE_CHANGED:
            return handleDateRangeChanged(state, action.payload.fromDate, action.payload.toDate, action.payload.interval, action.payload.currentDate);
        case DIALOG_CLOSED:
            return handleDialogClosed(state);
        case DIALOG_OPENED:
            return handleDialogOpened(state);
        case LOGGED_OUT:
            return handleUserLoggedOut();
        default:
            return state;
    }
}

/* 
 * Action Creators
 */
export function defaultsRequested() {
    return {
        type: DEFAULTS_REQUESTED
    }
}

export function defaultsReceived(data) {
    return {
        type: DEFAULTS_RECEIVED,
        payload: data
    }
}

export function defaultsFailed() {
    return {
        type: DEFAULTS_FAILED
    }
}

export function currentDateChanged(value) {
    return {
        type: CURRENT_DATE_CHANGED,
        payload: value
    }
}

function actionDateRangeChanged(fromDate, toDate, interval, currentDate) {
    return {
        type: DATE_RANGE_CHANGED,
        payload: {
            fromDate: fromDate,
            toDate: toDate,
            interval: interval,
            currentDate: currentDate,
        }
    }
}

export function closeDialog() {
    return {
        type: DIALOG_CLOSED,
    }
}

export function openDialog() {
    return {
        type: DIALOG_OPENED,
    }
}

/*
 * Selectors
 */
const sliceState = (store) => {
    return store.shipview.dates;
}

export const getFromDate = (store) => {
    return sliceState(store) ? sliceState(store).fromDate : initialState.fromDate;
}

export const getToDate = (store) => {
    return sliceState(store) ? sliceState(store).toDate : initialState.toDate;
}

export const getInterval = (store) => {
    return sliceState(store) ? sliceState(store).interval : initialState.interval;
}

export const getCurrentDate = (store) => {
    return sliceState(store) ? sliceState(store).currentDate : initialState.currentDate;
}

export const getEarliestDate = (store) => {
    return sliceState(store) ? sliceState(store).earliestDate : initialState.earliestDate;
}

export const getDialogIsOpen = (store) => {
    return sliceState(store) ? sliceState(store).dialogIsOpen : initialState.dialogIsOpen;
}

/*
 * Side Effects
 */
const fetchDefaultDates = () => {
    // Thunk middleware knows how to handle functions.
    // It passes the dispatch method as an argument to the function,
    // thus making it able to dispatch methods to itself

    return function (dispatch) {
        // update the UI state to say the API call is starting
        dispatch(defaultsRequested());

        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.

        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.

        return axios.get('/api/defaultdates',)
            .then(response => {
                const data = response.data;

                const dates = dateRangeCalculator(data.fromDate, data.toDate);

                data.interval = dates.interval;
                data.toDate = dates.to;

                // dispatch the data to the store
                dispatch(defaultsReceived(data));
            })
            .catch(function (error) {
                //console.log("error " + JSON.stringify(error));
                dispatch(defaultsFailed());
            });

        // TODO : other errors should be handled using React Error Boundaries
        // see https://reactjs.org/docs/error-boundaries.html

    }
}

export function shouldFetchDefaultDates(state) {
    const slice = sliceState(state);
    if (!slice) {
        return true;
    } else if (slice.isFetching) {
        return false;
    } else {
        return slice.didInvalidate;
    }
}

// a thunk
export function loadDefaultDates() {
    // Note that the function also receives getState()
    // which lets you choose what to dispatch next.

    // This is useful for avoiding a network request if
    // a cached value is already available.
    return (dispatch, getState) => {
        if (shouldFetchDefaultDates(getState())) {
            // Dispatch a thunk from thunk!
            return dispatch(fetchDefaultDates());
        } else {
            // Let the calling code know there's nothing to wait for.
            return Promise.resolve();
        }
    }
}

function canSetDates(state) {
    const slice = sliceState(state);
    if (!slice) {
        return false;
    } else if (slice.isFetching) {
        return false;
    } else {
        return true;
    }
}

export function changeDateRange(fromDate, toDate, interval, currentDate) {
    if (isNaN(fromDate)) {
        throw Error("fromDate must be a number");
    }
    if (isNaN(toDate)) {
        throw Error("toDate must be a number");
    }
    if (fromDate > toDate) {
        throw Error("fromDate must be earlier than toDate");
    }
    
    return (dispatch, getState) => {
        if (canSetDates(getState())) {

            dispatch(actionDateRangeChanged(fromDate, toDate, interval, currentDate));

            //dispatch(currentDateChanged(fromDate));

            return dispatch(closeDialog());
        } else {
            return Promise.resolve();
        }
    }
}