import React from 'react'

import styles from './Forms.module.css';

export const Form = ({ children }) => {

    return (
        <form className={styles.form}>
            {children}
        </form>
    )
}

export const FormFooter = ({ children }) => {

    return (
        <footer className={styles.formFooter}>
            {children}
        </footer>
    )
}
