import React from 'react';

import {
    useSelector
} from 'react-redux';

import {
    isFetching as getIsLoading
} from '../../../redux/shipview/mapdata';

import styles from './LoadingIndicator.module.css';

export const LoadingIndicator = () => {

    const isLoading = useSelector(state => getIsLoading(state));

    if (isLoading) {
        return (
            <div className={styles.root}>
                <div className={styles.text}>Loading...</div>
            </div>
        );
    }

    return null;
}
