/* 
 * Ship View reducuer
 */
import { combineReducers } from 'redux';
import { reducer as dates } from './dates';
import { reducer as installations } from './installations';
import { reducer as contentPanel } from './contentPanel';
import { selectedInstallation } from './selectedInstallation';
import { reducer as mapdata } from './mapdata';
import { reducer as mapweather } from './mapweather';
import { reducer as mapzoom } from './mapzoom';
import { reducer as overlays } from './overlays';


export const shipview = combineReducers({
    dates,
    installations,
    contentPanel,
    selectedInstallation,
    mapdata,
    mapweather,
    mapzoom,
    overlays,
})