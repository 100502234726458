/*
 * redux functions for : store.shipview.installations
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */
import axios from 'axios';
import _ from 'lodash';
import { LOGGED_IN } from '../user/identity';
import { Dates } from '../../utils';

/*
 * Actions
 */
const INSTALLATIONS_REQUESTED = 'fleetwide/shipview/installations/REQUESTED';
const INSTALLATIONS_RECEIVED = 'fleetwide/shipview/installations/RECEIVED';
const INSTALLATIONS_FAILED = 'fleetwide/shipview/installations/FAILED';
export const INSTALLATION_SELECTED = 'fleetwide/shipview/installations/INSTALLATION_SELECTED';
const SET_FILTER = 'fleetwide/shipview/installations/SET_FILTER';
const LATEST_STATUSES_RECEIVED = 'fleetwide/shipview/installations/LATEST_STATUSES_RECEIVED';

/*
 * Initial State
 */
const initialState = {
    isFetching: false,
    didInvalidate: false,
    isError: false,
    filter: '',
    items: [],
}

/*
 * Reducer Functions
 */
const handleRequested = (state) => {
    return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        isError: false,
    }
}

const handleReceived = (state, payload) => {
    const { items } = payload;
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: false,
        items: items.map(item => _.merge(item, {
            selected: false,
            latestStatusDate: "Unknown",
            latestLat: null,
            latestLng: null,
            latestWanConnection: "Unknown"
        })),
    }
}

const handleFailed = (state) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: true,
    }
}

const handleInstallationSelected = (state, payload) => {
    const { id } = payload;
    return {
        ...state,
        items: state.items.map(item => {
            let obj = { ...item };
            obj.selected = item.id === id;
            return obj;
        })
    }
}

const handleSetFilter = (state, payload) => {
    const { filter } = payload;
    if (filter === state.filter) return state;
    return {
        ...state,
        filter: filter
    }
}

const handleUserLoggedIn = (state) => {
    return {
        ...state,
        didInvalidate: true,
        filter: '',
        items: [],
    }
}

const handleLatestStatusesReceived = (state, payload) => {
    const { statuses } = payload;
    return {
        ...state,
        items: state.items.map(item => {
            let obj = { ...item };
            const status = statuses.filter(status => { return item.id === status.installationId });
            if (status && status.length) {
                obj.latestLat = status[0].lat;
                obj.latestLng = status[0].lng;
                obj.latestStatusDate = Dates.now().toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS);
                obj.latestWanConnection = status[0].wanConnection;
            }
            return obj;
        })
    }

}
/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case INSTALLATIONS_REQUESTED:
            return handleRequested(state);
        case INSTALLATIONS_RECEIVED:
            return handleReceived(state, action.payload);
        case INSTALLATIONS_FAILED:
            return handleFailed(state);
        case INSTALLATION_SELECTED:
            return handleInstallationSelected(state, action.payload);
        case SET_FILTER:
            return handleSetFilter(state, action.payload);
        case LOGGED_IN:
            return handleUserLoggedIn(state);
        case LATEST_STATUSES_RECEIVED:
            return handleLatestStatusesReceived(state, action.payload);
        default:
            return state;
    }
}

/*
 * Action Creators
 */
function installationsRequested() {
    return {
        type: INSTALLATIONS_REQUESTED
    }
}

function installationsReceived(items) {
    return {
        type: INSTALLATIONS_RECEIVED,
        payload: {
            items: items,
        }
    }
}

function installationsFailed() {
    return {
        type: INSTALLATIONS_FAILED
    }
}

function latestStatusesReceived(statuses) {
    return {
        type: LATEST_STATUSES_RECEIVED,
        payload: {
            statuses: statuses
        }
    }
}

export function installationSelected(id) {
    return (dispatch) => {
        dispatch({ type: INSTALLATION_SELECTED, payload: { id } });
    }
}

export function setFilter(filter) {
    return {
        type: SET_FILTER,
        payload: {
            filter
        }
    }
}


/*
 * Selectors
 */
const sliceState = (store) => {
    return store.shipview.installations;
}

export const getInstallations = (store) => {
    return sliceState(store) ? sliceState(store).items : [];
}

export const getFilteredInstallations = (store) => {
    const allInstallations = getInstallations(store);
    const installationFilter = getFilter(store).toLowerCase();
    return allInstallations.filter(item => item.name.toLowerCase().includes(installationFilter));
}

export const getSelectedInstallation = (store) => {
    const items = getInstallations(store).filter(item => { return item.selected });
    return items && items.length ? items[0] : null;
}

export const getInstallation = (store, id) => {
    const items = getInstallations(store).filter(item => { return item.id === id });
    return items && items.length ? items[0] : null;
}

export const getFilter = (store) => {
    return sliceState(store) ? sliceState(store).filter : initialState.filter;
}

export const getLatestLocation = (store, id) => {
    const item = getInstallation(store, id);
    return item ? item.latestLat && item.latestLng ? {
        lat: item.latestLat,
        lng: item.latestLng
    }: null : null;
}

export const getLatestWanConnection = (store, id) => {
    const item = getInstallation(store, id);
    return item ? item.latestWanConnection : null;
}

export const getLatestStatusDate = (store, id) => {
    const item = getInstallation(store, id);
    return item ? item.latestStatusDate : null;
}

/*
 * Side Effects
 */

const fetchInstallations = () => {
    // Thunk middleware knows how to handle functions.
    // It passes the dispatch method as an argument to the function,
    // thus making it able to dispatch methods to itself

    return function (dispatch) {
        // update the UI state to say the API call is starting
        dispatch(installationsRequested());

        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.

        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.

        return axios.get('/api/shipviewinstallations',)
            .then(response => {
                //console.log("response " + JSON.stringify(response));
                dispatch(installationsReceived(response.data));
            })
            .catch(function (error) {
                //console.log("error " + JSON.stringify(error));
                dispatch(installationsFailed());
            });

        // TODO : other errors should be handled using React Error Boundaries
        // see https://reactjs.org/docs/error-boundaries.html

    }
}

function shouldFetchInstallations(state) {
    const installations = state.shipview.installations;
    if (!installations) {
        return true;
    } else if (installations.isFetching) {
        return false;
    } else {
        return installations.didInvalidate;
    }
}

// a thunk
export function loadInstallations() {
    // Note that the function also receives getState()
    // which lets you choose what to dispatch next.

    // This is useful for avoiding a network request if
    // a cached value is already available.
    return (dispatch, getState) => {
        if (shouldFetchInstallations(getState())) {
            // Dispatch a thunk from thunk!
            return dispatch(fetchInstallations())
        } else {
            // Let the calling code know there's nothing to wait for.
            return Promise.resolve()
        }
    }
}

const getLatestStatuses = (state) => {    
    return function (dispatch) {

        return axios.get('/api/shipviewinstallations/lateststatuses')
            .then(response => {
                //console.log("response " + JSON.stringify(response.data));
                dispatch(latestStatusesReceived(response.data));
            })
            .catch(function (error) {
                console.log("error " + JSON.stringify(error));
            })
    }
}

// a thunk
export function loadLatestStatuses() {
    return (dispatch, getState) => {
        return dispatch(getLatestStatuses(getState()));
    }
}