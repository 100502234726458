import React from 'react'
import PropTypes from 'prop-types'

import styles from './Divider.module.css'

export const Divider = ({ children }) => {
  return (
    <div className={styles.divider}>
      <h2 className={styles.title}>{children}</h2>
      <span className={styles.hr}></span>
    </div>
  )
}

Divider.propTypes = {
  children: PropTypes.node.isRequired,
}