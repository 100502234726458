import React from "react";

import { ButtonOk } from '../../../Forms/Buttons';

const OkButton = (props) => {

    const { visible, onClick, disabled } = props;

    if (!visible) return null;

    return (
        <ButtonOk onClick={onClick} disabled={disabled}>Ok</ButtonOk>
    );
}

export default OkButton;