/* 
 * Ship View Selected Installation Packages reducer
 */
import { combineReducers } from 'redux';
import { reducer as costs } from './costs';
import { reducer as scheduledChanges } from './scheduledChanges';
import { reducer as changeHistory } from './changeHistory';
import { reducer as packageChanges } from './packageChanges';
import { reducer as immediateUpgrade } from "./immediateUpgrade";

export const reducer = combineReducers({
    costs,
    scheduledChanges,
    changeHistory,
    packageChanges,
    immediateUpgrade,
})