import React from 'react';

import {
    ButtonTransparent,
} from '../../Forms';

import {
    IconZoomIn,
    IconZoomOut,
} from '../../Icon/Icon';

import styles from './Zoom.module.css';

export const Zoom = (props) => {

    const { onZoomIn, onZoomOut } = props;

    return (
        <div className={styles.container}>
            <ButtonTransparent
                className={styles.button}
                icon={<IconZoomIn title={"Zoom In"} />}
                onClick={onZoomIn}
            />
            <ButtonTransparent
                className={styles.button}
                icon={<IconZoomOut title={"Zoom Out"} />}
                onClick={onZoomOut}
            />
        </div>
    );
}