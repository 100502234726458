import React from 'react'
import styles from './NavBar.module.css';

const NavBar = ({ children }) => {

    return (
        <header className={styles.root}>
            {children}
        </header>
    )
}

export default NavBar
