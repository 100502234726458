/*
 * redux functions for : store.shipview.mapweather
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */

/*
 * Actions
 */
const SHOW_CLOUDS = "fleetwide/shipview/mapweather/SHOW_CLOUDS";
const SHOW_RAIN = "fleetwide/shipview/mapweather/SHOW_RAIN";
const SHOW_WAVES = "fleetwide/shipview/mapweather/SHOW_WAVES";
const HIDE_ALL = "fleetwide/shipview/mapweather/HIDE_ALL";

/*
 * Initial State
 */
const initialState = {
    showClouds: false,
    showRain: false,
    showWaves: false,
}

/*
 * Reducer Functions
 */
const handleShowClouds = (state, value) => {
    return {
        ...state,
        showClouds: value
    }
}

const handleShowRain = (state, value) => {
    return {
        ...state,
        showRain: value
    }
}

const handleShowWaves = (state, value) => {
    return {
        ...state,
        showWaves: value
    }
}

const handleHideAll = (state) => {
    return {
        ...state,
        showRain: false,
        showClouds: false,
        showWaves: false,
    }
}

/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SHOW_CLOUDS:
            return handleShowClouds(state, action.payload.value);
        case SHOW_RAIN:
            return handleShowRain(state, action.payload.value);
        case SHOW_WAVES:
            return handleShowWaves(state, action.payload.value);
        case HIDE_ALL:
            return handleHideAll(state);
        default:
            return state;
    }
}

/*
 * Action Creators
 */
function setAction(type, value) {
    return {
        type: type,
        payload: {
            value: value
        }
    }
}


function setHideAll() {
    return {
        type: HIDE_ALL
    }
}

/*
 * Selectors
 */
const sliceState = (store) => {
    return store.shipview.mapweather;
}

export const getShowClouds = (store) => {
    const sliceData = sliceState(store);

    return sliceData ? sliceData.showClouds : false;
}

export const getShowRain = (store) => {
    const sliceData = sliceState(store);

    return sliceData ? sliceData.showRain : false;
}

export const getShowWaves = (store) => {
    const sliceData = sliceState(store);

    return sliceData ? sliceData.showWaves : false;
}

/*
 * Thunks
 */
export function showClouds() {
    return (dispatch) => {
        return dispatch(setAction(SHOW_CLOUDS, true));
    }
}

export function hideClouds() {
    return (dispatch) => {
        return dispatch(setAction(SHOW_CLOUDS, false));
    }
}

export function showRain() {
    return (dispatch) => {
        return dispatch(setAction(SHOW_RAIN, true));
    }
}

export function hideRain() {
    return (dispatch) => {
        return dispatch(setAction(SHOW_RAIN, false));
    }
}

export function showWaves() {
    return (dispatch) => {
        return dispatch(setAction(SHOW_WAVES, true));
    }
}

export function hideWaves() {
    return (dispatch) => {
        return dispatch(setAction(SHOW_WAVES, false));
    }
}

export function hideAll() {
    return (dispatch) => {
        return dispatch(setHideAll());
    }
}