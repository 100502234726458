import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames';
import styles from './NavLink.module.css'

const NavLink = ({ text, to, isActive }) => {
    return (
        <li className={styles.root}>
            <Link
                className={`${classnames(styles.link, isActive && styles.active)}`}
                to={to}
            >
                {text}
            </Link>
        </li>
    )
}

export default NavLink
