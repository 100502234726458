import React, { useState } from 'react'

import { DateRange } from '../DateRange/DateRange.js'

import { Zoom } from './Zoom';

import { Beams } from './Beams';

import { MapType, SELECTED_TODAY } from './MapType';

import { Weather } from './Weather';

import {
    useSelector,
    useDispatch,
} from 'react-redux';

import {
    openDialog,
    getFromDate,
    getToDate,
    getInterval,
    getCurrentDate,
    currentDateChanged,
    changeDateRange,
} from '../../../redux/shipview/dates';

import {
    getShowClouds,
    getShowRain,
    getShowWaves,
    showClouds as setShowClouds,
    hideClouds as setHideClouds,
    showRain as setShowRain,
    hideRain as setHideRain,
    showWaves as setShowWaves,
    hideWaves as setHideWaves,
    hideAll,
} from '../../../redux/shipview/mapweather';

import {
    setZoomIn,
    setZoomOut,
} from '../../../redux/shipview/mapzoom';

import {
    todayDateRange,
    adjustedNow,
} from '../../../utils';

import styles from './MapControls.module.css'

const MapControls = (props) => {

    const [mapType, setMapType] = useState("dated");

    const showClouds = useSelector(state => getShowClouds(state));
    const showRain = useSelector(state => getShowRain(state));
    const showWaves = useSelector(state => getShowWaves(state));

    const fromDate = useSelector(state => getFromDate(state));
    const toDate = useSelector(state => getToDate(state));
    const interval = useSelector(state => getInterval(state));
    const currentDate = useSelector(state => getCurrentDate(state));
    const dispatch = useDispatch();

    const handleCurrentDateChanged = (value) => {
        dispatch(currentDateChanged(value));
    }

    const handleChangeDates = () => {
        dispatch(openDialog());
    }

    const handleZoomIn = () => {
        dispatch(setZoomIn());
    }

    const handleZoomOut = () => {
        dispatch(setZoomOut());
    }

    const handleMapTypeOnChange = (value) => {

        setMapType(value);

        // don't show any weather related overlays when the user changes the type of map
        dispatch(hideAll());

        if (value === SELECTED_TODAY) {
            const today = todayDateRange();
            const now = adjustedNow(today.interval);
            console.log("today selected " + today.from + " " + today.to + " " + today.interval + " " + now);
            dispatch(changeDateRange(today.from, today.to, today.interval, now));
        }
    }

    const handleShowCloudsOnChange = (value) => {
        //console.log("handleShowCloudsOnChange " + value);
        if (value) {
            dispatch(setShowClouds());
        } else {
            dispatch(setHideClouds());
        }
    }

    const handleShowRainOnChange = (value) => {
        //console.log("handleShowRainOnChange " + value);
        if (value) {
            dispatch(setShowRain());
        } else {
            dispatch(setHideRain());
        }
    }

    const handleShowWavesOnChange = (value) => {
        if (value) {
            dispatch(setShowWaves());
        } else {
            dispatch(setHideWaves());
        }
    }

    // TODO : we may be able to pass a boolean down from above for Weather=disabled
    // if we change to using redux to control the routes current value, or set it in the 
    // onMapTypeChange handler
    return (
        <div className={styles.root}>
            <Zoom
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
            />

            <MapType
                selected={mapType}
                onChange={handleMapTypeOnChange}
            />

            <Beams />

            <Weather
                showClouds={showClouds}
                showRain={showRain}
                showWaves={showWaves}
                onShowCloudsChange={handleShowCloudsOnChange}
                onShowRainChange={handleShowRainOnChange}
                onShowWavesChange={handleShowWavesOnChange}
                disabled={mapType === "dated"}
            />

            <div className={styles.mapDateRangeContainer}>
                <DateRange
                    fromDate={fromDate}
                    toDate={toDate}
                    interval={interval}
                    currentDate={currentDate}
                    onCurrentDateChange={handleCurrentDateChanged}
                    onChangeDates={handleChangeDates}
                    disabled={mapType === "today"}
                />
            </div>
        </div>
    )
}

export default MapControls
