import React from 'react'
import PropTypes from 'prop-types'

import styles from './Table.module.css'

export const Table = ({ className, children }) => {
  return <table className={`${styles.root} ${className}`}>{children}</table>
}

Table.propTypes = {
  children: PropTypes.node.isRequired,
}
