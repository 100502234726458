import React from 'react'

//import Styles from '../../styles/03-components/_sidePanel.module.scss'

import styles from './SidePanel.module.css';

const SidePanel = (props) => {
    const { className, children } = props;
    return (
        <div className={`${styles.root} ${className}`}>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}

export default SidePanel
