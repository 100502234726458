import React, {
    useEffect,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
    getSelectedInstallation
} from '../../../redux/shipview/installations';

import {
    getFromDate,
    getToDate,
    getInterval,
} from '../../../redux/shipview/dates';

import {
    loadData,
    getCurrentValue,
} from '../../../redux/shipview/selectedInstallation/stats/beamUsage';


const CurrentBeamValue = () => {

    const currentValue = useSelector(state => getCurrentValue(state));

    return (<span>{ currentValue}</span>)
}

export const CurrentBeam = () => {

    const installation = useSelector(state => getSelectedInstallation(state));
    const fromDate = useSelector(state => getFromDate(state));
    const toDate = useSelector(state => getToDate(state));
    const interval = useSelector(state => getInterval(state));

    const dispatch = useDispatch();

    useEffect(() => {
        if (installation) {
            dispatch(loadData(installation.id, fromDate, toDate, interval));
        }
    }, [dispatch, installation, fromDate, toDate, interval])

    return (
        <CurrentBeamValue/>
    );
}