import React, { useMemo } from 'react';

import {
    Source,
    Layer,
} from 'react-map-gl';

import {
    getShowBeams
} from '../../../redux/shipview/overlays';

import {
    useSelector
} from 'react-redux';

import {
    getData as getBeams
} from '../../../redux/beams';

/**
 * Convert the beam data held in the database (accessed via redux) to a set of geojson
 * values that can be used by mapbox.
 * @param {any} data
 */
function makeBeamData(data) {

    //console.log("makeBeamData");

    // take a [lat,lng] value and return a [lng,lat]
    function latLngToLngLat(value) {
        return [value[1], value[0]];
    }

    // TODO: may need need to order this by value.zIndex
    const items = data && data.length ?
        data.map((value) => {
            var item = {
                zIndex: value.zIndex,
                source: {
                    type: 'Polygon',
                    coordinates: [
                        // convert the lat,lng values held in the database to lng,lat
                        value.positions && value.positions.length > 0
                            ? value.positions.map(position => latLngToLngLat(position))
                            : []
                    ]
                },
                fill: {
                    type: 'fill',
                    paint: {
                        'fill-color': value.fillColour,
                        'fill-opacity': value.fillOpacity
                    }
                },
                outline: {
                    type: 'line',
                    paint: {
                        'line-color': value.colour,
                        'line-width': value.outlineWidth,
                        'line-opacity': value.opacity
                    }
                }
            }
            //console.log("beamData " + JSON.stringify(item));
            return item;
        })
        : [];

    items.sort((a, b) => {
        if (a.zIndex < b.zIndex) {
            return -1;
        }
        if (a.zIndex > b.zIndex) {
            return 1;
        }

        return 0;
    });

    //console.log("beam data " + JSON.stringify(items));

    return items;
}

export const Beams = () => {

    //console.log("beams here ");

    const beamData = useSelector(state => getBeams(state));
    const beamDataMemo = useMemo(() => makeBeamData(beamData), [beamData]);
    const showBeams = useSelector(state => getShowBeams(state));

    return (
        beamDataMemo.map((beam, index) => (
            <Source key={`beam-source-${index}`} type='geojson' data={beam.source}>
                <Layer beforeId={"route-layer-id"} key={`beam-outline-layer-${index}`} {...beam.outline} layout={{ 'visibility': showBeams ? 'visible' : 'none' }} />
                <Layer beforeId={"route-layer-id"} key={`beam-fill-layer-${index}`} {...beam.fill} layout={{ 'visibility': showBeams ? 'visible' : 'none' }} />
            </Source>
        ))
    );
}