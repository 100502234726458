import React, {
    createRef,
    useEffect,
    useState
} from 'react';

import * as d3 from 'd3';

import styles from './Charts.module.css';

import { useSelector, useDispatch } from 'react-redux';

import {
    getSelectedInstallation
} from '../../../redux/shipview/installations';

import {
    getFromDate,
    getToDate,
    getInterval,
} from '../../../redux/shipview/dates';

import {
    loadData,
    getData,
    getCurrentCno,
    getCurrentSnr,
    isError,
    isFetching,
} from '../../../redux/shipview/selectedInstallation/stats/rf';

import {
    useWindowSize,
    formatNumber,
} from '../../../utils';

import {
    Line,
    Key,
    KeyItem,
    DebugInfo,
    TimeLine,
} from './ChartUtils.js';

import { ChartTab } from './ChartTab';

import ErrorBoundary from '../../../ErrorBoundary';

const KeyValueCno = () => {
    const current = useSelector(state => getCurrentCno(state));

    return <KeyValue current={formatNumber(current, 2)} />
}

const KeyValueSnr = () => {
    const current = useSelector(state => getCurrentSnr(state));

    return <KeyValue current={formatNumber(current, 2)} />
}

const KeyValue = (props) => {

    const { current } = props;

    return (
        <div className={styles.keyValue}>
            <span>{current}dB</span>
        </div>
    )
}

const Chart = (props) => {

    const { chartHeight, margin, data } = props;

    const cnoColor = d3.schemeCategory10[8];
    const snrColor = d3.schemeCategory10[9];

    // reference to the containing div used to respond to resize events
    const divRef = createRef();
    const size = useWindowSize();

    // height of the svg that contains the chart
    const svgHeight = chartHeight;

    // height of the chart area inside the svg container
    const height = svgHeight - margin.top - margin.bottom;

    // width of the chart area inside the svg container
    const [width, setWidth] = useState(0);

    // d3 scale functions that determine how wide the size of the x and y axes and items on those axes
    const xScale = d3.scaleUtc().range([0, width]);
    const yScale = d3.scaleLinear().range([height, 0]);

    // the extents of the items on each axis

    const minTsLower = d3.min(data, d => new Date(d.tsl));
    const maxTsLower = d3.max(data, d => new Date(d.tsl));
    const maxTsUpper = d3.max(data, d => new Date(d.tsu));

    const maxCno = d3.max(data, d => d.cno);
    const maxSnr = d3.max(data, d => d.snr);
    const maxValue = +d3.max([maxCno, maxSnr]);

    xScale.domain([minTsLower, maxTsUpper]);
    yScale.domain([0, maxValue]);

    // recalculate the chart width values when the window resizes
    useEffect(() => {
        if (divRef && divRef.current) {
            setWidth(divRef.current.offsetWidth - margin.left - margin.right);
        }
    }, [size, divRef, margin.left, margin.right])

    const cnoLine = d3.line().defined(d => d.cno).curve(d3.curveBasis).x(d => xScale(d.tsl)).y(d => yScale(d.cno));
    const snrLine = d3.line().defined(d => d.snr).curve(d3.curveBasis).x(d => xScale(d.tsl)).y(d => yScale(d.snr));

    const cnoLineProps = {
        data: data,
        lineFn: cnoLine,
        color: cnoColor,
    }

    const snrLineProps = {
        data: data,
        lineFn: snrLine,
        color: snrColor
    }

    function debugDataItems(data) {
        return (
            <div>
                {data && data.length &&
                    data.map((value, index) => {
                        return (<div key={`data-item-${index}`}>{value.tsl}, {new Date(value.tsl).toISOString()}, {value.tsu}, {value.cno}, {value.snr}</div>)
                    })
                }
            </div>
        )
    }

    const debugInfoProps = {
        show: false,
        svgHeight: svgHeight,
        height: height,
        width: width,
        windowWidth: size.width,
        windowHeight: size.height,
        xScaleRange: [xScale.range()[0], xScale.range()[1]],
        yScaleRange: [yScale.range()[0], yScale.range()[1]],
        minTslower: minTsLower ? minTsLower.toISOString() : "",
        maxTsLower: maxTsLower ? maxTsLower.toISOString() : "",
        dataLength: data.length,
        dataList: debugDataItems(data),
    }

    return (
        <div ref={divRef} className={styles.chartContainer}>
            <div className={styles.chartAndKey}>
                <svg className={styles.chartArea} height={svgHeight}>
                    <g transform={`translate(${margin.left},${margin.top})`}>
                        <g className="lines">
                            <Line {...cnoLineProps} />
                            <Line {...snrLineProps} />
                        </g>
                        <TimeLine height={height} xScale={xScale} />
                    </g>
                </svg>
                <Key>
                    <KeyItem id={"cno"} color={cnoColor} label={"C/N"}>
                        <KeyValueCno />
                    </KeyItem>
                    <KeyItem id={"snr"} color={snrColor} label={"SNR"}>
                        <KeyValueSnr />
                    </KeyItem>
                </Key>
            </div>
            <DebugInfo {...debugInfoProps} />
        </div>
    )
}

export const RfChart = (props) => {

    const installation = useSelector(state => getSelectedInstallation(state));
    const fromDate = useSelector(state => getFromDate(state));
    const toDate = useSelector(state => getToDate(state));
    const interval = useSelector(state => getInterval(state));
    const data = useSelector(state => getData(state));
    const error = useSelector(state => isError(state));
    const fetching = useSelector(state => isFetching(state));

    const dispatch = useDispatch();

    useEffect(() => {
        if (installation) {
            dispatch(loadData(installation.id, fromDate, toDate, interval));
        }
    }, [dispatch, installation, fromDate, toDate, interval])

    const title = "RF Performance (Decibels)";

    return (
        <div>
            <ChartTab>{fetching && <span>Fetching&nbsp;</span>}{error && <span className={styles.errorIndicator}>Failed to fetch&nbsp;</span>}{title}</ChartTab>
            <ErrorBoundary>
                <Chart {...props} data={data} />
            </ErrorBoundary>
        </div>
    );
}