/*
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */

import axios from 'axios';
import { LOGGED_OUT } from '../../../user/identity';
import { INSTALLATION_SELECTED } from '../../installations';
import { PACKAGES_CHANGED } from './packageChanges';

/*
 * Actions
 */
const DATA_REQUESTED = 'fleetwide/shipview/selectedInstallation/packages/scheduledChanges/REQUESTED';
const DATA_RECEIVED = 'fleetwide/shipview/selectedInstallation/packages/scheduledChanges/RECEIVED';
const DATA_FAILED = 'fleetwide/shipview/selectedInstallation/packages/scheduledChanges/FAILED';

/*
 * Initial State
 */
const initialState = {
    isFetching: false,
    didInvalidate: false,
    isError: false,
    data: null,
}

/*
 * Reducer Functions
 */
const handleRequested = (state) => {
    return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        isError: false,
    }
}

const handleReceived = (state, data) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: false,
        data: data,
    }
}

const handleFailed = (state) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: true,
        data: null,
    }
}

const handleDataInvalidated = (state) => {
    return {
        ...state,
        didInvalidate: true,
    }
}

const handleUserLoggedOut = () => {
    return Object.assign({}, initialState);
}

/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case DATA_REQUESTED:
            return handleRequested(state);
        case DATA_RECEIVED:
            return handleReceived(state, action.payload);
        case DATA_FAILED:
            return handleFailed(state);
        case INSTALLATION_SELECTED:
            return handleDataInvalidated(state);
        case PACKAGES_CHANGED:
            return handleDataInvalidated(state);
        case LOGGED_OUT:
            return handleUserLoggedOut();
        default:
            return state;
    }
}

/*
 * Action Creators
 */
function dataRequested() {
    return {
        type: DATA_REQUESTED
    }
}

function dataReceived(data) {
    return {
        type: DATA_RECEIVED,
        payload: data
    }
}

function dataFailed() {
    return {
        type: DATA_FAILED
    }
}

/*
 * Selectors
 */
const sliceState = (store) => {
    return store.shipview.selectedInstallation.packages.scheduledChanges;
}

export const getData = (store) => {
    return sliceState(store) ? sliceState(store).data : [];
}

export const getDataForEquipment = (store, wanInterfaceId, sabaEquipmentId) => {
    const data = getData(store);

    if (data && data.length > 0)
    {
        //console.log("looking for wanInterfaceId " + wanInterfaceId + " sabaEquipmentId " + sabaEquipmentId);

        const item = data.filter(value => value.wanInterfaceId === wanInterfaceId && value.sabaEquipmentId === sabaEquipmentId);

        //console.log("item is " + JSON.stringify(item));

        if (item && item.length === 1) {
            const ret = item[0];

            ret.scheduledChanges.sort((a, b) => {
                if (a.startDate > b.startDate) { return 1 };
                if (a.startDate < b.startDate) { return -1 };
                return 0;
            });

            return ret;
        }
    }

    return null;
}

export const isFetching = (store) => {
    return sliceState(store) ? sliceState(store).isFetching : false;
}

export const isError = (store) => {
    return sliceState(store) ? sliceState(store).isError : false;
}

export const dataInvalidated = (store) => {
    return sliceState(store) ? sliceState(store).didInvalidate : false;
}

/*
 * Side Effects
 */

// axios cancellation is based on the deprecated cancellable Promise
let CancelToken = axios.CancelToken;
let canceller;

const fetchData = (installationId) => {
    // Thunk middleware knows how to handle functions.
    // It passes the dispatch method as an argument to the function,
    // thus making it able to dispatch methods to itself

    return function (dispatch) {
        // update the UI state to say the API call is starting
        dispatch(dataRequested());

        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.

        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.

        // cancel the previous request if we have a valid canceller
        canceller && canceller();

        const url = '/api/packages/scheduledchanges?installationId=' + installationId;

        return axios.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // create a canceller for this request
                // an executor function receives a cancel function as a parameter
                canceller = c;
            })
        })
            .then(response => {
                //console.log("scheduledChanges fetchData response " + JSON.stringify(response));
                let data = response.data.map(item => {
                    return {
                        wanInterfaceId: item.wanInterfaceId,
                        sabaEquipmentId: item.sabaEquipmentId,
                        scheduledChanges: item.items.map((change) => {
                            return {
                                startDate: change.startDate,
                                endDate: change.endDate,
                                ratePlanName: change.ratePlanName,
                                dailyAdjustment: change.dailyAdjustment,
                                loginEmail: change.email,
                            }
                        })
                    }
                })
                // dispatch the data to the store
                dispatch(dataReceived(data));
            })
            .catch(function (error) {
                if (axios.isCancel(error)) {
                    //console.log("plan usage request cancelled ", error.message);
                } else {
                    //console.log("error " + JSON.stringify(error));
                    dispatch(dataFailed());
                }
            });

        // TODO : other errors should be handled using React Error Boundaries
        // see https://reactjs.org/docs/error-boundaries.html

    }
}

function shouldFetchData(state) {
    const sliceData = sliceState(state);
    if (!sliceData) {
        //console.log("should fetch invoices returning true - slice is null");
        return true;
    } else if (sliceData.isFetching) {
        //console.log("should fetch invoices returning false - data is fetching");
        return false;
    } else {
        //console.log("should fetch invoices returning " + sliceData.didInvalidate);
        return sliceData.didInvalidate;
    }
}

// a thunk
export function loadData(installationId) {
    // Note that the function also receives getState()
    // which lets you choose what to dispatch next.

    // This is useful for avoiding a network request if
    // a cached value is already available.
    return (dispatch, getState) => {
        if (shouldFetchData(getState())) {
            return dispatch(fetchData(installationId));
        } else {
            return Promise.resolve();
        }
    }
}