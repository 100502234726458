/* 
 * redux functions for : store.user.identity
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */
import { loadPermissions } from './permissions';
import { loadBeams } from '../beams';
import { loadDefaultDates as loadShipViewDefaultDates } from '../shipview/dates';
import { loadInstallations as loadShipViewInstallations } from '../shipview/installations';
import { loadClients } from './clients';
import { loadNotificationEmails } from './notificationEmails';

/*
 * Actions
 */
export const LOGGED_IN = 'fleetwide/user/identity/LOGGED_IN';
export const LOGGED_OUT = 'fleetwide/user/identity/LOGGED_OUT';

/* 
 * Initial State
 */
const initialState = {
    id: null,
    name: null,
    isAuthenticated: false
};

/*
 * Reducer Functions
 */
const handleLoggedIn = (state, id, name) => {
    return {
        ...state,
        id: id,
        name: name,
        isAuthenticated: true
    }
}

const handleLoggedOut = () => {
    return initialState;
}
/*
 * Reducer
 */

export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOGGED_IN:
            return handleLoggedIn(state, action.payload.id, action.payload.name);
        case LOGGED_OUT:
            return handleLoggedOut();
        default:
            return state;
    }
}

/*
 * Action Creators
 */
export function userLoggedIn(data) {
    return (dispatch) => {
        // TODO: these may need to be made sequential
        dispatch({ type: LOGGED_IN, payload: data });
        dispatch(loadPermissions());
        dispatch(loadBeams());
        dispatch(loadShipViewDefaultDates());
        dispatch(loadShipViewInstallations());
        dispatch(loadClients());
        dispatch(loadNotificationEmails());
    }
}

export function userLoggedOut() {
    // TODO : do we need to dispatch other actions here to clear the state of various slices
    // OR : should the other reducer slices just handle this action?
    return {
        type: LOGGED_OUT
    }
}

/*
 * Selectors
 */
const sliceState = (store) => {
    return store.user.identity;
}

export const isLoggedIn = (store) => {
    return sliceState(store) ? sliceState(store).isAuthenticated : false;
}