/* 
 * Ship View Selected Installation reducuer
 */
import { combineReducers } from 'redux';
import { reducer as stats } from './stats';
import { reducer as invoices } from './invoices';
import { reducer as packages } from './packages';

export const selectedInstallation = combineReducers({
    stats,
    invoices,
    packages,
})