import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './TableBodyCell.module.css'

export const TableBodyCell = ({ children, align }) => {

    let alignClass;

    if (align === "right") {
        alignClass = styles.alignRight;
    } else if (align === "centre") {
        alignClass = styles.alignCentre;
    } else {
        alignClass = styles.alignLeft;
    }

    return (
        <td className={classnames(styles.root, alignClass)} align={align}>{children}</td>
    )
}

TableBodyCell.propTypes = {
    children: PropTypes.node.isRequired,
}