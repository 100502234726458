import React, { useMemo } from 'react';

import {
    Source,
    Layer,
} from 'react-map-gl';

import {
    useSelector
} from 'react-redux';

import {
    getRoutes,
} from '../../../redux/shipview/mapdata';

function makeRoutesData(data) {

    // take a [lat,lng] value and return a [lng,lat]
    function latLngToLngLat(value) {
        return [value[1], value[0]];
    }

    var result = {
        type: 'Feature',
        geometry: {
            type: 'MultiLineString',
            coordinates:
                // convert the lat,lng values held in the database to lng,lat
                data && data.length > 0
                    ? data.map(route => route.map(item => latLngToLngLat(item)))
                    : []

        }
    }

    //console.log("makeRoutesData " + JSON.stringify(result));

    return result;
}

export const Route = () => {

    //console.log("route here");

    const routeLayer = {
        type: 'line',
        paint: {
            'line-color': '#0000ff',
            'line-width': 2
        }
    }

    const routesData = useSelector(state => getRoutes(state));
    const routesDataMemo = useMemo(() => makeRoutesData(routesData), [routesData]);

    return (
        <Source type='geojson' data={routesDataMemo}>
            <Layer id={"route-layer-id"} {...routeLayer} />
        </Source>
    );
}
