import { useEffect, useRef, useContext } from 'react';

import { MapContext } from 'react-map-gl';

import {
    useSelector
} from 'react-redux';

// NOTE : had to npm install node-sass v4 (v6 doesn't work)
import AerisWeather from '@aerisweather/javascript-sdk';
import '@aerisweather/javascript-sdk/dist/styles/styles.css';

import {
    getShowClouds,
    getShowRain,
    getShowWaves,
} from '../../../redux/shipview/mapweather';

const aeris = new AerisWeather('E8m6lbzLk0Siy4aZocdJq', 'fdOY36urjBYpEOs55n8zliYpZ1HdzFdDdZaC1vN0');

export const Weather = () => {

    const WAVES = "wave-heights";
    const CLOUD = "satellite";
    const RAIN = "radar-global";

    const { map } = useContext(MapContext);

    const isFirst = useRef(true);
    const showClouds = useSelector(state => getShowClouds(state));
    const showRain = useSelector(state => getShowRain(state));
    const showWaves = useSelector(state => getShowWaves(state));
    const interactiveMap = useRef(null);

    useEffect(() => {
        if (map) {
            if (isFirst.current) {
                
                isFirst.current = false;

                aeris.views().then((views) => {
                    interactiveMap.current = new views.InteractiveMap(map, {
                        timeline: {
                            from: -3 * 3600,
                            to: 0
                        },
                        reloadOnBoundsChange: false
                    });
                });


            } else {                
                // remove all the layers first so that they get added in the correct z-index order
                interactiveMap.current.removeLayers([WAVES, CLOUD, RAIN]);

                if (showWaves) {
                    interactiveMap.current.addLayer(WAVES);
                }
                if (showClouds) {
                    interactiveMap.current.addLayer(CLOUD);
                }
                if (showRain) {
                    interactiveMap.current.addLayer(RAIN);
                }
            }
        }
    }, [map, showClouds, showRain, showWaves])

    return null;
}

