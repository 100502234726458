/*
 * redux functions for : store.shipview.ui
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */

import { LOGGED_OUT } from '../user/identity';

/*
 * Actions
 */
const CLOSED = 'fleetwide/shipview/contentpanel/CLOSED';
const BILLING = 'fleetwide/shipview/contentpanel/BILLING';
const CHARTS = 'fleetwide/shipview/contentpanel/CHARTS';
const DATA_USE = 'fleetwide/shipview/contentpanel/DATA_USE';
const NETWORK = 'fleetwide/shipview/contentpanel/NETWORK';
const PACKAGES = 'fleetwide/shipview/contentpanel/PACKAGES';
const SUPPORT_TICKETS = 'fleetwide/shipview/contentpanel/SUPPORT_TICKETS';

/*
 * Initial State
 */

const initialState = {
    isClosed: true,
    isBilling: false,
    isCharts: false,
    isDataUse: false,
    isNetwork: false,
    isPackages: false,
    isSupportTickets: false,
};

/*
 * Reducer Functions
 */
const handleIsClosed = () => {
    return Object.assign({}, initialState);
}

const handleIsBilling = () => {
    const state = Object.assign({}, initialState);
    state.isClosed = false;
    state.isBilling = true;
    return state;
}

const handleIsCharts = () => {
    const state = Object.assign({}, initialState);
    state.isClosed = false;
    state.isCharts = true;
    return state;
}

const handleIsDataUse = () => {
    const state = Object.assign({}, initialState);
    state.isClosed = false;
    state.isDataUse = true;
    return state;
}

const handleIsNetwork = () => {
    const state = Object.assign({}, initialState);
    state.isClosed = false;
    state.isNetwork = true;
    return state;
}

const handleIsPackages = () => {
    const state = Object.assign({}, initialState);
    state.isClosed = false;
    state.isPackages = true;
    return state;
}

const handleIsSupportTickets = () => {
    const state = Object.assign({}, initialState);
    state.isClosed = false;
    state.isSupportTickets = true;
    return state;
}

const handleUserLoggedOut = () => {
    return Object.assign({}, initialState);
}
/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLOSED:
            return handleIsClosed();
        case BILLING:
            return handleIsBilling();
        case CHARTS:
            return handleIsCharts();
        case DATA_USE:
            return handleIsDataUse();
        case NETWORK:
            return handleIsNetwork();
        case PACKAGES:
            return handleIsPackages();
        case SUPPORT_TICKETS:
            return handleIsSupportTickets();
        case LOGGED_OUT:
            return handleUserLoggedOut();
        default:
            return state;
    }
}

/* 
 * Action Creators
 */
export function isClosed() {
    return {
        type: CLOSED
    }
}

export function isBilling() {
    return {
        type: BILLING
    }
}

export function isCharts() {
    return {
        type: CHARTS
    }
}

export function isDataUse() {
    return {
        type: DATA_USE
    }
}

export function isNetwork() {
    return {
        type: NETWORK
    }
}

export function isPackages() {
    return {
        type: PACKAGES
    }
}

export function isSupportTickets() {
    return {
        type: SUPPORT_TICKETS
    }
}

/*
 * Selectors
 */
const uiState = (store) => {
    return store.shipview.contentPanel;
}

export const getIsClosed = (store) => {
    return uiState(store) ? uiState(store).isClosed : initialState.isClosed;
}

export const getIsBilling = (store) => {
    return uiState(store) ? uiState(store).isBilling : initialState.isBilling;
}

export const getIsCharts = (store) => {
    return uiState(store) ? uiState(store).isCharts : initialState.isCharts;
}

export const getIsDataUse = (store) => {
    return uiState(store) ? uiState(store).isDataUse : initialState.isDataUse;
}

export const getIsNetwork = (store) => {
    return uiState(store) ? uiState(store).isNetwork : initialState.isNetwork;
}

export const getIsPackages = (store) => {
    return uiState(store) ? uiState(store).isPackages : initialState.isPackages;
}

export const getIsSupportTickets = (store) => {
    return uiState(store) ? uiState(store).isSupportTickets : initialState.isSupportTickets;
}

/*
 * Side Effects
 */
