import React from "react";
import styles from "./SavingMessage.module.css";

const SavingMessage = (props) => {

    const { visible } = props;

    if (!visible) return null;

    return (
        <div className={styles.root}>
            <div>Saving your changes. Please wait...</div>
        </div>
    );
}

export default SavingMessage;