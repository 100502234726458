import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ButtonSecondary } from '../../Forms';

import {
    getDataForEquipment as getScheduledChanges,
    isFetching,
    isError,
} from '../../../redux/shipview/selectedInstallation/packages/scheduledChanges';

import { openDialog as openScheduleChangesDialog } from '../../../redux/shipview/selectedInstallation/packages/packageChanges';
import { openDialog as openImmediateUpgradeDialog } from '../../../redux/shipview/selectedInstallation/packages/immediateUpgrade';

import { userCanChangePackage as getUserCanChangePackage } from '../../../redux/user/permissions';
import { userCanViewPackagePrices as getUserCanViewPackagePrices } from '../../../redux/user/permissions';

import { DateDisplay } from '../../Forms';

import { Container, Row, Col } from 'react-grid-system';

import styles from './ScheduledChanges.module.css';

import { formatNumber } from '../../../utils';

/**
 * Component which displays a list of the existing Scheduled Changes for
 * a given wanInterfaceId and SabaEquipmentId
 * 
 * @param {any} props
 */
const ScheduledChanges = (props) => {

    // TODO: remove when things are all done
    const showDebug = false;

    const {
        wanInterfaceId,
        sabaEquipmentId,
        packageCanBeChanged, // indicates that this equipment supports package changes, subject to other business rules around in-progress packages etc.
        baseRatePlanName
    } = props;

    const data = useSelector(state => getScheduledChanges(state, wanInterfaceId, sabaEquipmentId));
    const dataIsLoading = useSelector(state => isFetching(state));
    const loadError = useSelector(state => isError(state));
    // boolean which determines if the logged in user has permission to change packages.
    const userCanChangePackage = useSelector(state => getUserCanChangePackage(state));
    // true if the user can view package prices
    const userCanViewPackagePrices = useSelector(state => getUserCanViewPackagePrices(state));

    const dispatch = useDispatch();

    if (dataIsLoading || loadError || !packageCanBeChanged) return null;

    const handleShowChangePackagesWithDialog = (wanInterfaceId, sabaEquipmentId, baseRatePlanName) => {
        dispatch(openScheduleChangesDialog(wanInterfaceId, sabaEquipmentId, baseRatePlanName));
    }

    const handleShowImmediateUpgradeDialog = (wanInterfaceId, sabaEquipmentId) => {
        dispatch(openImmediateUpgradeDialog(wanInterfaceId, sabaEquipmentId));
    }

    //console.log("scheduled changes data " + JSON.stringify(data));

    return (
        <div className={styles.root}>

            <div className={styles.title}>Up-coming and ongoing Package Changes</div>

            {showDebug &&
                <div>WanInterfaceId = {wanInterfaceId} SabaEquipmentId = {sabaEquipmentId}</div>
            }

            {data && data.scheduledChanges && data.scheduledChanges.length > 0 &&
                <Container>
                    <Row className={styles.tableHeader}>
                        <Col>Change Starts</Col>
                        <Col>Change Ends</Col>
                        <Col>Package</Col>
                        {userCanViewPackagePrices &&
                            <Col className={styles.headerColRight}>$ Daily Adjustment</Col>
                        }
                        <Col>Created By</Col>
                    </Row>
                </Container>
            }

            {data && data.scheduledChanges && data.scheduledChanges.length > 0 && data.scheduledChanges.map((v, index) => {
                return (
                    <Container key={`scheduled-change-item-${index}`} className={styles.container}>
                        <Row className={styles.row}>
                            <Col><DateDisplay milliseconds={v.startDate} showTime={true} /></Col>
                            <Col><DateDisplay milliseconds={v.endDate} showTime={true} /></Col>
                            <Col className={styles.packageName} title={v.ratePlanName} >{v.ratePlanName}</Col>
                            {userCanViewPackagePrices &&
                                <Col className={styles.dailyAdjustment} >{formatNumber((v.dailyAdjustment ? +v.dailyAdjustment : 0), 2)}</Col>
                            }
                            <Col className={styles.email} title={v.loginEmail}>{v.loginEmail}</Col>
                        </Row>
                    </Container>

                )
            })}

            <div className={styles.changePackage}>
                {userCanChangePackage &&
                    <ButtonSecondary onClick={() => handleShowChangePackagesWithDialog(wanInterfaceId, sabaEquipmentId, baseRatePlanName)}>Schedule Package Changes...</ButtonSecondary>
                }
                {userCanChangePackage &&
                    <ButtonSecondary onClick={() => handleShowImmediateUpgradeDialog(wanInterfaceId, sabaEquipmentId)}>Perform an Immediate Upgrade...</ButtonSecondary>
                }
            </div>
        </div>
    )

}

export default ScheduledChanges;