import React from 'react';

import TabbedSidePanelContent from '../../TabbedSidePanel/TabbedSidePanelContent'

import PanelHeader from './PanelHeader';
import Billing from '../Billing/Billing';
import Charts from '../Charts/Charts';
import DataUse from '../DataUse/DataUse';
import Network from '../Network/Network';
import Packages from '../Packages/Packages';
import SupportTickets from '../SupportTickets/SupportTickets';


const PanelContent = () => {

    return (
        <TabbedSidePanelContent noPadding={false}>
            <PanelHeader />
            <Billing />
            <Charts />
            <DataUse />
            <Network />
            <Packages />
            <SupportTickets />
        </TabbedSidePanelContent>
    )
}

export default PanelContent;