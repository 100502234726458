import React from "react";

import { formatNumber } from "../../../utils";
import { userCanViewPackagePrices as getUserCanViewPackagePrices } from '../../../redux/user/permissions';
import { useSelector } from "react-redux";

import styles from "./PackageList.module.css";
import classnames from "classnames";

const PackageList = props => {

    const { packages, selectedRatePlanId, onChange } = props;

    // true if the user can view package prices
    const userCanViewPackagePrices = useSelector(state => getUserCanViewPackagePrices(state));

    function handleChange(event) {
        onChange(+event.target.value);
    }

    return (
        <div className={styles.root}>

            {packages.map((value) => {
                var isChecked = value.ratePlanId === selectedRatePlanId;
                const id = `package-list-item-id-${value.ratePlanId}`;
                return (
                    <div key={id} className={`${classnames(styles.row, isChecked && styles.checked)}`}>
                        <input
                            type="radio"
                            id={id}
                            value={value.ratePlanId}
                            checked={value.ratePlanId === selectedRatePlanId}
                            onChange={handleChange}
                        />
                        <label htmlFor={id} className={styles.packageName}>
                            {value.ratePlanName}
                        </label>
                        {userCanViewPackagePrices &&
                            <label htmlFor={id}>
                                ${formatNumber(value.dailyAdjustment, 0)}
                            </label>
                        }
                    </div>
                );
            })}

        </div>
    );
}

export default PackageList;