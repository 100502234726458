import React from 'react';

import { useSelector } from 'react-redux';

import { getIsBilling } from '../../../redux/shipview/contentPanel';

import { getSelectedInstallation } from '../../../redux/shipview/installations';

import InvoiceHistory from './InvoiceHistory';

import TabbedSidePanelContentBody from '../../TabbedSidePanel/TabbedSidePanelContentBody'

const Billing = () => {

    const show = useSelector(state => getIsBilling(state));

    const selectedInstallation = useSelector(state => getSelectedInstallation(state));

    if (show && selectedInstallation) {
        return (
            <TabbedSidePanelContentBody noPadding={false}>
                <div>
                    <InvoiceHistory />
                </div>
            </TabbedSidePanelContentBody>
        )
    } else {
        return null;
    }

}

export default Billing;