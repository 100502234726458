import React from 'react'
import classnames from 'classnames'

import styles from './TabbedSidePanelContentBody.module.css'

const TabbedSidePanelContentBody = ({noPadding, children }) => {

    return (
        <main className={`${classnames(styles.root, noPadding && styles.noPadding)}`}>
            {children}
        </main>
    )
}

export default TabbedSidePanelContentBody;