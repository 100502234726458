import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
    getDialogIsOpen,
    closeDialog,
    getFromDate,
    getToDate,
    changeDateRange,
} from '../../redux/shipview/dates'

import { Dates } from '../../utils';

import DateRangeDialog from '../DateRangeDialog/DateRangeDialog';

const ShipViewChangeDatesDialog = () => {

    const fromDate = useSelector(state => getFromDate(state));
    const toDate = useSelector(state => getToDate(state));
    const show = useSelector(state => getDialogIsOpen(state));

    //console.log(`dialog init from=${fromDate} ${Dates.fromMillis(fromDate).toString(Dates.DATE_FORMAT_LONG_WITH_MILLISECONDS)} to=${toDate} ${Dates.fromMillis(toDate).toString(Dates.DATE_FORMAT_LONG_WITH_MILLISECONDS)}`);


    const dispatch = useDispatch();

    const handleOnSave = (fromDate, toDate, interval) => {
        //console.log(`dialog save from=${fromDate} ${Dates.fromMillis(fromDate).toString(Dates.DATE_FORMAT_LONG_WITH_MILLISECONDS)} to=${toDate} ${Dates.fromMillis(toDate).toString(Dates.DATE_FORMAT_LONG_WITH_MILLISECONDS)}`);

        dispatch(changeDateRange(fromDate, toDate, interval, fromDate));
    }

    const handleOnCancel = () => {
        dispatch(closeDialog());
    }

    if (show) {
        return <DateRangeDialog
            title={"Map and Vessel Connectivity Date"}
            initialFromDate={fromDate}
            initialToDate={Dates.fromMillis(toDate).startOfDay().millis()}
            onCancel={handleOnCancel}
            onSave={handleOnSave}
        />
    }

    return null;
}

export default ShipViewChangeDatesDialog;