import React from 'react';

import TabbedSidePanelContentBody from '../../TabbedSidePanel/TabbedSidePanelContentBody'

import { useSelector } from 'react-redux';

import { getIsDataUse } from '../../../redux/shipview/contentPanel';

import { getSelectedInstallation } from '../../../redux/shipview/installations';

const DataUse = () => {

    const show = useSelector(state => getIsDataUse(state));

    const selectedInstallation = useSelector(state => getSelectedInstallation(state));

    if (show && selectedInstallation) {
        return (
            <TabbedSidePanelContentBody noPadding={false}>
                <div>
                    <p>Data Use - Coming in a later release...</p>
                </div>
            </TabbedSidePanelContentBody>
        )
    }

    return null;
}

export default DataUse;