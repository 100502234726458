import React from 'react'
import classnames from 'classnames'

import styles from './TabbedSidePanel.module.css'

const TabbedSidePanel = ({ isClosed, children, }) => {

    return (
        <div className={`${classnames(styles.root, isClosed && styles.closed)}`}>
            {children}
        </div>
    )
}

export default TabbedSidePanel;