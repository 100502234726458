import React from 'react';

import styles from './ShipViewPage.module.css';

import { Panel as Content } from './ContentPanel/Panel';
import ShipViewShipsPanel from './SidePanel/ShipViewShipsPanel';
import ShipViewChangeDatesDialog from './ShipViewChangeDatesDialog';
import MapControls from './MapControls/MapControls';
import InvoiceHistoryChangeDatesDialog from './Billing/InvoiceHistoryChangeDatesDialog';
import PackageChanges from './Packages/PackageChanges';
import { ImmediateUpgrade } from './Packages/ImmediateUpgrade';
import ShipCardStatusUpdater from './SidePanel/ShipCardStatusUpdater';

import { ShipMap } from './Map/ShipMap';
import { LoadingIndicator } from './Map/LoadingIndicator';
import { ShipInfo } from './Map/ShipInfo';

import { getDialogIsOpen as getImmediateUpgradeDialogIsOpen } from "../../redux/shipview/selectedInstallation/packages/immediateUpgrade";

import { useSelector } from "react-redux";

const ShipViewPage = () => {

    const showImmediateUpgrade = useSelector(state => getImmediateUpgradeDialogIsOpen(state));

    return (
        <div className={styles.mapContainer}>

            <div className={styles.map}>
                <ShipMap />
                <LoadingIndicator />
            </div>

            <div className={styles.shipInfo}>
                <ShipInfo />
            </div>

            <div className={styles.mapControls}>
                <MapControls />
            </div>

            <ShipViewShipsPanel />

            <Content />

            <ShipViewChangeDatesDialog />

            <PackageChanges />

            {showImmediateUpgrade &&
                <ImmediateUpgrade />
            }

            <InvoiceHistoryChangeDatesDialog />

            <ShipCardStatusUpdater />

        </div>
    );
}

export default ShipViewPage;