/* 
 * redux functions for : store.user.settings
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */
import axios from 'axios';
import { LOGGED_IN } from './identity';

/*
 * Actions
 */
const NOTIFICATION_EMAILS_REQUESTED = 'fleetwide/user/notificationEmails/REQUESTED';
const NOTIFICATION_EMAILS_RECEIVED = 'fleetwide/user/notificationEmails/RECEIVED';
const NOTIFICATION_EMAILS_FAILED = 'fleetwide/user/notificationEmails/FAILED';

/*
 * Initial State
 */

const initialState = {
    isFetching: false,
    didInvalidate: false,
    isError: false,
    items: [],
};

/*
 * Reducer Functions
 */
const handleRequested = (state) => {
    return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        isError: false,
    };
}

const handleReceived = (state, payload) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: false,
        items: payload.items,
    };
}

const handleFailed = (state) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: true,
    };
}

const handleUserLoggedIn = (state) => {
    return {
        ...state,
        didInvalidate: true,
        items: [],
    }
}

/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case NOTIFICATION_EMAILS_REQUESTED:
            return handleRequested(state);
        case NOTIFICATION_EMAILS_RECEIVED:
            return handleReceived(state, action.payload);
        case NOTIFICATION_EMAILS_FAILED:
            return handleFailed(state);
        case LOGGED_IN:
            return handleUserLoggedIn(state);
        default:
            return state;
    }
}

/* 
 * Action Creators
 */
function notificationEmailsRequested() {
    return {
        type: NOTIFICATION_EMAILS_REQUESTED
    }
}

function notificationEmailsReceived(items) {
    return {
        type: NOTIFICATION_EMAILS_RECEIVED,
        payload: {
            items: items
        }
    }
}

function notificationEmailsFailed() {
    return {
        type: NOTIFICATION_EMAILS_FAILED
    }
}

/*
 * Selectors
 */
const sliceState = (store) => {
    return store.user.notificationEmails;
}

export const getNotificationEmails = (store) => {
    return sliceState(store) ? sliceState(store).items : [];
}

/*
 * Side Effects
 */
const fetchNotificationEmails = () => {
    return function (dispatch) {
        dispatch(notificationEmailsRequested());

        return axios.get('/api/user/notificationemails',)
            .then(response => {
                dispatch(notificationEmailsReceived(response.data));
            })
            .catch(function (error) {
                dispatch(notificationEmailsFailed());
            });
    }
}

function shouldFetchNotificationEmails(state, force) {
    const notificationEmails = state.user.notificationEmails;
    if (!notificationEmails) {
        return true;
    } else if (notificationEmails.isFetching) {
        return false;
    } else {
        return notificationEmails.didInvalidate || force;
    }
}

export function loadNotificationEmails(force) {
    return (dispatch, getState) => {
        if (shouldFetchNotificationEmails(getState(), force)) {
            return dispatch(fetchNotificationEmails())
        } else {
            return Promise.resolve();
        }
    }
}