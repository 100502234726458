import React from 'react';

import { useSelector } from 'react-redux';

import {
    getIsBilling as getContentIsBilling,
    getIsCharts as getContentIsCharts,
    getIsDataUse as getContentIsDataUse,
    getIsNetwork as getContentIsNetwork,
    getIsPackages as getContentIsPackages,
    getIsSupportTickets as getContentIsSupportTickets,
} from '../../../redux/shipview/contentPanel';

import TabbedSidePanelContentHeader from '../../TabbedSidePanel/TabbedSidePanelContentHeader'

import { getSelectedInstallation } from '../../../redux/shipview/installations';

import TicketsHeader from '../SupportTickets/TicketsHeader';

const PanelHeader = () => {

    const contentIsBilling = useSelector(state => getContentIsBilling(state));
    const contentIsCharts = useSelector(state => getContentIsCharts(state));
    const contentIsDataUse = useSelector(state => getContentIsDataUse(state));
    const contentIsNetwork = useSelector(state => getContentIsNetwork(state));
    const contentIsPackages = useSelector(state => getContentIsPackages(state));
    const contentIsSupportTickets = useSelector(state => getContentIsSupportTickets(state));
    const selectedInstallation = useSelector(state => getSelectedInstallation(state));

    const installationName = selectedInstallation ? selectedInstallation.name : "(Nothing selected.  Please select a ship)";


    const noPadding = false;

    let header = "";

    if (contentIsBilling) {
        header = "Invoice History";
    } else if (contentIsCharts) {
        header = "Vessel Connectivity";
    } else if (contentIsDataUse) {
        header = "Data Use"
    } else if (contentIsNetwork) {
        header = "Network"
    } else if (contentIsPackages) {
        header = "Data Packages"
    } else if (contentIsSupportTickets) {
        header = "Support Tickets"
    } else {
        header = "Unknown"
    }

    return (
        <TabbedSidePanelContentHeader title={header} subTitle={installationName}noPadding={noPadding}>
            {contentIsSupportTickets &&
                <TicketsHeader />
            }
        </TabbedSidePanelContentHeader>
    )
}

export default PanelHeader;