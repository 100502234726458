import React from 'react'
import PropTypes from 'prop-types'

import styles from './PasswordInputs.module.css';

export const PasswordInput = (props) => {
    const { id, placeholder, autoComplete, onChange } = props;

    return (
        <input
            className={styles.password}
            id={id}
            type="password"
            defaultValue=''
            placeholder={placeholder}
            autoComplete={autoComplete}
            onChange={onChange}
        />
    )
}

export const CurrentPasswordInput = (props) => {
    const { id, placeholder, onChange } = props;

    return (
        <PasswordInput id={id} placeholder={placeholder} autoComplete={"current-password"} onChange={onChange} />
    )
}

CurrentPasswordInput.propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
}

export const NewPasswordInput = (props) => {
    const { id, placeholder, onChange } = props;

    return (
        <PasswordInput id={id} placeholder={placeholder} autoComplete={"new-password"} onChange={onChange} />
    )
}

NewPasswordInput.propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
}