import React, { useEffect, useState } from 'react';

// this import is required to get round the react-map-gl issues with babel.
// see https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-792053551
import mapboxgl from "mapbox-gl";
import MapGL, { FlyToInterpolator, MapContext } from 'react-map-gl';

import {
    Route,
} from './Route';

import {
    ShipPoint,
} from './ShipPoint';

import {
    Beams,
} from './Beams';

import {
    Weather
} from './Weather';

import { WeatherLegends } from './WeatherLegends';

import {
    useSelector,
    useDispatch,
} from 'react-redux';

import {
    getZoomIn,
    getZoomOut,
} from '../../../redux/shipview/mapzoom';

import {
    getSelectedInstallation,
} from '../../../redux/shipview/installations';

import {
    loadData,
    getFirstLocation,
    isFetching as getIsLoading,
} from '../../../redux/shipview/mapdata';

import {
    getFromDate,
    getToDate,
    getInterval,
} from '../../../redux/shipview/dates';

// this is required to get round the react-map-gl issues with babel.
// see https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-792053551
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export const ShipMap = () => {

    // TODO this needs to be held in dotenv (satcomglobal account)
    const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2F0Y29tZ2xvYmFsIiwiYSI6ImNrcDJpNjJ5OTAxY2kycXIyeDFkZHFiYXkifQ.Cc4Xwv7spIJih_MPwHVp7Q';

    const MAX_ZOOM = 24;
    const MIN_ZOOM = 0;
    const DEFAULT_ZOOM = 2;

    const isLoading = useSelector(state => getIsLoading(state));
    const installation = useSelector(state => getSelectedInstallation(state));
    const fromDate = useSelector(state => getFromDate(state));
    const toDate = useSelector(state => getToDate(state));
    const interval = useSelector(state => getInterval(state));

    const [viewport, setViewport] = useState({
        latitude: 0,
        longitude: 0,
        zoom: DEFAULT_ZOOM,
        bearing: 0,
        pitch: 0
    });

    const zoomIn = useSelector(state => getZoomIn(state));
    const zoomOut = useSelector(state => getZoomOut(state));

    const flyToLocation = useSelector(state => getFirstLocation(state));

    const dispatch = useDispatch();

    // handle zoom in request
    useEffect(() => {
        if (zoomIn) {
            setViewport(v => {
                return {
                    ...v,
                    zoom: v.zoom < MAX_ZOOM ? v.zoom + 1 : v.zoom
                }
            })
        }
    }, [zoomIn]);

    // handle zoom out request
    useEffect(() => {
        if (zoomOut) {
            setViewport(v => {
                return {
                    ...v,
                    zoom: v.zoom > MIN_ZOOM ? v.zoom - 1 : v.zoom
                }
            })
        }
    }, [zoomOut]);

    // handle a change of selected installation
    useEffect(() => {
        if (installation) {
            dispatch(loadData(installation.id, fromDate, toDate, interval));
        }
    }, [dispatch, installation, fromDate, toDate, interval]);

    // handle fly to after a change of installation
    useEffect(() => {
        if (!isLoading && flyToLocation && flyToLocation.longitude && flyToLocation.latitude) {
            setViewport(v => {
                return {
                    ...v,
                    longitude: flyToLocation.longitude.value,
                    latitude: flyToLocation.latitude.value,
                    transitionInterpolator: new FlyToInterpolator({ speed: 3 }),
                    transitionDuration: 'auto'
                }
            })
        }
    }, [flyToLocation, isLoading]);

    return (
        <MapContext.Provider>
            <MapGL
                {...viewport}
                width="100%"
                height="100%"
                mapStyle="mapbox://styles/mapbox/streets-v11"
                onViewportChange={setViewport}
                mapboxApiAccessToken={MAPBOX_TOKEN}
            >
                <Route />
                <ShipPoint />
                <Beams />
                <Weather />
                <WeatherLegends />
            </MapGL>
        </MapContext.Provider>
    );
}