/*
 * redux functions for : store.shipview.selectedInstallation.invoices
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */

import axios from 'axios';

import { LOGGED_OUT } from '../../user/identity';
import { INSTALLATION_SELECTED } from '../installations';

import { Dates } from "../../../utils";

/*
 * Actions
 */
const DATA_REQUESTED = 'fleetwide/shipview/selectedInstallation/invoices/REQUESTED';
const DATA_RECEIVED = 'fleetwide/shipview/selectedInstallation/invoices/RECEIVED';
const DATA_FAILED = 'fleetwide/shipview/selectedInstallation/invoices/FAILED';
const DATES_CHANGED = 'fleetwide/shipview/selectedInstallation/invoices/DATES_CHANGED';
const DIALOG_CLOSED = 'fleetwide/shipview/selectedInstallation/invoices/DIALOG_CLOSED';
const DIALOG_OPENED = 'fleetwide/shipview/selectedInstallation/invoices/DIALOG_OPENED';

/*
 * Initial State
 */
const initialState = {
    dialogIsOpen: false,
    isFetching: false,
    didInvalidate: false,
    isError: false,
    fromDate: Dates.today().addDays(-90).millis(), 
    toDate: Dates.today().endOfDay().millis(), 
    data: null,
}

/*
 * Reducer Functions
 */
const handleRequested = (state) => {
    return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        isError: false,
    }
}

const handleReceived = (state, data) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: false,
        data: data
    }
}

const handleFailed = (state) => {
    return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: true,
        data: null,
    }
}

const handleInstallationSelected = (state) => {
    return {
        ...state,
        didInvalidate: true,
    }
}

const handleDialogClosed = (state) => {
    return {
        ...state,
        dialogIsOpen: false,
    }
}

const handleDialogOpened = (state) => {
    return {
        ...state,
        dialogIsOpen: true,
    }
}

const handleDatesChanged = (state, fromDate, toDate) => {
    return {
        ...state,
        didInvalidate: true,
        fromDate: fromDate,
        toDate: toDate
    }
}

const handleUserLoggedOut = () => {
    return Object.assign({}, initialState);
}

/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case DATA_REQUESTED:
            return handleRequested(state);
        case DATA_RECEIVED:
            return handleReceived(state, action.payload.data);
        case DATA_FAILED:
            return handleFailed(state);
        case INSTALLATION_SELECTED:
            return handleInstallationSelected(state);
        case DIALOG_CLOSED:
            return handleDialogClosed(state);
        case DIALOG_OPENED:
            return handleDialogOpened(state);
        case DATES_CHANGED:
            return handleDatesChanged(state, action.payload.fromDate, action.payload.toDate);
        case LOGGED_OUT:
            return handleUserLoggedOut();
        default:
            return state;
    }
}

/*
 * Action Creators
 */
function dataRequested() {
    return {
        type: DATA_REQUESTED
    }
}

function dataReceived(data) {
    return {
        type: DATA_RECEIVED,
        payload: {
            data: data
        }
    }
}

function dataFailed() {
    return {
        type: DATA_FAILED
    }
}

function datesChanged(fromDate, toDate) {
    return {
        type: DATES_CHANGED,
        payload: {
            fromDate: fromDate,
            toDate: toDate,
        }
    }
}

export function closeDialog() {
    return {
        type: DIALOG_CLOSED,
    }
}

export function openDialog() {
    return {
        type: DIALOG_OPENED,
    }
}

/*
 * Selectors
 */
const sliceState = (store) => {
    return store.shipview.selectedInstallation.invoices;
}

export const getData = (store) => {
    return sliceState(store) ? sliceState(store).data : [];
}

export const isFetching = (store) => {
    return sliceState(store) ? sliceState(store).isFetching : false;
}

export const isError = (store) => {
    return sliceState(store) ? sliceState(store).isError : false;
}

export const getFromDate = (store) => {
    return sliceState(store) ? sliceState(store).fromDate : initialState.fromDate;
}

export const getToDate = (store) => {
    return sliceState(store) ? sliceState(store).toDate : initialState.toDate;
}

export const getDialogIsOpen = (store) => {
    return sliceState(store) ? sliceState(store).dialogIsOpen : initialState.dialogIsOpen;
}

/*
 * Side Effects
 */

// axios cancellation is based on the deprecated cancellable Promise
let CancelToken = axios.CancelToken;
let canceller;

const fetchData = (installationId, fromDate, toDate) => {
    // Thunk middleware knows how to handle functions.
    // It passes the dispatch method as an argument to the function,
    // thus making it able to dispatch methods to itself

    return function (dispatch) {
        // update the UI state to say the API call is starting
        dispatch(dataRequested());

        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.

        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.

        // cancel the previous request if we have a valid canceller
        canceller && canceller();

        const url = '/api/invoices/history?installationId=' + installationId + '&startTimeUnix=' + fromDate + '&endTimeUnix=' + toDate;

        return axios.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // create a canceller for this request
                // an executor function receives a cancel function as a parameter
                canceller = c;
            })
        })
            .then(response => {
                //console.log("response " + JSON.stringify(response));
                let data = (response.data && response.data.length) ? response.data.map(item => {
                    return {
                        invoiceId: item.invoiceId,
                        invoiceNumber: item.invoiceNumber,
                        date: +item.invoiceDate,
                        total: +item.invoiceTotal,
                        tax: +item.invoiceTax
                    }
                }) : [];

                // dispatch the data to the store
                dispatch(dataReceived(data));
            })
            .catch(function (error) {
                if (axios.isCancel(error)) {
                    //console.log("invoices request cancelled ", error.message);
                } else {
                    //console.log("error " + JSON.stringify(error));
                    dispatch(dataFailed());
                }
            });

        // TODO : other errors should be handled using React Error Boundaries
        // see https://reactjs.org/docs/error-boundaries.html

    }
}

function shouldFetchData(state) {
    const sliceData = sliceState(state);
    if (!sliceData) {
        //console.log("should fetch invoices returning true - slice is null");
        return true;
    } else if (sliceData.isFetching) {
        //console.log("should fetch invoices returning false - data is fetching");
        return false;
    } else {
        //console.log("should fetch invoices returning " + sliceData.didInvalidate);
        return sliceData.didInvalidate;
    }
}

// a thunk
export function loadData(installationId, fromDate, toDate) {
    // Note that the function also receives getState()
    // which lets you choose what to dispatch next.

    // This is useful for avoiding a network request if
    // a cached value is already available.
    return (dispatch, getState) => {
        if (shouldFetchData(getState())) {
            return dispatch(fetchData(installationId, fromDate, toDate));
        } else {
            return Promise.resolve();
        }
    }
}

function canSetDates(state) {
    const slice = sliceState(state);
    if (!slice) {
        return false;
    } else if (slice.isFetching) {
        return false;
    } else {
        return true;
    }
}

export function changeDateRange(fromDate, toDate) {
    if (isNaN(fromDate)) {
        throw Error("fromDate must be a number");
    }
    if (isNaN(toDate)) {
        throw Error("toDate must be a number");
    }
 
    return (dispatch, getState) => {
        if (canSetDates(getState())) {
            dispatch(datesChanged(fromDate, toDate));
            return dispatch(closeDialog());
        } else {
            return Promise.resolve();
        }
    }
}