/*
 * Redux Store configuration
 * -----------------------------------------------------
 */
import {
    applyMiddleware,
    combineReducers,
    compose,
    createStore
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { reducer as beams } from './beams.js';
import { user } from './user';
import { shipview } from './shipview';

/*
 * Reducer slices
 */

/*
 * Creates a single root reducer for the store
 */
const rootReducer = combineReducers({
    beams,
    user,
    shipview,
});

/*
 * Configures the Redux store
 */
const configureStore = preloadedState => {

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        rootReducer,
        preloadedState,
        composeEnhancers(
            applyMiddleware(
                thunkMiddleware,
            ),
        )
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept(['./shipview', './user'], () => {
            store.replaceReducer(rootReducer)
        })
    }

    return store
};

export default configureStore;
