import React from 'react'
import styles from './NavLinkList.module.css'

const NavLinkList = ({ children }) => {
    return (
        <ul className={styles.root}>
            {children}
        </ul>
    )
}

export default NavLinkList
