import React from 'react'

import { ButtonPrimary } from '../Forms'

import styles from './SidePanelHeader.module.css';

const SidePanelHeader = (props) => {
    const { title, onClose, children } = props;
    return (
        <header className={styles.root}>
            <h1>{title}</h1>
            {children}
            <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
        </header>
    )
}

export default SidePanelHeader
