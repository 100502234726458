import React from 'react';

import TabbedSidePanelContentBody from '../../TabbedSidePanel/TabbedSidePanelContentBody'

import { useSelector } from 'react-redux';

import { getIsNetwork } from '../../../redux/shipview/contentPanel';

import { getSelectedInstallation } from '../../../redux/shipview/installations';

const Network = () => {

    const show = useSelector(state => getIsNetwork(state));

    const selectedInstallation = useSelector(state => getSelectedInstallation(state));

    if (show && selectedInstallation) {
        return (
            <TabbedSidePanelContentBody noPadding={false}>
            <div>
                <p>Network - Coming in a later release...</p>
            </div>
            </TabbedSidePanelContentBody>
        );
    }

    return null;
}

export default Network;