import React from 'react'
import { Link } from 'react-router-dom';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import useAuthentication from './useAuthentication';

const LoginMenu = () => {
    const [auth] = useAuthentication();

    if (auth.isAuthenticated) {
        return (<></>)
    } else {
        const loginPath = `${ApplicationPaths.Login}`;
        return (
            <>
                <Link to={loginPath}>Sign In</Link>
            </>
        );
    }
}

export default LoginMenu;