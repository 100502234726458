/*
 *
 * Structure loosely follows Ducks pattern : https://github.com/erikras/ducks-modular-redux
 *
 */
import axios from 'axios';

import { LOGGED_OUT } from '../../../user/identity';

import { PACKAGES_CHANGED } from "./packageChanges";

/*
 * Actions
 */
const OPEN_DIALOG = 'auranow/shipview/selectedInstallation/packages/immediateUpgrade/OPEN_DIALOG';
const CLOSE_DIALOG = 'auranow/shipview/selectedInstallation/packages/immediateUpgrade/CLOSE_DIALOG';
const DATA_RECEIVED = 'auranow/shipview/selectedInstallation/packages/immediateUpgrade/DATA_RECEIVED';
const DATA_FAILED = 'auranow/shipview/selectedInstallation/packages/immediateUpgrade/DATA_FAILED';
const SAVING = 'auranow/shipview/selectedInstallation/packages/immediateUpgrade/SAVING';
const SAVE_FAILED = 'auranow/shipview/selectedInstallation/packages/immediateUpgrade/SAVE_FAILED';

/*
 * Initial State
 */
const initialState = {
    isFetching: false,
    isError: false,
    dialogIsOpen: false,
    isSaving: false,
    saveFailed: false,
    wanInterfaceId: null,
    sabaEquipmentId: null,
    immediateUpgradeAllowed: false,
    immediateUpgradeEarliestStartDate: null,
    immediateUpgradeEarliestEndDate: null,
    availablePackages: [],
}

/*
 * Reducer Functions
 */
const handleOpenDialog = () => {
    // effectively this is data requested
    return {
        ...initialState,
        dialogIsOpen: true,
        isFetching: true,
    }
}

const handleDataReceived = (state, data) => {
    return {
        ...state,
        isFetching: false,
        isError: false,
        wanInterfaceId: data.wanInterfaceId,
        sabaEquipmentId: data.sabaEquipmentId,
        immediateUpgradeAllowed: data.immediateUpgradeAllowed,
        immediateUpgradeEarliestStartDate: data.immediateUpgradeEarliestStartDate,
        immediateUpgradeEarliestEndDate: data.immediateUpgradeEarliestEndDate,
        availablePackages: data.availablePackages,
    }
}

const handleDataFailed = (state) => {
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
}

const handleSaving = (state) => {
    return {
        ...state,
        isSaving: true,
        saveFailed: false,
    }
}

const handleSaveFailed = (state) => {
    return {
        ...state,
        isSaving: false,
        saveFailed: true
    }
}

const handleCloseDialog = () => {
    return { ...initialState };
}

const handleUserLoggedOut = () => {
    return { ...initialState };
}

/*
 * Reducer
 */
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case OPEN_DIALOG:
            return handleOpenDialog();
        case DATA_RECEIVED:
            return handleDataReceived(state, action.payload);
        case DATA_FAILED:
            return handleDataFailed(state);
        case CLOSE_DIALOG:
            return handleCloseDialog();
        case SAVING:
            return handleSaving(state);
        case SAVE_FAILED:
            return handleSaveFailed(state);
        case LOGGED_OUT:
            return handleUserLoggedOut();
        default:
            return state;
    }
}

/*
 * Action Creators
 */
function actionOpenDialog() {
    return {
        type: OPEN_DIALOG,
    }
}

function actionDataReceived(data) {
    return {
        type: DATA_RECEIVED,
        payload: data
    }
}

function actionDataFailed() {
    return {
        type: DATA_FAILED
    }
}

function actionSaving() {
    return {
        type: SAVING
    }
}

function actionSaveFailed() {
    return {
        type: SAVE_FAILED
    }
}

function actionPackagesChanged() {
    return {
        type: PACKAGES_CHANGED
    }
}

function actionCloseDialog() {
    return {
        type: CLOSE_DIALOG
    }
}

/*
 * Selectors
 */
const sliceState = (store) => {
    return store.shipview.selectedInstallation.packages.immediateUpgrade;
}

export const getDialogIsOpen = (store) => {
    return sliceState(store) ? sliceState(store).dialogIsOpen : false;
}

const getWanInterfaceId = (store) => {
    return sliceState(store) ? sliceState(store).wanInterfaceId : null;
}

export const getAvailablePackages = (store) => {
    return sliceState(store) ? sliceState(store).availablePackages : [];
}

export const getIsFetching = (store) => {
    return sliceState(store) ? sliceState(store).isFetching : false;
}

export const getIsSaving = (store) => {
    return sliceState(store) ? sliceState(store).isSaving : false;
}

export const getSaveFailed = (store) => {
    return sliceState(store) ? sliceState(store).saveFailed : false;
}

export const getCanPerformImmediateUpgrade = (store) => {
    return sliceState(store) ? sliceState(store).immediateUpgradeAllowed : false;
}

export const getImmediateUpgradeEarliestStartDate = (store) => {
    return sliceState(store) ? sliceState(store).immediateUpgradeEarliestStartDate : null;
}

export const getImmediateUpgradeEarliestEndDate = (store) => {
    return sliceState(store) ? sliceState(store).immediateUpgradeEarliestEndDate : null;
}

/*
 * Side Effects
 */
// axios cancellation is based on the deprecated cancellable Promise
let CancelToken = axios.CancelToken;
let canceller;

const fetchData = (wanInterfaceId, sabaEquipmentId) => {

    return function (dispatch) {
        dispatch(actionOpenDialog());

        // cancel the previous request if we have a valid canceller
        canceller && canceller();

        const url = '/api/packages/immediateUpgrade?wanInterfaceId=' + wanInterfaceId + "&sabaEquipmentId=" + sabaEquipmentId ;

        return axios.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // create a canceller for this request
                // an executor function receives a cancel function as a parameter
                canceller = c;
            })
        })
            .then(response => {
                //console.log("immediateUpgrade fetch data " + JSON.stringify(response.data));

                var data = {
                    wanInterfaceId: response.data.wanInterfaceId,
                    sabaEquipmentId: response.data.sabaEquipmentId,
                    immediateUpgradeAllowed: response.data.immediateUpgradeAllowed,
                    immediateUpgradeEarliestStartDate: response.data.immediateUpgradeEarliestStartDate,
                    immediateUpgradeEarliestEndDate: response.data.immediateUpgradeEarliestEndDate,
                    availablePackages: response.data.availablePackages,
                }

                dispatch(actionDataReceived(data));
            })
            .catch(function (error) {
                if (axios.isCancel(error)) {
                    //console.log(error);
                } else {
                    dispatch(actionDataFailed());
                }
            });

        // TODO : other errors should be handled using React Error Boundaries
        // see https://reactjs.org/docs/error-boundaries.html
    }
}

function shouldFetchData(state) {
    const sliceData = sliceState(state);
    if (!sliceData) {
        return false;
    } else if (sliceData.isFetching) {
        return false;
    } else {
        return true;
    }
}

// Thunk to open the Immediate Upgrade Dialog for the specific wanInterfaceId and sabaEquipmentId
// Nasty hack for baseRatePlanName -- see other comments elsewhere
export function openDialog(wanInterfaceId, sabaEquipmentId) {
    return (dispatch, getState) => {
        if (shouldFetchData(getState())) {
            return dispatch(fetchData(wanInterfaceId, sabaEquipmentId));
        } else {
            return Promise.resolve();
        }
    }
}

export function cancelChanges() {
    return (dispatch, getState) => {
        return dispatch(actionCloseDialog());
    }
}

export function saveChanges(ratePlanId, endDate) {
    return (dispatch, getState) => {

        // tell everyone were doing the save
        dispatch(actionSaving());

        const data = (() => {

            return {
                wanInterfaceId: getWanInterfaceId(getState()),
                endDate,
                ratePlanId,
            }
        })();

        //console.log("saving changes " + JSON.stringify(data));

        const url = '/api/packages/immediateupgrade';

        return axios
            .post(url, data)
            .then(() => {
                dispatch(actionPackagesChanged());
                dispatch(actionCloseDialog());
            })
            .catch((error) => {
                //console.log(`post failure ${error} : ${JSON.stringify(error.response)}`);
                dispatch(actionSaveFailed());
            });
    }
}