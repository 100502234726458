import React from "react";
import styles from "./SaveErrorMessage.module.css";

const SaveErrorMessage = (props) => {

    const { visible } = props;

    return (
        <div className={styles.root}>
            {visible ? <div>Sorry, a problem occurred while saving your changes.</div> : <div>&nbsp;</div>}
        </div>
    );
}

export default SaveErrorMessage;